import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Skeleton,
  Alert,
  message,
  Modal,
  Checkbox,
  Pagination,
  Card,
  Menu,
} from "antd";
import axios from "axios";
import ImportListProductContainer from "./ImportListProduct/ImportListProductContainer";
import ImportProductModal from "./ImportProductModal";
import "./index.scss";
import OverrideProductModal from "./OverrirdeProductModal";
import SplitProductModal from "./SplitProductModal";
import NewValueModal from "./EditPricingModal/NewValueModal";
import MultiplierModal from "./EditPricingModal/MultiplierModal";
import ReconectStoremodal from "./ImportListProduct/ReconectedStoreModal";

import { hasPermission } from "../../../../Helpers/Permissions";
import { formatNumber } from "../../../../Helpers/Pricing";
import { connect } from "react-redux";
import Importlistnav from "./Importlistnav";
import qs from "qs";
import NotFoundCard from "../../Common/NotFoundCard";
import ErrorCard from "../../Common/ErrorCard";
const { confirm } = Modal;

class ImportList extends Component {
  state = {
    urlSearched: false,
    loading: true,
    error: false,
    errorMsg: "",
    errorCode: "",
    products: [],
    total: 0,
    selected: [],
    tags: [],
    types: [],
    collections: [],
    store_settings: null,
    currencies: null,
    store_connected: false,
    visible: false,
    importProductModalVisible: false,
    paging: {
      page: 1,
      per_page_count: 10,
    },
    filters: {
      search: "",
    },
    newValueModalVisible: false,
    multiplierModalVisible: false,
    overridePrice: {
      id: null,
      type: null,
    },
    overrideProductModalVisible: false,
    overrideData: null,
    splitProduct: {
      visible: false,
      product: null,
    },
    addToStoreLoading: false,
    addProductLoading: false,
  };

  requestCountFinder(e) {
    for (let i = 0; i < e.length; i++) {
      if (!!e[i].request_latest_price) {
        return e[i].request_latest_price.count;
      }
    }
  }

  loadData() {
    this.setState(
      {
        loading: true,
        products: [],
        selected: [],
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/products/import_list",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            page: this.state.paging.page,
            per_page_count: this.state.paging.per_page_count,
            filters: this.state.filters,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              let productsUnique = res.data.products.map((item) => {
                if (!!item && !!item.product_selected_gallery_images) {
                  item.product_selected_gallery_images.filter((i) => !!i);
                }

                if (!!item && !!item.vfprod && !!item.vfprod.gallery) {
                  item.vfprod.gallery.filter((i) => i.length > 10);
                }

                return item;
              });

              this.setState({
                products: !!productsUnique ? productsUnique : [],
                niches: res.data.niches,
                total: res.data.total,
                // requestCount: !! res.data.products.length && res.data.products.request_latest_price ? res.data.products[0].request_latest_price.count: null,
                requestCount: this.requestCountFinder(res.data.products),
                tags: Object.keys(res.data.tags),
                types: Object.keys(res.data.types),
                collections: res.data.collections.map((c) => ({
                  id: c.id,
                  handle: c.title,
                })),
                store_settings: res.data.store_settings,
                app_uninstall: res.data.app_uninstall,
                currencies: res.data.currencies,
                store_connected: res.data.store_connected,
                loading: false,
              });
            } else {
              this.setState({
                error: true,
                errorMsg: res.data.msg,
                loading: false,
              });
            }
          })
          .catch((e) => {
            if (!!e.request) {
              this.setState({
                error: true,
                errorCode: e.request.status,
                errorMsg: e.request.statusText,
                loading: false,
              });
            } else {
              this.setState({
                error: false,
                errorMsg: e.message,
                loading: true,
              });
            }
          });
      }
    );
  }

  componentDidMount() {
    const params = qs.parse(this.props.location.search.replace(/\?/g, ""));
    if (params.search) {
      this.setState(
        {
          urlSearched: true,
          filters: {
            ...this.state.filters,
            search: params.search,
          },
        },
        () => this.loadData()
      );
    } else {
      this.loadData();
    }
  }
  componentDidUpdate(pp) {
    if (pp.location.search !== this.props.location.search) {
      const params = qs.parse(this.props.location.search.replace(/\?/g, ""));
      this.setState(
        {
          filters: {
            ...this.state.filters,
            search: params.search,
          },
          paging: { page: 1, per_page_count: 10 },
        },
        () => this.loadData()
      );
    }
  }

  onShowSizeChange(current, pageSize) {
    this.setState(
      {
        paging: { ...this.state.paging, per_page_count: pageSize },
      },
      () => this.loadData()
    );
  }

  updateProduct(pid, field, value, showAlert) {
    axios({
      url: process.env.REACT_APP_API_URL + "/products/update_product_field",
      method: "post",
      data: {
        storeid: this.props.match.params.storeid,
        productid: pid,
        field,
        value: field === "collection" ? String(value) : value,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          let prods = this.state.products.map((p) => {
            if (p._id["$oid"] === pid) {
              return res.data.product;
            } else {
              return p;
            }
          });
          this.setState(
            {
              products: prods.map((item) => {
                item.product_selected_gallery_images = item.product_selected_gallery_images.filter(
                  (i) => !!i
                );
                item.vfprod.gallery = item.vfprod.gallery.filter(
                  (i) => i.length > 10
                );
                return item;
              }),
            },
            () => this.updateProductSelected()
          );
          if (showAlert) {
            message.success("Updated Successfully");
          }
        } else {
          if (showAlert) {
            message.error(res.data.msg);
          }
        }
      })
      .catch((e) => {
        if (showAlert) {
          message.error(e.message);
        }
      });

    // this.loadData()
  }

  updateProductSelected() {
    this.setState({
      selected: this.state.products
        .filter((p) => p.isSelected, [])
        .map((p) => p._id["$oid"]),
    });
  }

  selectAllProducts(checked) {
    if (checked) {
      this.setState({
        selected: this.state.products.map((p) => p._id["$oid"]),
      });
    } else {
      this.setState({
        selected: [],
      });
    }
  }

  selectProduct(id) {
    if (this.state.selected.indexOf(id) === -1) {
      this.setState({
        selected: [...this.state.selected, id],
      });
    } else {
      this.setState({
        selected: this.state.selected.filter((pid) => pid !== id),
      });
    }
  }

  importProducts(productIds) {
    this.setState(
      {
        addProductLoading: true,
      },
      () => {
        axios({
          url:
            process.env.REACT_APP_API_URL +
            "/products/import_products_aliexpress",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            productids: productIds,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                products: [...res.data.products, ...this.state.products],
                addProductLoading: false,
              });
            } else {
              this.setState({
                addProductLoading: false,
              });
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({
              addProductLoading: false,
            });
            message.error(e.message);
          });
      }
    );
  }

  importToStore(ids) {
    this.setState({ addToStoreLoading: true });
    axios({
      url: process.env.REACT_APP_API_URL + "/products/import_to_store",
      method: "post",
      data: {
        storeid: this.props.match.params.storeid,
        productids: ids,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          let products = this.state.products.map((p) => {
            if (ids.indexOf(p._id["$oid"]) !== -1) {
              return { ...p, export_in_progress: 1 };
            } else {
              return p;
            }
          });
          this.setState({ products, addToStoreLoading: false });
          message.success("Exporting to store");
        } else if (res.data.success === 2) {
          this.setState({ addToStoreLoading: false });
          this.connsectStoreModal(res.data.shopifyURL);
        } else {
          this.setState({ addToStoreLoading: false });
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        this.setState({ addToStoreLoading: false });
        message.error(e.message);
      });
  }

  exportInBulk() {
    this.importToStore(this.state.selected);
  }

  deleteProducts(ids) {
    axios({
      url: process.env.REACT_APP_API_URL + "/products/delete_products",
      method: "post",
      data: {
        storeid: this.props.match.params.storeid,
        productids: ids,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            products: this.state.products.filter(
              (p) => ids.indexOf(p._id["$oid"]) === -1,
              []
            ),
            selected: [],
          });
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  }

  confirmDelete(ids) {
    if (ids === 0) {
      message.error("You must select atleast one product to delete");
      return;
    }
    const that = this;
    confirm({
      title: "Are you sure you want to delete?",
      content:
        "Once product deleted cannot be recovered. You need to import again",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        that.deleteProducts(ids);
      },
      onCancel() {},
    });
  }

  // changeFilter(key, value) {
  //   this.setState(
  //     {
  //       filters: { ...this.state.filters, [key]: value },
  //       paging: { ...this.state.paging, page: 1 },
  //     },
  //     () => this.loadData()
  //   );
  // }

  searchhandler() {
    if (window.location.href.includes("import-list")) {
      this.props.history.push(
        `/${this.props.match.params.storegeo}/${
          this.props.match.params.storeid
        }/products/import-list?search=${this.state.filters.search}`
      );
    } else {
      this.props.history.push(
        `/${this.props.match.params.storegeo}/${
          this.props.match.params.storeid
        }/products/import-list?search=${this.state.filters.search}`
      );
    }
  }

  changeFilter(kvpairs) {
    this.setState(
      {
        filters: { ...this.state.filters, ...kvpairs },
        paging: { ...this.state.paging, page: 1 },
      },
      () => this.searchhandler(),
      () => this.loadData()
    );
  }

  toggleOverrideModal(productData) {
    this.setState({
      overrideProductModalVisible: !this.state.overrideProductModalVisible,
      overrideData: productData ? productData : null,
    });
  }

  toggleModal = (modalVisible) => {
    this.setState({
      [modalVisible]: !this.state[modalVisible],
    });
  };

  connsectStoreModal(url) {
    this.setState({
      visible: !this.state.visible,
      shopifyConnectURL: url,
    });
  }

  connsectStoreModal2(url) {
    this.setState({
      storeDisconnect: true,
      shopifyConnectURL: url,
    });
  }

  onProductOverrideSuccessfull(id) {
    let prods = this.state.products.map((p) => {
      if (p._id["$oid"] === id) {
        return { ...p, override_in_progress: 1 };
      } else {
        return p;
      }
    });
    this.setState(
      {
        products: prods,
        overrideProductModalVisible: false,
      },
      () => this.updateProductSelected()
    );
  }

  overridePrice(type, value) {
    const pid = this.state.overridePrice.id;
    let prod = this.state.products.filter((p) => pid === p._id["$oid"])[0];
    let variants;
    if (type === "value") {
      if (this.state.overridePrice.type === "price") {
        variants = prod.variants.map((v) => ({
          ...v,
          shop_price: value,
          manually_edited_shopprice: true,
        }));
      } else {
        variants = prod.variants.map((v) => ({
          ...v,
          compared_at_price: value,
          manually_edited_comparedatprice: true,
        }));
      }
    } else {
      if (this.state.overridePrice.type === "price") {
        variants = prod.variants.map((v) => ({
          ...v,
          shop_price: v.price * value,
          manually_edited_shopprice: true,
        }));
      } else {
        variants = prod.variants.map((v) => ({
          ...v,
          compared_at_price: v.shop_price * value,
          manually_edited_comparedatprice: true,
        }));
      }
    }
    this.updateProduct(prod._id["$oid"], "variants", variants, true);
    this.setState({
      newValueModalVisible: false,
      multiplierModalVisible: false,
    });
  }

  toggleOverridePriceModal(id, priceType, modalType) {
    this.setState({
      overridePrice: {
        id,
        type: priceType,
      },
      [modalType]: !this.state[modalType],
    });
  }

  toggleSplitProductModal(product) {
    this.setState({
      splitProduct: {
        product,
        visible: !this.state.splitProduct.visible,
      },
    });
  }

  onSplitSuccessfull() {
    this.setState(
      {
        paging: {
          page: 1,
          per_page_count: 10,
        },
        splitProduct: {
          visible: false,
          product: null,
        },
      },
      () => this.loadData()
    );
  }

  goToPage(page) {
    this.setState(
      {
        paging: {
          ...this.state.paging,
          page,
        },
      },
      () => this.loadData()
    );
  }

  refreshPrice(id, cb) {
    this.updateProduct(id, "priceUpdateCheck", "1", true);
  }

  getPriceRange(product) {
    let sortedPrices = [];

    Object.keys(product.sourcing_pricing).forEach((range) => {
      Object.values(product.sourcing_pricing[range]).forEach((v) =>
        sortedPrices.push(v.avg)
      );
    });

    if (!product.provisional) {
      sortedPrices.push(product.cost_data.vfmrp);
    }
    sortedPrices = sortedPrices.sort((a, b) => a - b);

    const minPrice = sortedPrices[0];
    const maxPrice = sortedPrices[sortedPrices.length - 1];

    return minPrice === maxPrice
      ? `₹ ${formatNumber(minPrice)}`
      : `₹ ${formatNumber(minPrice)} - ₹ ${formatNumber(maxPrice)}`;
  }

  changeHandler(kvpairs) {
    this.setState({
      filters: { ...this.state.filters, ...kvpairs },
    });
  }

  lessRequestCount() {
    if (this.state.requestCount > 0) {
      this.setState({ requestCount: this.state.requestCount - 1 });
    }
  }

  render() {
    const store = this.props.store;

    const productActions = (
      <div className="menudropdownacton">
        <Menu style={{ width: "280px" }}>
          <Menu.Item
            className="menu-items-modal hov"
            onClick={() => this.toggleModal("importProductModalVisible")}
          >
            <svg
              id="download-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              style={{
                marginRight: "16px",
                verticalAlign: "middle",
                width: "18px",
                height: "18px",
              }}
            >
              <g
                id="Group_3071"
                data-name="Group 3071"
                transform="translate(0 12.227)"
              >
                <g id="Group_3070" data-name="Group 3070">
                  <path
                    id="Path_77"
                    data-name="Path 77"
                    d="M18.438,313v5.43a.782.782,0,0,1-.781.781H2.344a.782.782,0,0,1-.781-.781V313H0v5.43a2.346,2.346,0,0,0,2.344,2.344H17.656A2.346,2.346,0,0,0,20,318.43V313Z"
                    transform="translate(0 -313)"
                    fill="#898989"
                  />
                </g>
              </g>
              <g
                id="Group_3073"
                data-name="Group 3073"
                transform="translate(5.145)"
              >
                <g id="Group_3072" data-name="Group 3072">
                  <path
                    id="Path_78"
                    data-name="Path 78"
                    d="M140.321,9.208l-2.969,2.969V0H135.79V12.176l-2.969-2.969-1.1,1.1,4.855,4.855,4.855-4.855Z"
                    transform="translate(-131.716)"
                    fill="#898989"
                  />
                </g>
              </g>
            </svg>
            <span className="import-product-menu">
              {" "}
              Add products from AliExpress
            </span>
          </Menu.Item>

          <Menu.Item
            className="menu-items-modal hov"
            onClick={() => {
              window.open(
                `/in/${
                  this.props.match.params.storeid
                }/products/search-products`
              );
            }}
          >
            <svg
              id="discover-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="19.528"
              height="19.811"
              viewBox="0 0 19.528 19.811"
              style={{
                marginRight: "16px",
                verticalAlign: "middle",
                width: "18px",
                height: "18px",
              }}
            >
              <g
                id="Group_3179"
                data-name="Group 3179"
                transform="translate(-961.212 -332.939)"
              >
                <path
                  id="Path_84"
                  data-name="Path 84"
                  d="M-4589.1-691.587h-7.1v18h18v-6.865"
                  transform="translate(5558.167 1025.587)"
                  fill="none"
                  stroke="#898989"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <g
                  id="Group_3178"
                  data-name="Group 3178"
                  transform="translate(970.509 334)"
                >
                  <path
                    id="Path_85"
                    data-name="Path 85"
                    d="M-4590.015-682.718l9.17-9.27"
                    transform="translate(4590.015 691.988)"
                    fill="none"
                    stroke="#898989"
                    stroke-linecap="round"
                    stroke-width="1.5"
                  />
                  <path
                    id="Path_86"
                    data-name="Path 86"
                    d="M-4587.394-691.587h6.091v5.81"
                    transform="translate(4590.756 691.587)"
                    fill="none"
                    stroke="#898989"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                </g>
              </g>
            </svg>

            <span className="import-product-menu">
              Discover Products from VF Catalog
            </span>
          </Menu.Item>
        </Menu>
      </div>
    );
    const PageWrapper = (children) => {
      return (
        <div className="import-list">
          <Importlistnav
            urlSearched={this.state.urlSearched}
            storeid={this.props.match.params.storeid}
            loading={this.state.loading}
            changeHandler={(e) => this.changeHandler(e)}
            changeFilter={(e) => this.changeFilter(e)}
            data={this.state.filters}
            productActions={productActions}
            store1={this.props.store}
            products={this.state.products}
          />

          <div xs={12}>
            {hasPermission(store, "import_export_products") && (
              <ImportProductModal
                visible={this.state.importProductModalVisible}
                onCancel={() => this.toggleModal("importProductModalVisible")}
                onConfirm={(productIds) => this.importProducts(productIds)}
              />
            )}
          </div>

          <Row className="slect-all-order-checkbox">
            <Col xs={24} md={24}>
              <span
                style={{ padding: "10px 0px 5px", display: "inline-block" }}
              >
                <Checkbox
                  indeterminate={
                    this.state.selected.length > 0 &&
                    this.state.selected.length !== this.state.products.length
                  }
                  onChange={(e) => this.selectAllProducts(e.target.checked)}
                  checked={
                    this.state.products.length !== 0 &&
                    this.state.selected.length === this.state.products.length
                  }
                  className="select-all-checkbox"
                >
                  <span style={{ fontSize: ".75rem" }}>Select All</span>
                </Checkbox>
              </span>
              {this.state.selected.length !== 0 && (
                <span className="select-button">
                  {this.state.store_connected && (
                    <Button
                      type="primary"
                      style={{ marginLeft: "5px" }}
                      onClick={() => this.exportInBulk()}
                    >
                      Export Selected
                    </Button>
                  )}
                  <Button
                    type="primary"
                    style={{ marginLeft: "5px" }}
                    onClick={() => this.confirmDelete(this.state.selected)}
                  >
                    Delete Selected
                  </Button>
                </span>
              )}
            </Col>
          </Row>

          <Row>{children}</Row>
          <Row>
            <Pagination
              locale={{ jump_to: "Go to page" }}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              showQuickJumper
              showSizeChanger
              pageSizeOptions={["10", "25", "50"]}
              onShowSizeChange={(p, d) => this.onShowSizeChange(p, d)}
              current={this.state.paging.page}
              onChange={(page) => this.goToPage(page)}
              total={this.state.total}
              defaultPageSize={this.state.paging.per_page_count}
            />
          </Row>
        </div>
      );
    };

    if (this.state.loading) {
      return PageWrapper(
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <ErrorCard
          errorMsg={this.state.errorMsg}
          errorCode={this.state.errorCode}
        />
      );
    }

    const props = this.props;
    let {
      products,
      tags,
      types,
      collections,
      selected,
      store_settings,
      overrideProductModalVisible,
      newValueModalVisible,
      multiplierModalVisible,
    } = this.state;

    // products = products.map(item => {
    //   // item.product_selected_gallery_images = item.product_selected_gallery_images.filter(i => !!i);
    //   item.vfprod.gallery = item.vfprod.gallery.filter(i => i.length > 10);
    //   return item;
    // });

    return PageWrapper(
      <div className="product-import-list">
        {products.length > 0 ? (
          products.map((product) => (
            <ImportListProductContainer
              {...props}
              lessRequestCount={() => this.lessRequestCount()}
              requestCount={this.state.requestCount}
              key={product._id["$oid"]}
              refreshPrice={(cb) => this.refreshPrice(product._id["$oid"], cb)}
              product={product}
              store={this.props.store}
              app_uninstall={this.state.app_uninstall}
              tags={tags}
              types={types}
              collections={collections}
              currencies={this.state.currencies}
              isSelected={selected.indexOf(product._id["$oid"]) !== -1}
              onProductUpdate={(pid, field, value, showAlert) =>
                this.updateProduct(pid, field, value, showAlert)
              }
              onProductDelete={(ids) => this.confirmDelete(ids)}
              onSelect={(id) => this.selectProduct(id)}
              settings={{
                ...store_settings,
                commission:
                  product.aliproduct && product.aliproduct.ali_commission
                    ? product.aliproduct.ali_commission
                    : 0,
              }}
              importToStore={(ids) => this.importToStore(ids)}
              storeConnected={this.state.store_connected}
              toggleModal={(data) => this.toggleModal(data)}
              toggleOverrideModal={(data) => this.toggleOverrideModal(data)}
              toggleSplitProductModal={() =>
                this.toggleSplitProductModal(product)
              }
              toggleOverridePriceModal={(priceType, modalType) =>
                this.toggleOverridePriceModal(
                  product._id["$oid"],
                  priceType,
                  modalType
                )
              }
              loadData={() => this.loadData()}
              connsectStoreModal={() => this.connsectStoreModal()}
              addToStoreLoading={this.state.addToStoreLoading}
            />
          ))
        ) : (
          <NotFoundCard
            mainHeading={"Oops! No Data Found."}
            secondHeading={
              <p>Looks like there are no products in this page.</p>
            }
          />
        )}

        <OverrideProductModal
          visible={overrideProductModalVisible}
          overrideData={this.state.overrideData}
          storeid={this.props.match.params.storeid}
          onProductOverrideSuccessfull={(id) =>
            this.onProductOverrideSuccessfull(id)
          }
          sourcePage="import-list"
          onCancel={() => this.toggleOverrideModal()}
          onConfirm={() => this.toggleOverrideModal()}
        />

        <SplitProductModal
          visible={this.state.splitProduct.visible}
          product={this.state.splitProduct.product}
          pricing={this.state.store_settings}
          onSplitSuccessfull={() => this.onSplitSuccessfull()}
          onCancel={() => this.toggleSplitProductModal()}
        />

        <NewValueModal
          visible={newValueModalVisible}
          onCancel={() => this.toggleModal("newValueModalVisible")}
          onConfirm={(value) => this.overridePrice("value", value)}
        />

        <MultiplierModal
          visible={multiplierModalVisible}
          onCancel={() => this.toggleModal("multiplierModalVisible")}
          onConfirm={(value) => this.overridePrice("multiplier", value)}
        />
        <ReconectStoremodal
          visible={this.state.visible}
          onCancel={() => this.connsectStoreModal()}
          shopifyConnectURL={this.state.shopifyConnectURL}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
    auth: state.auth.auth,
  };
};
export default connect(mapStateToProps)(ImportList);
