import React, { Component } from "react";
import { Collapse, Tooltip, Icon, Button } from "antd";
import ClickToCopy from "../../../Common/ClickToCopy";
import { ReactComponent as link } from "../../../../../assets/images/icons/link.svg";
import moment from "moment";
import "./index.scss";

const { Panel } = Collapse;

class InventoryRTO extends Component {
  state = {
    open: false,
  };

  render() {
    let order = this.props.order;
    let product_img = order && order.images ? order.images : null;

    return (
      <>
        <Collapse
          className="collapse-item rto"
          showArrow={false}
          expandIconPosition="right"
          onChange={() => this.setState({ open: !this.state.open })}
        >
          <Panel
            showArrow={false}
            header={
              <div className="container">
                <div
                  className="details"
                  onClick={(event) => event.stopPropagation()}
                >
                  <div className="img">
                    <img src={product_img} alt="" />
                  </div>
                  <div className="product-link-name">
                    <p>
                      {order.name.length <= 20 ? (
                        <a
                          href={`./products/${order.vfprodid}`}
                          target="_blank"
                          className="view-in-vf-link"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          {order && order.name ? order.name : null}
                          <Icon
                            component={link}
                            style={{ marginLeft: "6px" }}
                          />
                        </a>
                      ) : (
                        <Tooltip title={order.name}>
                          {" "}
                          <p>
                            {" "}
                            <a
                              href={`./products/${order.vfprodid}`}
                              target="_blank"
                              className="view-in-vf-link"
                              rel="noopener noreferrer"
                            >
                              {" "}
                              {order && order.name
                                ? order.name.slice(0, 20) + "..."
                                : null}
                              <Icon
                                component={link}
                                style={{ marginLeft: "6px" }}
                              />
                            </a>
                          </p>
                        </Tooltip>
                      )}
                    </p>

                    <ClickToCopy
                      inventoryId={order.vfprodid}
                      textToCopy={order.vfprodid}
                    />
                  </div>{" "}
                </div>
                <div
                  onClick={(event) => event.stopPropagation()}
                  className="InventoryE"
                >
                  {order.total}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  {this.state.open ? "Contract View" : "Expand View"}
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="8"
                      viewBox="0 0 16 8"
                      style={{
                        marginLeft: "5px",
                        transition: "all 0.5s",
                        transform: `${
                          this.state.open ? "rotateX(180deg)" : "rotateX(0deg)"
                        }`,
                      }}
                    >
                      <g
                        id="Polygon_41"
                        data-name="Polygon 41"
                        transform="translate(16 8) rotate(180)"
                        fill={`${this.state.open ? "#1370FC" : "none"}`}
                      >
                        <path d="M8,0l8,8H0Z" stroke="none" />
                        <path
                          d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                          stroke="none"
                          fill="#1370fc"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
            }
            key="1"
          >
            <div className="bottom-head-rto">
              <div className="prod-details">
                <h5>Variant Details</h5>{" "}
              </div>

              <div className="prod-details">
                <h5>Specs</h5>{" "}
              </div>

              <div className="live-inv">
                <h5>Qty</h5>{" "}
              </div>
              <div className="live-inv" />
            </div>

            {order.sku.map((sku, i) => {
              return (
                <div
                  className={`bottom-head-rto data ${
                    i === 0 ? `first` : null
                  } `}
                >
                  <div className="prod-details">
                    <p style={{ width: "100%" }}>{sku.sku}</p>{" "}
                  </div>

                  <div className="prod-details">
                    <p style={{ width: "100%" }}>
                      {" "}
                      {!!sku && sku.specs && sku.specs.length < 19 ? (
                        <p style={{ width: "100%" }}>{sku.specs}</p>
                      ) : (
                        <Tooltip title={sku.specs}>
                          {" "}
                          <p style={{ width: "100%" }}>
                            {!!sku && sku.specs
                              ? sku.specs.slice(0, 16) + "..."
                              : null}
                          </p>
                        </Tooltip>
                      )}
                    </p>{" "}
                  </div>

                  <div className="prod-details">
                    <p style={{ width: "100%" }}>{sku.qty}</p>{" "}
                  </div>

                  <div className="prod-details">
                    <a href={`./tracking?search=${sku.sku}`} target="_blank">
                      {" "}
                      Track RTO
                      <Icon component={link} style={{ marginLeft: "6px" }} />
                    </a>
                  </div>
                </div>
              );
            })}
          </Panel>
        </Collapse>

        {/* logs */}
      </>
    );
  }
}

export default InventoryRTO;
