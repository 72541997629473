import React, { Component } from "react";
import {
  Modal,
  Tooltip,
  Icon,
  Button,
  Checkbox,
  InputNumber,
  message,
  Input,
  Select,
  Alert,
} from "antd";
import "./index.scss";
import axios from "axios";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import { ReactComponent as CircleCheck } from "../../../../../assets/images/icons/circle-check.svg";
import ghantaalert from "./ican.json";

const { Option } = Select;

class AddNewRequestModal extends Component {
  state = {
    editLineItem: {},
    shipment: undefined,
    selectedLineItems: [],
    type: {
      rvp: null,
      refund: null,
      replacement: null,
    },

    refund: {
      customer_name: null,
      acc_no: null,
      confirm_acc_no: null,
      ifsc_code: null,
      amount: null,
    },

    totalAmount: null,

    groundActionfilters: {
      status: "",
    },
    otherRemarks: "",
    loading: false,
    iconStop: true,
    data: null,
    items: null,
    setAwb: null,
    tracking_url: null,
    order_vfid: null,
    shopify_order_link: null,
    order_shopify_name: null,
    shipment_vfid: null,
    status: "",
    currentStatus: "",
  };

  OnChangeCheckbox(key, value) {
    let obj = {
      refund: ": Refund End Customer",
      rvp: ": Reverse Pickup",
      replacement: ": Replacement",
    };

    this.setState({
      type: {
        ...this.state.type,
        currentStatus: obj[key],
        [key]: value,
      },
    });
  }

  handleChange(key, value) {
    this.setState({
      refund: {
        ...this.state.refund,
        [key]: value,
      },
    });
  }

  inputPassword = (event) => {
    this.setState({
      refund: {
        ...this.state.refund,
        acc_no: event.target.value,
      },
    });
  };

  confirmPassword = (event) => {
    this.setState({
      refund: {
        ...this.state.refund,
        confirm_acc_no: event.target.value,
      },
    });
  };

  onEdit(key) {
    this.setState({
      editLineItem: {
        [key]: true,
      },
    });
  }

  changeGroundActionFilter(kvpairs) {
    if (kvpairs["status"] === "other") {
      this.setState({
        other: true,
        groundActionfilters: { ...this.state.groundActionfilters, ...kvpairs },
      });
    } else {
      this.setState({
        other: false,
        groundActionfilters: { ...this.state.groundActionfilters, ...kvpairs },
      });
    }
  }

  componentDidMount() {
    this.setState({ shipment: this.props.data });
    if (this.props.activeTab === "rvp") {
      this.setState({ status: "reverse_pickup" });
    } else if (this.props.activeTab === "refund") {
      this.setState({ status: "refund_end_customer" });
    } else {
      this.setState({ status: "replacement" });
    }
  }

  componentDidUpdate(pp) {
    if (
      (pp.data === [] && this.props.data !== []) ||
      this.props.data !== pp.data
    ) {
      this.setState({
        shipment: {
          ...this.props.data,
          items:
            !!this.props.data.length &&
            this.props.data[0].items.map((item) => {
              return {
                ...item,
                originalQty: item.quantity,
              };
            }),
        },

        type: {
          rvp: this.props.rvpStatus === 1 ? 1 : 0,
          refund: this.props.refundedstatus === 1 ? 1 : 0,
          replacement: this.props.replacementStatus === 1 ? 1 : 0,
        },

        refund: {
          customer_name:
            this.props.request_status_data &&
            this.props.request_status_data.refund_details &&
            this.props.request_status_data.refund_details.customer_name
              ? this.props.request_status_data.refund_details.customer_name
              : this.props.refundedstatus === 2
              ? "Not Available"
              : "",
          acc_no:
            this.props.request_status_data &&
            this.props.request_status_data.refund_details &&
            this.props.request_status_data.refund_details.acc_no
              ? this.props.request_status_data.refund_details.acc_no
              : this.props.refundedstatus === 2
              ? "Not Available"
              : "",
          confirm_acc_no:
            this.props.request_status_data &&
            this.props.request_status_data.refund_details &&
            this.props.request_status_data.refund_details.acc_no
              ? this.props.request_status_data.refund_details.acc_no
              : this.props.refundedstatus === 2
              ? "Not Available"
              : "",
          ifsc_code:
            this.props.request_status_data &&
            this.props.request_status_data.refund_details &&
            this.props.request_status_data.refund_details.ifsc_code
              ? this.props.request_status_data.refund_details.ifsc_code
              : this.props.refundedstatus === 2
              ? "Not Available"
              : "",
          amount:
            this.props.request_status_data &&
            this.props.request_status_data.refund_details &&
            this.props.request_status_data.refund_details.amount
              ? this.props.request_status_data.refund_details.amount
              : this.props.refundedstatus === 2
              ? "Not Available"
              : "",
        },

        groundActionfilters: {
          status:
            this.props.request_status_data &&
            this.props.request_status_data.reverse_pickup_reason &&
            this.props.request_status_data.reverse_pickup_reason
              ? this.props.request_status_data.reverse_pickup_reason
              : "",
        },

        totalAmount:
          this.props.shipmentTotalAmount && !!this.props.shipmentTotalAmount
            ? this.props.shipmentTotalAmount
            : "",
        data: this.props.data,
        items: this.props.items,
        setAwb: this.props.setAwb,
        tracking_url: this.props.tracking_url,
        order_vfid: this.props.order_vfid,
        shopify_order_link: this.props.shopify_order_link,
        order_shopify_name: this.props.order_shopify_name,
        shipment_vfid: this.props.shipment_vfid,
      });
    }
  }

  onSaveChange(awb) {
    const { acc_no, confirm_acc_no } = this.state.refund;
    const matches = acc_no === confirm_acc_no;
    if (matches) {
    } else {
      message.error("Account No & Confirm Account No is not match!");
      return;
    }
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url:
            process.env.REACT_APP_API_URL +
            "/disputes/raise_shipment_dispute_action",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            shipment_vfid: this.state.shipment_vfid,
            awb: this.state.setAwb,
            type: this.state.type,
            items: this.state.items,
            refund: this.state.type.refund === 1 ? this.state.refund : [],
            rvp: {
              reason:
                this.state.groundActionfilters.status === "other"
                  ? this.state.otherRemarks
                  : this.state.groundActionfilters.status,
            },
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              message.success(res.data.msg);
              this.setState({
                loading: false,
              });
              this.props.CloseToggleShipmentDisputeModal();
              window.location.assign(
                `../requests/shipment-requests/?search=${awb}#${
                  this.state.status
                }`
              );
            } else {
              this.setState({
                loading: false,
              });
              message.error(res.data.msg);
            }
          })

          .catch((e) => {
            this.setState({
              loading: false,
            });
            message.error(e.message);
          });
      }
    );
  }

  lineItemSelectedChange(id) {
    let selectedLineItems = [...this.state.selectedLineItems];
    const findIndex = selectedLineItems.findIndex((li) => li === id);
    if (findIndex === -1) {
      selectedLineItems.push(id);
    } else {
      selectedLineItems = [
        ...selectedLineItems.slice(0, findIndex),
        ...selectedLineItems.slice(findIndex + 1),
      ];
    }
    this.setState({
      selectedLineItems,
    });
  }

  onQtyChange = (id, qty) => {
    this.setState((state) => ({
      shipment: {
        ...state.shipment,
        items: state.shipment.items.map((li) => {
          if (li.lineitem_id === id) {
            return { ...li, quantity: qty };
          } else {
            return { ...li };
          }
        }),
      },
    }));
  };

  onSave(id) {
    this.setState({
      editLineItem: {},
    });
    this.lineItemSelectedChange(id);
  }

  onCountinue() {
    if (this.state.selectedLineItems.length < 1) {
      return message.error("Please select at least one line item");
    }
  }

  rvpPriceHandler() {
    try {
      let rvp = this.state.shipment[0]["request_status"]["rvp_charges"];
      return `RVP Charges: ₹${rvp}`;
    } catch (error) {
      return `Error`;
    }
  }

  render() {
    let selectitems = this.state.selectedLineItems;
    if (
      selectitems.length === 0 &&
      this.state.shipment &&
      this.state.shipment.items &&
      this.state.shipment.items.length === 1 &&
      this.state.shipment.items[0].quantity === 1
    ) {
      selectitems.push(this.state.shipment.items[0].lineitem_id);
    }

    if (this.state.data === undefined) {
      return null;
    }

    const defaultOptions = {
      animationData: ghantaalert,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const shipment = this.state.data;
    const lineItems = this.state.items;

    let obj = {
      refund: "Refund End Customer,",
      rvp: "Reverse Pickup,",
      replacement: ": Replacement,",
    };

    function statusHandler(refund, replacement, rvp) {
      if (!!refund && !!replacement && !!rvp) {
        return ": Refund End Customer, Reverse Pickup, Replacement";
      }

      if (!!refund && !!replacement) {
        return ": Refund End Customer, Replacement";
      }

      if (!!refund && !!rvp) {
        return ": Refund End Customer, Reverse Pickup";
      }

      if (!!replacement && !!rvp) {
        return ": Replacement, Reverse Pickup";
      }

      if (!!replacement) {
        return ": Replacement";
      }

      if (!!rvp) {
        return ": Reverse Pickup";
      }
      if (!!refund) {
        return ": Refund";
      }
    }

    // const lineItemsLength = shipment.items.length;
    // const disputeDeadline = moment.utc(shipment.closed_on).add(7, "days");
    return (
      <Modal
        onCancel={() => this.props.toggleShipmentDisputeModal()}
        title={
          <div>
            <div className="modal-title">
              <p>
                Requests on Post Delivery Shipment{" "}
                <span>
                  {statusHandler(
                    this.state.type.refund,
                    this.state.type.replacement,
                    this.state.type.rvp
                  )}
                </span>
              </p>
              <div className="imp">
                <span>* All fields mandatory</span>
              </div>
            </div>

            <div className="modal-header">
              <div>
                <p>
                  WayBill Number(AWB){": "}
                  <span className="blue-text">
                    <a
                      href={this.state.tracking_url}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {this.state.setAwb}
                    </a>
                  </span>
                </p>
              </div>

              <div>
                <p style={{ margin: 0 }}>
                  Shopify ID:{" "}
                  <span className="blue-text">
                    <a
                      href={
                        !!this.state.shopify_order_link
                          ? this.state.shopify_order_link
                          : "#"
                      }
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {this.state.order_shopify_name}
                    </a>
                  </span>
                </p>
              </div>

              <div className="order_vfid">
                <p style={{ margin: 0 }}>
                  vF Order:{" "}
                  <span className="blue-text">
                    <a
                      href={`../orders/closedOrders?search=${
                        this.state.order_vfid
                      }`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {this.state.order_vfid}
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        }
        visible={this.props.visible}
        className="shipment_request_review-line-item"
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
        width={904}
        footer={
          <div>
            {this.state.type.replacement === 1 ? (
              <>
                <p className="pickup_reason">
                  {this.props.replacementStatus === 1 ? (
                    <p>
                      <span style={{ verticalAlign: "middle" }}>
                        <Icon
                          className="refund_disable_circle"
                          component={CircleCheck}
                        />
                      </span>
                      <span className="action_taken">
                        {" "}
                        Action Already Taken: {""}
                      </span>{" "}
                      <span className="bank_detail_txt">
                        Replacement Completed
                      </span>
                      <span className="link_data ali-in-store-hover-underline-animation">
                        <Link
                          to={`../requests/shipment-requests/?search=${
                            this.state.setAwb
                          }#${"replacement"}`}
                          target="_blank"
                          style={{
                            color: "#FF005E",
                            fontSize: "12px",
                            display: "flex",
                            lineHeight: "24px",
                            letterSpacing: "0.032em",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span className="view-status-txt">View Status</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="11"
                            height="11.207"
                            viewBox="0 0 11 11.207"
                          >
                            <g
                              id="Group_3238"
                              data-name="Group 3238"
                              transform="translate(-961.462 -333.293)"
                            >
                              <path
                                id="Path_84"
                                data-name="Path 84"
                                d="M-4592.26-691.587h-3.945v10h10V-685.4"
                                transform="translate(5558.167 1025.587)"
                                fill="none"
                                stroke="#fd115e"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1"
                              />
                              <g
                                id="Group_3178"
                                data-name="Group 3178"
                                transform="translate(964.865 334)"
                              >
                                <path
                                  id="Path_85"
                                  data-name="Path 85"
                                  d="M-4590.015-685.029l6.885-6.96"
                                  transform="translate(4590.015 691.988)"
                                  fill="none"
                                  stroke="#fd115e"
                                  stroke-linecap="round"
                                  stroke-width="1"
                                />
                                <path
                                  id="Path_86"
                                  data-name="Path 86"
                                  d="M-4586.394-691.587h3.573v3.362"
                                  transform="translate(4589.918 691.587)"
                                  fill="none"
                                  stroke="#fd115e"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1"
                                />
                              </g>
                            </g>
                          </svg>
                        </Link>
                      </span>
                    </p>
                  ) : (
                    <span className="replacement_text_data">Replacement:</span>
                  )}
                </p>
                <div className="replacement_border">
                  <p className="replacement_new_txt">
                    <span />{" "}
                    <div className="ghanta-icon-wraper">
                      <Lottie options={defaultOptions} height={16} width={16} />
                    </div>
                    <span className="important_txt">Important:</span> A new
                    order will be created that will be visible in Open orders
                    section on raising a replacement order.
                  </p>
                </div>
              </>
            ) : null}

            {this.props.refundedstatus === 2 ||
            this.props.gst_enabled === 1 ? null : this.state.type.refund ===
              1 ? (
              <div className="end_customer_detail">
                <p className="title_text">
                  {this.props.refundedstatus === 1 ? (
                    <p>
                      <span style={{ verticalAlign: "middle" }}>
                        <Icon
                          className="refund_disable_circle"
                          component={CircleCheck}
                        />
                      </span>
                      <span className="action_taken">
                        {" "}
                        Action Already Taken: {""}
                      </span>{" "}
                      <span className="bank_detail_txt">
                        End Customer Refunded
                      </span>
                      <span className="link_data ali-in-store-hover-underline-animation">
                        <Link
                          to={`../requests/shipment-requests/?search=${
                            this.state.setAwb
                          }#${"refund_end_customer"}`}
                          target="_blank"
                          style={{
                            color: "#FF005E",
                            fontSize: "12px",
                            display: "flex",
                            lineHeight: "24px",
                            letterSpacing: "0.032em",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span className="view-status-txt">View Status</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="11"
                            height="11.207"
                            viewBox="0 0 11 11.207"
                          >
                            <g
                              id="Group_3238"
                              data-name="Group 3238"
                              transform="translate(-961.462 -333.293)"
                            >
                              <path
                                id="Path_84"
                                data-name="Path 84"
                                d="M-4592.26-691.587h-3.945v10h10V-685.4"
                                transform="translate(5558.167 1025.587)"
                                fill="none"
                                stroke="#fd115e"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1"
                              />
                              <g
                                id="Group_3178"
                                data-name="Group 3178"
                                transform="translate(964.865 334)"
                              >
                                <path
                                  id="Path_85"
                                  data-name="Path 85"
                                  d="M-4590.015-685.029l6.885-6.96"
                                  transform="translate(4590.015 691.988)"
                                  fill="none"
                                  stroke="#fd115e"
                                  stroke-linecap="round"
                                  stroke-width="1"
                                />
                                <path
                                  id="Path_86"
                                  data-name="Path 86"
                                  d="M-4586.394-691.587h3.573v3.362"
                                  transform="translate(4589.918 691.587)"
                                  fill="none"
                                  stroke="#fd115e"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1"
                                />
                              </g>
                            </g>
                          </svg>
                        </Link>
                      </span>
                    </p>
                  ) : (
                    <span>Enter Customer Bank Details:</span>
                  )}
                </p>

                <div className="end_customer_detail_input">
                  <div className="upeer_label_margin">
                    <p className="common_label">Customer Name:</p>
                    <Input
                      className="customer_detail_text"
                      placeholder="Enter Your Name "
                      value={this.state.refund.customer_name}
                      onChange={(e) =>
                        this.handleChange("customer_name", e.target.value)
                      }
                      disabled={this.props.refundedstatus === 1 ? true : false}
                    />
                  </div>

                  <div className="upeer_label_margin">
                    <p className="common_label">Account Number:</p>
                    <Input
                      className="account_number_text"
                      placeholder="Enter Account Number"
                      value={this.state.refund.acc_no}
                      // onChange={(e) =>
                      //   this.handleChange("acc_no", e.target.value)
                      // }
                      onChange={this.inputPassword}
                      type="number"
                      disabled={this.props.refundedstatus === 1 ? true : false}
                    />
                  </div>

                  {this.props.refundedstatus === 1 ? null : (
                    <div className="upeer_label_margin">
                      <p className="common_label">Confirm Account Number:</p>
                      <Input
                        className="confirm_account_number_text"
                        placeholder="Re-enter Account Number"
                        value={this.state.refund.confirm_acc_no}
                        // onChange={(e) =>
                        //   this.handleChange("confirm_acc_no", e.target.value)
                        // }

                        onChange={this.confirmPassword}
                        type="number"
                        disabled={
                          this.props.refundedstatus === 1 ? true : false
                        }
                      />
                    </div>
                  )}
                  <div className="upeer_label_margin">
                    <p className="common_label">IFSC code :</p>
                    <Input
                      className="ifsc"
                      placeholder="Enter IFSC"
                      value={this.state.refund.ifsc_code}
                      onChange={(e) =>
                        this.handleChange("ifsc_code", e.target.value)
                      }
                      disabled={this.props.refundedstatus === 1 ? true : false}
                    />
                  </div>

                  {this.props.refundedstatus === 1 ? null : (
                    <div className="upeer_label_margin">
                      <p className="common_label">Total Order Amount:</p>
                      <Input
                        className="ifsc"
                        placeholder="Enter Refund Amount "
                        value={this.state.totalAmount}
                        onChange={(e) =>
                          this.handleChange("amount", e.target.value)
                        }
                        type="number"
                        disabled={true}
                      />
                    </div>
                  )}

                  <div className="upeer_label_margin">
                    <p className="common_label">Refund Amount:</p>
                    <Input
                      className="ifsc"
                      placeholder="Enter Refund Amount "
                      value={this.state.refund.amount}
                      onChange={(e) =>
                        this.handleChange("amount", e.target.value)
                      }
                      type="number"
                      disabled={this.props.refundedstatus === 1 ? true : false}
                    />
                  </div>
                </div>

                {this.props.refundedstatus === 1 ? null : (
                  <p className="replacement_new_txt">
                    <span />{" "}
                    <div className="ghanta-icon-wraper">
                      <Lottie options={defaultOptions} height={16} width={16} />
                    </div>
                    <span className="important_txt">Important:</span> Please
                    note, customer account information should be correct, this
                    refund will be credited after being validated by our finance
                    team.
                  </p>
                )}
              </div>
            ) : null}

            {this.state.type.rvp === 1 ? (
              <div className="reversePickupReason">
                <p className="pickup_reason">
                  {this.props.rvpStatus === 1 ? (
                    <p>
                      <span style={{ verticalAlign: "middle" }}>
                        <Icon
                          className="refund_disable_circle"
                          component={CircleCheck}
                        />
                      </span>
                      <span className="action_taken">
                        {" "}
                        Action Already Taken: {""}
                      </span>{" "}
                      <span className="bank_detail_txt">
                        Reverse Pickup Completed
                      </span>
                      <span className="link_data ali-in-store-hover-underline-animation">
                        <Link
                          to={`../requests/shipment-requests/?search=${
                            this.state.setAwb
                          }#${"reverse_pickup"}`}
                          target="_blank"
                          style={{
                            color: "#FF005E",
                            fontSize: "12px",
                            display: "flex",
                            lineHeight: "24px",
                            letterSpacing: "0.032em",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span className="view-status-txt">View Status</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="11"
                            height="11.207"
                            viewBox="0 0 11 11.207"
                          >
                            <g
                              id="Group_3238"
                              data-name="Group 3238"
                              transform="translate(-961.462 -333.293)"
                            >
                              <path
                                id="Path_84"
                                data-name="Path 84"
                                d="M-4592.26-691.587h-3.945v10h10V-685.4"
                                transform="translate(5558.167 1025.587)"
                                fill="none"
                                stroke="#fd115e"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1"
                              />
                              <g
                                id="Group_3178"
                                data-name="Group 3178"
                                transform="translate(964.865 334)"
                              >
                                <path
                                  id="Path_85"
                                  data-name="Path 85"
                                  d="M-4590.015-685.029l6.885-6.96"
                                  transform="translate(4590.015 691.988)"
                                  fill="none"
                                  stroke="#fd115e"
                                  stroke-linecap="round"
                                  stroke-width="1"
                                />
                                <path
                                  id="Path_86"
                                  data-name="Path 86"
                                  d="M-4586.394-691.587h3.573v3.362"
                                  transform="translate(4589.918 691.587)"
                                  fill="none"
                                  stroke="#fd115e"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1"
                                />
                              </g>
                            </g>
                          </svg>
                        </Link>
                      </span>
                    </p>
                  ) : (
                    <span>Reverse Pickup Reason:</span>
                  )}
                </p>

                <Select
                  value={this.state.groundActionfilters.status}
                  onChange={(val) =>
                    this.changeGroundActionFilter({ status: val })
                  }
                  disabled={this.props.rvpStatus === 1 ? true : false}
                  className="reverse_pickup_reason_data"
                  //open={true}
                  onDropdownVisibleChange={(val) =>
                    this.setState({ arrow2: val })
                  }
                  suffixIcon={
                    <svg
                      style={{
                        transition: "all 0.5s",
                        transform: `${
                          this.state.arrow2
                            ? "rotateX(180deg)"
                            : "rotateX(0deg)"
                        }`,
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="8"
                      viewBox="0 0 16 8"
                    >
                      <g
                        id="Polygon_33"
                        data-name="Polygon 33"
                        transform="translate(16 8) rotate(180)"
                        fill={`${this.state.arrow2 ? "898989" : "none"}`}
                      >
                        <path d="M8,0l8,8H0Z" stroke="none" />
                        <path
                          d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                          stroke="none"
                          fill="#898989"
                        />
                      </g>
                    </svg>
                  }
                >
                  <Option value="">Select a reason</Option>
                  <Option value="customer_wanted_a_different_variant">
                    Customer Wanted a Different Variant
                  </Option>
                  <Option value="customer_wanted_a_different_product">
                    Customer Wanted a Different Product
                  </Option>
                  <Option value="customer_changed_his_mind">
                    Customer Changed his mind
                  </Option>
                  <Option value="customer_didnt_like_the_quality_of_the_product">
                    Customer didn't like the quality of the product
                  </Option>
                  <Option value="customer_found_a_cheaper_alternative">
                    Customer found a cheaper alternative
                  </Option>
                </Select>

                {this.state.other ? (
                  <div className="other-textarea">
                    <p className="other_label">Other Reason</p>
                    <textarea
                      onChange={(e) =>
                        this.setState({
                          otherRemarks: e.target.value,
                        })
                      }
                      placeholder="Please specify other reason"
                      name=""
                      id=""
                      cols="20"
                      rows="10"
                    />
                  </div>
                ) : null}

                {this.props.rvpStatus === 1 ? null : (
                  <p className="replacement_new_txt">
                    <span />{" "}
                    <div className="ghanta-icon-wraper">
                      <Lottie options={defaultOptions} height={16} width={16} />
                    </div>
                    <div className="rvp-field">
                      <div className="left-rvp">
                        <span className="important_txt"> Important:</span> RVP
                        requests will be charged from your wallet as per the
                        rate card
                      </div>
                      <div className="rvp-right">{this.rvpPriceHandler()}</div>
                    </div>
                  </p>
                )}
              </div>
            ) : null}

            <div className="bottom_button">
              <Button
                className={
                  this.state.type.rvp === 1 ||
                  this.state.type.refund === 1 ||
                  this.state.type.replacement === 1
                    ? "clickable_btn"
                    : "not_clickablebtn"
                }
                onClick={() => this.onSaveChange(this.state.setAwb)}
              >
                {/* Continue <Icon type="caret-right" /> */}
                Continue
              </Button>
              <Button
                onClick={() => this.props.CloseToggleShipmentDisputeModal()}
                className="cancel_close_btn"
              >
                Cancel & Close
              </Button>
            </div>
          </div>
        }
      >
        <>
          <div className="variants_detail_header">
            <p className="varaint_text">Variant Details</p>
            <p className="quantity_txt">Quantity</p>
            <p className="action_txt">Action</p>
          </div>

          <div className="line-items-tab">
            {lineItems &&
              lineItems.map((li, i) => {
                return (
                  <div
                    className={`line-item ${
                      this.state.selectedLineItems.find(
                        (item) => item === li.lineitem_id
                      ) !== undefined
                        ? "selected"
                        : ""
                    }`}
                    key={li.lineitem_id}
                  >
                    <div className="line-item-product-detail">
                      <div className="mr">
                        <Checkbox
                          checked={
                            this.state.selectedLineItems.find(
                              (item) => item === li.lineitem_id
                            ) !== undefined
                          }
                          onChange={() =>
                            this.lineItemSelectedChange(li.lineitem_id)
                          }
                        />
                      </div>
                      <div className="mr">
                        <span
                          className="line-item-img"
                          style={{ background: `url(${li.img})` }}
                        />
                      </div>
                      <div className="mr">
                        <p className="blue-text line-item-name">
                          <Tooltip title={li.name} placement="topLeft">
                            {li.name}
                          </Tooltip>
                        </p>
                        <p className="line-item-sku">
                          <span style={{ color: "#898989" }}> SKU: </span>#
                          {li.sku}
                        </p>
                        <p className="line-item-sku">
                          <span style={{ color: "#898989" }}> Specs: </span>
                          {li.allspecs === "" ? "Default" : li.allspecs}
                        </p>
                      </div>
                    </div>

                    <div className="quantity">
                      {this.state.editLineItem[li.lineitem_id] ? (
                        <>
                          <InputNumber
                            value={li.quantity}
                            min={1}
                            max={li.originalQty}
                            onChange={(val) =>
                              this.onQtyChange(li.lineitem_id, val)
                            }
                          />
                        </>
                      ) : (
                        <p>
                          <span
                            className="mr-3"
                            style={{ color: "black", fontSize: "12px" }}
                          >
                            {li.quantity}
                          </span>
                        </p>
                      )}
                    </div>

                    <div className="action">
                      <Button
                        onClick={() =>
                          this.state.editLineItem[li.lineitem_id]
                            ? this.onSave(li.lineitem_id)
                            : this.onEdit(li.lineitem_id)
                        }
                        className="action_button"
                      >
                        {this.state.editLineItem[li.lineitem_id]
                          ? "save"
                          : "Edit Quantity"}
                      </Button>
                    </div>
                  </div>
                );
              })}
          </div>

          <div className="bottom_check-box">
            <p className="following_action">Requesting Following Action(s):</p>

            {(this.props.data &&
              this.props.data.request_status &&
              this.props.data.request_status.refund == 2) ||
            this.props.gst_enabled === 1 ? null : (
              <p className="refund_end">
                {this.props.refundedstatus === 1 ? (
                  <>
                    <span className="disabled_checkbox">
                      <Checkbox
                        checked={this.state.type.refund === 1}
                        disabled={true}
                      />
                    </span>
                    <span className="refund_end_checkbox disabledaction">
                      Refund End Customer
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <Checkbox
                        checked={this.state.type.refund === 1}
                        onChange={(e) =>
                          this.OnChangeCheckbox(
                            "refund",
                            e.target.checked ? 1 : 0
                          )
                        }
                      />
                    </span>
                    <span className="refund_end_checkbox">
                      Refund End Customer
                    </span>
                  </>
                )}
              </p>
            )}

            <p className="reverse_pickup">
              {this.props.rvpStatus === 1 ? (
                <>
                  <span className="disabled_checkbox">
                    <Checkbox
                      checked={this.state.type.rvp === 1}
                      disabled={true}
                    />
                  </span>
                  <span className="reverse_pickup_checkbox disabledaction">
                    Reverse Pickup
                  </span>
                </>
              ) : (
                <>
                  <span>
                    <Checkbox
                      checked={this.state.type.rvp === 1}
                      onChange={(e) =>
                        this.OnChangeCheckbox("rvp", e.target.checked ? 1 : 0)
                      }
                    />
                  </span>
                  <span className="reverse_pickup_checkbox">
                    Reverse Pickup
                  </span>
                </>
              )}
            </p>

            <p className="replacement">
              {this.props.replacementStatus === 1 ? (
                <>
                  {" "}
                  <span>
                    <Checkbox
                      checked={this.state.type.replacement === 1}
                      disabled={true}
                      className="disabled_checkbox"
                    />
                  </span>
                  <span className="reverse_pickup_checkbox disabledaction">
                    Replacement
                  </span>
                </>
              ) : (
                <>
                  <span>
                    <Checkbox
                      checked={this.state.type.replacement === 1}
                      onChange={(e) =>
                        this.OnChangeCheckbox(
                          "replacement",
                          e.target.checked ? 1 : 0
                        )
                      }
                    />
                  </span>
                  <span className="replacement_checkbox">Replacement</span>
                </>
              )}
            </p>
          </div>
        </>
      </Modal>
    );
  }
}

export default AddNewRequestModal;
