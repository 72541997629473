import React, { Component } from "react";
import {
  Alert,
  Skeleton,
  Row,
  Icon,
  Select,
  Card,
  Pagination,
  Tooltip,
  message,
} from "antd";
import InventoryCard from "./InventoryCard";
import InventoryCardLogs from "./InventoryCardLogs";
import InventoryRTO from "./InventoryRTO";
import axios from "axios";
import "./index.scss";
import { formatBalance } from "../../../../Helpers/Pricing";

import qs from "qs";
import InventoryOrderNav from "./InventoryOrderNav";
import NotFoundCard from "../../Common/NotFoundCard";
import ErrorCard from "../../Common/ErrorCard";

// const { Search } = Input;
const { Option } = Select;
export const statusName = {
  pending: "Pending",
  sourced: "Sourced",
  transit: "Transit",
  inventory_live: "Inventory Live",
};
export const statusColor = {
  pending: "#FC824E",
  sourced: "#43A0E8",
  transit: "#DDD715",
  inventory_live: "#15DD24",
};

class LiveInventory extends Component {
  state = {
    total_counts: { live_counts: 0, stock_price: 0 },
    total_counts_bad: { live_counts: 0, stock_price: 0 },

    activeTab: "inventory",
    inventorySort: "default",
    aggrSorted: "default",
    totalStockSort: "default",
    count: "",
    badInventoryCount: "",
    loadingInventoryCSV: false,
    filters: {
      search: "",
      location: "",
      endDate: "",
      startDate: "",
    },

    badInvFilters: {
      search: "",
      location: "",
      endDate: "",
      startDate: "",
    },

    logsfilters: {
      search: "",
      location: "",
      endDate: "",
      startDate: "",
      movement_type: [],
      type: "good",
    },

    rtoFilters: {
      search: "",
      location: "",
      endDate: "",
      startDate: "",
    },

    loading: false,
    inventoryloading: false,
    badInventoryLoading: false,
    badInventoryCSVLoading: false,

    errorObj: {
      inventory: {
        errorMsg: "",
        errorCode: "",
        error: false,
      },

      badInventory: {
        errorMsg: "",
        errorCode: "",
        error: false,
      },
      logs: {
        errorMsg: "",
        errorCode: "",
        error: false,
      },
      rto: {
        errorMsg: "",
        errorCode: "",
        error: false,
      },
    },

    errorMsg: "",
    logs: [],
    orders: [],
    badInventory: [],
    errorCode: "",
    errorTab: "",
    rto: [],
    total: 0,
    rtoTotal: 0,

    skuBreakup: {
      visible: false,
      data: null,
    },
    paging: {
      page: 1,
      per_page_count: 20,
    },

    rtoPaging: { page: 1, per_page_count: 20 },
  };

  toggleSKUBreakup(data = null) {
    this.setState({
      skuBreakup: {
        visible: data ? true : false,
        data,
      },
    });
  }

  fetchOrders() {
    this.setState(
      {
        inventoryloading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/sourcing/get_live_inventory",
          method: "POST",
          data: {
            storeid: this.props.match.params.storeid,
            filters: this.state.filters,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              let count = 0;

              res.data.data.forEach((c) => {
                count += c.total_live_qty;
              });

              this.setState({
                inventoryloading: false,
                orders: res.data.data,
                count: count,
                total_counts: res.data.total_counts,
              });
            } else {
              this.setState({
                inventoryloading: false,
                errorObj: {
                  ...this.state.errorObj,
                  inventory: {
                    error: true,
                  },
                },
              });
            }
          })
          .catch((e) => {
            // this.setState({
            //   inventoryloading: false,
            //   error: true,
            //   errorMsg: e.message,
            // });

            if (!!e.request) {
              this.setState({
                inventoryloading: false,
                errorObj: {
                  ...this.state.errorObj,
                  inventory: {
                    errorCode: e.request.status,
                    errorMsg: e.request.statusText,
                    error: true,
                  },
                },
              });
            } else {
              this.setState({
                inventoryloading: true,
                errorObj: {
                  ...this.state.errorObj,
                  inventory: {
                    errorCode: e.request.status,
                    errorMsg: e.request.statusText,
                    error: true,
                  },
                },
              });
            }
          });
      }
    );
  }
  fetchOrdersDownloadCSV() {
    this.setState(
      {
        loadingInventoryCSV: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/sourcing/get_live_inventory",
          method: "POST",
          data: {
            storeid: this.props.match.params.storeid,
            filters: this.state.filters,
            downloadcsv: 1,
          },
          withCredentials: true,
        })
          .then((res) => {
            this.setState({ loadingInventoryCSV: false });
            if (res.data.success === 1) {
              message.success(res.data.msg);
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({ loadingInventoryCSV: false });
            message.error(e.message);
          });
      }
    );
  }
  // fetch bad inventory

  fetchBadInventory() {
    this.setState(
      {
        badInventoryLoading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/sourcing/get_bad_inventory",
          method: "POST",
          data: {
            storeid: this.props.match.params.storeid,
            filters: this.state.badInvFilters,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              let count = 0;

              res.data.data.forEach((c) => {
                count += c.total_live_qty;
              });

              this.setState({
                badInventoryLoading: false,
                badInventory: res.data.data,
                badInventoryCount: count,
                total_counts_bad: res.data.total_counts,
              });
            } else {
              this.setState({
                inventoryloading: false,
                errorObj: {
                  ...this.state.errorObj,
                  badInventory: {
                    error: true,
                  },
                },
              });
            }
          })
          .catch((e) => {
            // this.setState({
            //   inventoryloading: false,
            //   error: true,
            //   errorMsg: e.message,
            // });

            if (!!e.request) {
              this.setState({
                badInventoryLoading: false,
                errorObj: {
                  ...this.state.errorObj,
                  badInventory: {
                    errorCode: e.request.status,
                    errorMsg: e.request.statusText,
                    error: true,
                  },
                },
              });
            } else {
              this.setState({
                badInventoryLoading: true,
                errorObj: {
                  ...this.state.errorObj,
                  badInventory: {
                    errorCode: e.request.status,
                    errorMsg: e.request.statusText,
                    error: true,
                  },
                },
              });
            }
          });
      }
    );
  }
  fetchBadInventoryCSV() {
    this.setState(
      {
        badInventoryCSVLoading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/sourcing/get_bad_inventory",
          method: "POST",
          data: {
            storeid: this.props.match.params.storeid,
            filters: this.state.badInvFilters,
            downloadcsv: 1,
          },
          withCredentials: true,
        })
          .then((res) => {
            this.setState({ badInventoryCSVLoading: false });
            if (res.data.success === 1) {
              message.success(res.data.msg);
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({ badInventoryCSVLoading: false });
            message.error(e.message);
          });
      }
    );
  }

  // logs
  fetchLogs() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/sourcing/inventory_logs",
          method: "POST",
          data: {
            storeid: this.props.match.params.storeid,
            filters: this.state.logsfilters,
            ...this.state.paging,
            page:
              !!this.state.logsfilters.search ||
              !!this.state.logsfilters.startDate
                ? 1
                : this.state.paging.page,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
                logs: res.data.data,
                total: res.data.total,
              });
            } else {
              this.setState({
                loading: false,
                errorObj: {
                  ...this.state.errorObj,
                  logs: {
                    error: true,
                  },
                },
              });
            }
          })
          .catch((e) => {
            this.setState({
              loading: false,
              error: true,
              errorMsg: e.message,
            });

            if (!!e.request) {
              this.setState({
                loading: false,
                errorObj: {
                  ...this.state.errorObj,
                  logs: {
                    errorCode: e.request.status,
                    errorMsg: e.request.statusText,
                    error: true,
                  },
                },
              });
            } else {
              this.setState({
                loading: true,
                errorObj: {
                  ...this.state.errorObj,
                  logs: {
                    errorCode: e.request.status,
                    errorMsg: e.request.statusText,
                    error: true,
                  },
                },
              });
            }
          });
      }
    );
  }

  // rto

  fetchRTO() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/sourcing/rto_inventory_logs",
          method: "POST",
          data: {
            storeid: this.props.match.params.storeid,
            filters: this.state.rtoFilters,
            // ...this.state.rtoPaging,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
                rto: res.data.data,
                rtoTotal: res.data.total,
              });
            } else {
              this.setState({
                loading: false,
                errorObj: {
                  ...this.state.errorObj,
                  rto: {
                    error: true,
                  },
                },
              });
            }
          })
          .catch((e) => {
            if (!!e.request) {
              this.setState({
                loading: false,
                errorObj: {
                  ...this.state.errorObj,
                  rto: {
                    errorCode: e.request.status,
                    errorMsg: e.request.statusText,
                    error: true,
                  },
                },
              });
            } else {
              this.setState({
                loading: true,
                errorObj: {
                  ...this.state.errorObj,
                  rto: {
                    errorCode: e.request.status,
                    errorMsg: e.request.statusText,
                    error: true,
                  },
                },
              });
            }
          });
      }
    );
  }

  // pagination function

  goToPage(page) {
    this.setState(
      {
        paging: { ...this.state.paging, page },
      },
      () => {
        this.fetchLogs();
      }
    );
  }

  rtoGoToPage(page) {
    this.setState(
      {
        rtoPaging: { ...this.state.rtoPaging, page },
      },
      () => {
        this.rtoGoToPage();
      }
    );
  }

  onShowSizeChange(current, pageSize) {
    this.setState(
      {
        paging: { ...this.state.paging, per_page_count: pageSize, page: 1 },
      },
      () => this.fetchLogs()
    );
  }

  rtoOnShowSizeChange(current, pageSize) {
    this.setState(
      {
        rtoPaging: {
          ...this.state.rtoPaging,
          per_page_count: pageSize,
          page: 1,
        },
      },
      () => this.fetchRTO()
    );
  }

  componentDidMount() {
    const params = qs.parse(this.props.location.search.replace(/\?/g, ""));
    if (params.search) {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            search: params.search,
          },
        },
        () => this.fetchOrders()
      );
    } else {
      this.fetchOrders();
      this.fetchLogs();
      this.fetchRTO();
      this.fetchBadInventory();
    }
  }
  componentDidUpdate(pp) {
    if (pp.location.search !== this.props.location.search) {
      const params = qs.parse(this.props.location.search.replace(/\?/g, ""));
      this.setState(
        {
          filters: {
            ...this.state.filters,
            search: params.search,
          },
        },
        () => this.fetchOrders()
      );
    }
  }

  // componentDidMount() {
  //   this.fetchOrders();
  // }

  searchhandler() {
    if (window.location.href.includes("live-inventory")) {
      this.props.history.push(
        `/${this.props.match.params.storegeo}/${this.props.match.params.storeid}/live-inventory?search=${this.state.filters.search}`
      );
    } else {
      this.props.history.push(
        `/${this.props.match.params.storegeo}/${this.props.match.params.storeid}/live-inventory?search=${this.state.filters.search}`
      );
    }
  }
  changeFilter(filters) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          ...filters,
        },
      },
      () => this.searchhandler(),
      () => this.fetchOrders()
    );
  }

  changeFilter2(logsfilters) {
    this.setState(
      {
        logsfilters: {
          ...this.state.logsfilters,
          ...logsfilters,
        },
      },

      () => this.fetchLogs()
    );
  }

  rtoFilterFunction(rto) {
    this.setState(
      {
        rtoFilters: {
          ...this.state.rtoFilters,
          ...rto,
        },
      },

      () => this.fetchRTO()
    );
  }

  changeHandler(kvpairs) {
    this.setState({
      filters: { ...this.state.filters, ...kvpairs },
    });
  }

  badChangeFilter(e) {
    this.setState(
      {
        badInvFilters: {
          ...this.state.badInvFilters,
          ...e,
        },
      },

      () => this.fetchBadInventory()
    );
  }

  badChangeHandler(kvpairs) {
    this.setState({
      badInvFilters: { ...this.state.badInvFilters, ...kvpairs },
    });
  }

  changeHandler2(kvpairs) {
    this.setState({
      logsfilters: { ...this.state.logsfilters, ...kvpairs },
    });
  }

  rtoChangeHandler(kvpairs) {
    this.setState({
      rtoFilters: { ...this.state.rtoFilters, ...kvpairs },
    });
  }

  tabHandler(tab) {
    this.setState({ loading: true }, () => {
      this.setState({ activeTab: tab, loading: false });
    });
  }

  customSorter() {
    if (this.state.inventorySort === "default") {
      let sorted = this.state.orders.sort(function(a, b) {
        return a.total_live_qty - b.total_live_qty;
      });
      this.setState({
        inventorySort: "asc",
        orders: sorted,
        totalStockSort: "default",
        aggrSorted: "default",
      });
    }

    if (this.state.inventorySort === "asc") {
      let sorted = this.state.orders.sort(function(a, b) {
        return b.total_live_qty - a.total_live_qty;
      });
      this.setState({
        inventorySort: "desc",
        orders: sorted,
        totalStockSort: "default",
        aggrSorted: "default",
      });
    }

    if (this.state.inventorySort === "desc") {
      this.setState({
        inventorySort: "default",
        totalStockSort: "default",
        aggrSorted: "default",
      });
    }
  }

  customSorterAggr() {
    if (this.state.aggrSorted === "default") {
      let sorted = this.state.orders.sort(function(a, b) {
        return (
          formatBalance(a.total_floating_value.total / a.total_live_qty) -
          formatBalance(b.total_floating_value.total / b.total_live_qty)
        );
      });

      this.setState({
        inventorySort: "default",
        orders: sorted,
        totalStockSort: "default",
        aggrSorted: "asc",
      });
    }

    if (this.state.aggrSorted === "asc") {
      let sorted = this.state.orders.sort(function(a, b) {
        return (
          formatBalance(b.total_floating_value.total / b.total_live_qty) -
          formatBalance(a.total_floating_value.total / a.total_live_qty)
        );
      });
      this.setState({
        inventorySort: "default",
        orders: sorted,
        totalStockSort: "default",
        aggrSorted: "desc",
      });
    }

    if (this.state.aggrSorted === "desc") {
      this.setState({
        inventorySort: "default",
        totalStockSort: "default",
        aggrSorted: "default",
      });
    }
  }

  customSorterStock() {
    if (this.state.totalStockSort === "default") {
      let sorted = this.state.orders.sort(function(a, b) {
        return (
          formatBalance(
            a.total_floating_value.base > 0
              ? a.total_floating_value.base
              : "N/A"
          ) -
          formatBalance(
            b.total_floating_value.base > 0
              ? b.total_floating_value.base
              : "N/A"
          )
        );
      });
      this.setState({
        totalStockSort: "asc",
        orders: sorted,
        inventorySort: "default",

        aggrSorted: "default",
      });
    }

    if (this.state.totalStockSort === "asc") {
      let sorted = this.state.orders.sort(function(a, b) {
        return (
          formatBalance(
            b.total_floating_value.base > 0
              ? b.total_floating_value.base
              : "N/A"
          ) -
          formatBalance(
            a.total_floating_value.base > 0
              ? a.total_floating_value.base
              : "N/A"
          )
        );
      });
      this.setState({
        totalStockSort: "desc",
        orders: sorted,
        inventorySort: "default",

        aggrSorted: "default",
      });
    }

    if (this.state.totalStockSort === "desc") {
      this.setState({
        totalStockSort: "default",
        inventorySort: "default",

        aggrSorted: "default",
      });
    }
  }

  render() {
    // console.log("up",this.props);
    const PageWrapper = (children) => {
      return (
        <div className="inventory-list">
          <InventoryOrderNav
            badInvFilters={this.state.badInvFilters}
            badChangeHandler={(e) => this.badChangeHandler(e)}
            badChangeFilter={(e) => this.badChangeFilter(e)}
            changeFilter={(e) => this.changeFilter(e)}
            changeHandler={(e) => this.changeHandler(e)}
            rtoChangeHandler={(e) => this.rtoChangeHandler(e)}
            changeHandler2={(e) => this.changeHandler2(e)}
            tabHandler={(e) => this.tabHandler(e)}
            loading={this.state.loading}
            filters={this.state.filters}
            rtoFilters={this.state.rtoFilters}
            logsfilter={this.state.logsfilters}
            count={this.state.count}
            badInventoryCount={this.state.badInventoryCount}
            activeTab={this.state.activeTab}
            storeId={this.props.match.params.storeid}
            logs={this.state.logs}
            rto={this.state.rto}
            rtoFilterFunction={(e) => this.rtoFilterFunction(e)}
            logsFunction={(e) => this.changeFilter2(e)}
            fetchOrdersDownloadCSV={() => this.fetchOrdersDownloadCSV()}
            loadingInventoryCSV={this.state.loadingInventoryCSV}
            LiveInventoryData={this.state.orders}
            fetchBadInventoryCSV={()=>this.fetchBadInventoryCSV()}
            badInventoryCSVLoading={this.state.badInventoryCSVLoading}
            badInventory={this.state.badInventory}
          />
          {children}
        </div>
      );
    };

    if (this.state.error) {
      return PageWrapper(
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={this.state.errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }

    if (
      this.state.errorObj.badInventory.error &&
      !this.state.badInventoryLoading &&
      this.state.activeTab === "badInventory"
    ) {
      return PageWrapper(
        <ErrorCard
          errorMsg={this.state.errorObj.badInventory.errorMsg}
          errorCode={this.state.errorObj.badInventory.errorCode}
        />
      );
    }

    // check inventory error

    if (
      this.state.errorObj.inventory.error &&
      !this.state.inventoryloading &&
      this.state.activeTab === "inventory"
    ) {
      return PageWrapper(
        <ErrorCard
          errorMsg={this.state.errorObj.inventory.errorMsg}
          errorCode={this.state.errorObj.inventory.errorCode}
        />
      );
    }

    // check logs error
    if (
      this.state.errorObj.logs.error &&
      !this.state.loading &&
      this.state.activeTab === "logs"
    ) {
      return PageWrapper(
        <ErrorCard
          errorMsg={this.state.errorObj.inventory.errorMsg}
          errorCode={this.state.errorObj.inventory.errorCode}
        />
      );
    }

    // check rto error
    if (
      this.state.errorObj.rto.error &&
      !this.state.loading &&
      this.state.activeTab === "RTO"
    ) {
      return PageWrapper(
        <ErrorCard
          errorMsg={this.state.errorObj.rto.errorMsg}
          errorCode={this.state.errorObj.rto.errorCode}
        />
      );
    }

    if (this.state.activeTab === "inventory" && this.state.inventoryloading) {
      return PageWrapper(
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    if (this.state.loading) {
      return PageWrapper(
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    if (this.state.activeTab === "RTO" && this.state.rto.length === 0) {
      return PageWrapper(
        <div
          style={{
            marginBottom: "30px",
            textAlign: "center",
            marginTop: "30px",
          }}
        >
          <NotFoundCard
            mainHeading={"Oops! No Data Found."}
            secondHeading={<p>Looks like there are no RTO in this page.</p>}
          />
        </div>
      );
    }

    if (this.state.activeTab === "logs" && this.state.logs.length === 0) {
      return PageWrapper(
        <div
          style={{
            marginBottom: "30px",
            textAlign: "center",
            marginTop: "30px",
          }}
        >
          <NotFoundCard
            mainHeading={"Oops! No Data Found."}
            secondHeading={<p>Looks like there are no logs in this page.</p>}
          />
        </div>
      );
    }

    if (
      this.state.activeTab === "badInventory" &&
      this.state.badInventoryLoading
    ) {
      return PageWrapper(
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    if (
      this.state.activeTab === "badInventory" &&
      this.state.badInventory.length === 0
    ) {
      return PageWrapper(
        <div
          style={{
            marginBottom: "30px",
            textAlign: "center",
            marginTop: "30px",
          }}
        >
          <NotFoundCard
            mainHeading={"Oops! No Data Found."}
            secondHeading={
              <p>Looks like there are no bad inventory in this page.</p>
            }
          />
        </div>
      );
    }

    if (
      this.state.activeTab === "inventory" &&
      this.state.orders.length === 0
    ) {
      return PageWrapper(
        <div
          style={{
            marginBottom: "30px",
            textAlign: "center",
            marginTop: "30px",
          }}
        >
          <NotFoundCard
            mainHeading={"Oops! No Data Found."}
            secondHeading={
              <p>Looks like there are no inventory in this page.</p>
            }
          />
        </div>
      );
    }

    if (this.state.activeTab === "RTO") {
      return PageWrapper(
        <div
          className="logs-tab rto"
          id={`${this.state.activeTab === "RTO" ? "RTO" : null}`}
        >
          {/* logs */}

          <div className="new-inventory-rto-logs">
            <div className="head">
              <div className="prod-details">Product Details</div>
              <div className="live-inv">Total RTO Qty</div>
              <div className="blank" />
            </div>
          </div>
          {!!this.state.rto &&
            this.state.rto.map((o) => (
              <InventoryRTO
                toggleSKUBreakup={(data) => this.toggleSKUBreakup(data)}
                order={o}
                key={o._id}
                statusName={statusName}
                statusColor={statusColor}
              />
            ))}
          {/* <Pagination
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            showSizeChanger
            showQuickJumper
            pageSizeOptions={["10", "25", "50"]}
            onShowSizeChange={(p, d) => this.rtoOnShowSizeChange(p, d)}
            current={this.state.rtoPaging.page}
            onChange={(page) => this.rtoGoToPage(page)}
            total={this.state.rtoTotal}
            defaultPageSize={this.state.rtoPaging.per_page_count}
          /> */}
        </div>
      );
    }

    if (this.state.activeTab === "logs") {
      return PageWrapper(
        <div
          className="logs-tab"
          id={`${this.state.activeTab === "logs" ? "logs" : null}`}
        >
          {/* logs */}

          <div className="new-inventory-card-logs">
            <div className="head">
              <div className="prod-details">Variant SKU</div>
              <div className="live-inv">Inventory Details</div>
              <div className="distribution">Inventory Status</div>
            </div>
          </div>
          {this.state.logs.map((o) => (
            <InventoryCardLogs
              toggleSKUBreakup={(data) => this.toggleSKUBreakup(data)}
              order={o}
              key={o._id}
              statusName={statusName}
              statusColor={statusColor}
            />
          ))}
          <Pagination
            locale={{ jump_to: "Go to page" }}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            showSizeChanger
            showQuickJumper
            pageSizeOptions={["10", "25", "50"]}
            onShowSizeChange={(p, d) => this.onShowSizeChange(p, d)}
            current={this.state.paging.page}
            onChange={(page) => this.goToPage(page)}
            total={this.state.total}
            defaultPageSize={this.state.paging.per_page_count}
          />
        </div>
      );
    }

    if (this.state.activeTab === "badInventory") {
      return PageWrapper(
        <div>
          <div className="new-inventory-card">
            <div className="head">
              <div className="prod-details">Product Details</div>
              <div className="live-inv">Live Inventory</div>
              <div className="pricing">Aggregated Unit Price</div>
              <div className="dist"> Total Stock Price</div>
              <div className="expand">Expand View</div>
            </div>
          </div>
          <div className="scroller">
            {this.state.badInventory.map((o) => (
              <InventoryCard
                // activeTab={this.state.activeTab}
                toggleSKUBreakup={(data) => this.toggleSKUBreakup(data)}
                order={o}
                key={o._id}
                statusName={statusName}
                statusColor={statusColor}
              />
            ))}
          </div>
          <div class="bottom-fix-counter bad">
            <div className="prod-details">Total Value</div>
            <div className="live-inv">
              {this.state.total_counts_bad.live_counts}
            </div>
            <div className="pricing" />
            <div className="dist">
              ₹ {formatBalance(this.state.total_counts_bad.stock_price)}{" "}
            </div>
            <div className="flexible" />
            <div className="expand" />
          </div>
        </div>
      );
    }

    return PageWrapper(
      <div id={`${this.state.activeTab === "logs" ? "logs" : null}`}>
        <div className="new-inventory-card">
          <div className="head">
            <div className="prod-details">Product Details</div>

            <Tooltip
              title={`Click to sort ${
                this.state.inventorySort === "asc"
                  ? "Descending"
                  : this.state.inventorySort === "desc"
                  ? "Default"
                  : "Ascending"
              } `}
            >
              <div onClick={() => this.customSorter()} className="live-inv">
                Live Inventory
                <div
                  className={`svg-wraper default ${this.state.inventorySort} `}
                >
                  <svg
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    data-icon="caret-up"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z" />
                  </svg>

                  <svg
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    data-icon="caret-down"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z" />
                  </svg>
                </div>
              </div>
            </Tooltip>

            <Tooltip
              title={`Click to sort ${
                this.state.aggrSorted === "asc"
                  ? "Descending"
                  : this.state.aggrSorted === "desc"
                  ? "Default"
                  : "Ascending"
              } `}
            >
              <div onClick={() => this.customSorterAggr()} className="pricing">
                Aggregated Unit Price
                <div className={`svg-wraper default ${this.state.aggrSorted} `}>
                  <svg
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    data-icon="caret-up"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z" />
                  </svg>

                  <svg
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    data-icon="caret-down"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z" />
                  </svg>
                </div>
              </div>
            </Tooltip>

            <Tooltip
              title={`Click to sort ${
                this.state.totalStockSort === "asc"
                  ? "Descending"
                  : this.state.totalStockSort === "desc"
                  ? "Default"
                  : "Ascending"
              } `}
            >
              <div onClick={() => this.customSorterStock()} className="dist">
                Total Stock Price
                <div
                  className={`svg-wraper default ${this.state.totalStockSort} `}
                >
                  <svg
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    data-icon="caret-up"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z" />
                  </svg>

                  <svg
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    data-icon="caret-down"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z" />
                  </svg>
                </div>
              </div>
            </Tooltip>

            {/* <div className="flexible"> flexible</div> */}
            {/* <div className="flexible">Actions</div> */}
            <div className="expand custom">Action</div>
          </div>
        </div>

        <div className="scroller">
          {this.state.orders.map((o) => (
            <InventoryCard
              activeTab={this.state.activeTab}
              toggleSKUBreakup={(data) => this.toggleSKUBreakup(data)}
              order={o}
              key={o._id}
              statusName={statusName}
              statusColor={statusColor}
              // {...this.props}
            />
          ))}
        </div>

        <div class="bottom-fix-counter">
          <div className="prod-details">Total Value</div>
          <div className="live-inv">{this.state.total_counts.live_counts}</div>
          <div className="pricing" />
          <div className="dist">
            ₹ {formatBalance(this.state.total_counts.stock_price)}{" "}
          </div>
          {/* <div className="flexible" /> */}
          <div className="expand" />
        </div>
      </div>
    );
  }
}

export default LiveInventory;
