import React, { Component } from "react";
import {
  Row,
  Skeleton,
  Alert,
  Modal,
  Button,
  Icon,
  DatePicker,
  message,
} from "antd";
// import Joyride from "react-joyride";
import Joyride, { ACTIONS, EVENTS, LIFECYCLE, STATUS } from "react-joyride";

import { connect } from "react-redux";
import axios from "axios";
import ConnectShopifyModal from "./ConnectShopifyModal";
import OverallStats from "./OverallStats";
import moment from "moment";
// import SalesReturnsGraph from "./SalesReturnsGraph";
import Graph from "./Graph";
// import welcomeImage from "../../../assets/images/backgrounds/WelcomeCelebration.png"
import AllNotification from "../Notifications/AllNotification";
import RecentSoldProduct from "./RecentSoldProduct";
import MostSellingProduct from "./MostsellingProduct";
import StatisticCardS from "./StatisticCard";
import OrderStatus from "./Orderstatus";
import vipulImage from "../../../assets/images/vipul.png";
import { ReactComponent as rightArrow } from "../../../assets/images/icons/long-arrow-pointing-to-the-right.svg";
import "./index.scss";
import vcimage from "../../../assets/images/icons/vc.png";
import SnapshotStepCard from "./SnapshotStepCard";
import ViewStepsCard from "./ViewStepsCard";
import AppTourEndModal from "./AppTour/TourEndModal";
import { steps } from "./AppTour/AppTourDataArray";
import VideoTooltip from "../Common/VideoTooltip";

// import ViewStepsCard2 from "./ViewStepsCard/index2.js";

const { RangePicker } = DatePicker;

class Snapshot extends Component {
  state = {
    customHeight: 0,
    activation_status: 0,
    showWelcome: false,
    manualShowWelcome: false,
    welcomeType: "",
    welcomModalLoadig: false,
    steps: [],
    app_uninstall: undefined,
    contactCenterModal: false,
    contactCenterModalStatus: false,
    alerts: {
      sync_products: 1,
      connect_products: 0,
      unlinked_products_count: 0,
    },
    store_currency: "USD",
    overallStats: null,
    connectShopifyModalVisible: false,
    loading: true,
    error: false,
    errorMsg: "",
    snapshot: true,

    SnapShotGraph: {
      startDate: moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },

    mostsellingproducts: [],
    mostrecentproduct: [],
    sales: [],
    revenue: [],
    products: [],
    loadcounterdata: [],

    showHideheaderdata: true,
    countsteps: 0,
    transform: `rotateX(0deg)`,
    transform2: `rotateX(180deg)`,
    setstoregeo: "",
    cardClicked: false,
    tourEndModal: false,
  };

  cardClickHandler() {
    this.setState({ cardClicked: !this.state.cardClicked });
  }

  tourRef = React.createRef();

  fetchSnapshot() {
    this.setState(
      {
        loading: true,
      },
      () =>
        axios({
          url: process.env.REACT_APP_API_URL + "/snapshot/",
          method: "post",
          data: {
            storeid: this.props.auth.store.id,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              let emailVerified = true;

              res.data.steps.forEach((e) => {
                if (e.id === "EMAIL_VERIFIED" && e.completed === false) {
                  return (emailVerified = false);
                }
              });

              if (emailVerified === false) {
                this.props.history.push("/onboarding");
                return;
              }

              // const steps = res.data.steps;
              var countsteps = 0;
              for (var i = 0; i < res.data.steps.length; i++) {
                if (res.data.steps[i].completed === true) {
                  countsteps++;
                }
              }

              if (this.props.auth.store.store_geo === "global") {
                if (countsteps === 10) {
                  this.setState({ showHideheaderdata: false });
                }
              } else {
                if (countsteps === 11) {
                  this.setState({ showHideheaderdata: false });
                }
              }

              this.setState(
                {
                  activation_status: res.data.activation_status,
                  showWelcome: res.data.showWelcome,
                  welcomeType: res.data.welcomeType,
                  product_updates_available: res.data.product_updates_available,
                  steps: res.data.steps,
                  app_uninstall: res.data.app_uninstall,

                  store_currency: res.data.store_currency,
                  alerts: { ...this.state.alerts, ...res.data.alerts },
                  overallStats: res.data.analytics,
                  storeCreatedDate: res.data.store_created.split(" ")[0],
                  loading: false,
                  countsteps: countsteps,
                  setstoregeo: res.data.store_geo,
                },
                () => {
                  const steps = {};
                  this.state.steps.forEach((s) => {
                    steps[s.id] = s.completed;
                  });

                  this.setState({ stepsObj: steps });
                }
              );
            } else {
              this.setState({
                error: true,
                errorMsg: res.data.msg,
                loading: false,
              });
            }
          })
          .catch((e) => {
            this.setState({
              error: true,
              errorMsg: e.message,
              loading: false,
            });
          })
    );
  }

  contactCenterModalEnable() {
    this.setState({ contactCenterModalStatus: true });

    // axios({
    //   url: process.env.REACT_APP_API_URL + "/snapshot/contact_center_setting",
    //   method: "post",
    //   withCredentials: true,
    //   data: { storeid: `${this.props.match.params.storeid}` },
    // })
    //   .then((res) => {
    //     if (res.data.success === 1) {
    //     } else {
    //     }
    //   })
    //   .catch((e) => {
    //     message.error(e.message);
    //   });
  }

  loadcounterdata() {
    this.setState({ loading: true }, () => {
      axios({
        url: process.env.REACT_APP_API_URL + "/snapshot/snapshot_counters",
        method: "post",
        data: {
          storeid: this.props.auth.store.id,
          start_date: this.state.SnapShotGraph.startDate,
          end_date: this.state.SnapShotGraph.endDate,
        },
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.setState({
              loadcounterdata: res.data.data,
              loading: false,
            });
          } else {
            this.setState({
              error: true,
              errorMsg: res.data.msg,
              loading: false,
            });
          }
        })
        .catch((e) => {
          this.setState({
            error: true,
            errorMsg: e.message,
            loading: false,
          });
        });
    });
  }

  loadGraphData() {
    this.setState({ loading: true }, () => {
      axios({
        url: process.env.REACT_APP_API_URL + "/snapshot/snapshot_graph",
        method: "post",
        data: {
          storeid: this.props.auth.store.id,
          start: this.state.SnapShotGraph.startDate,
          end: this.state.SnapShotGraph.endDate,
        },
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.setState({
              sales: res.data.date.sale,
              revenue: res.data.date.revenue,
              loading: false,
            });
          } else {
            this.setState({
              error: true,
              errorMsg: res.data.msg,
              loading: false,
            });
          }
        })
        .catch((e) => {
          this.setState({
            error: true,
            errorMsg: e.message,
            loading: false,
          });
        });
    });
  }

  loadData() {
    this.setState({ loading: true }, () => {
      axios({
        url:
          process.env.REACT_APP_API_URL +
          "/snapshot/get_top_5_most_selling_product",
        method: "post",
        data: {
          storeid: this.props.auth.store.id,
          start_date: this.state.SnapShotGraph.startDate,
          end_date: this.state.SnapShotGraph.endDate,
        },
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.setState({
              mostsellingproducts: res.data.products,
              loading: false,
            });
          } else {
            this.setState({
              error: true,
              errorMsg: res.data.msg,
              loading: false,
            });
          }
        })
        .catch((e) => {
          this.setState({
            error: true,
            errorMsg: e.message,
            loading: false,
          });
        });
    });
  }

  componentDidMount() {
    // console.log(this.props);
    // this.fetchSnapshot();
    // this.loadGraphData();
    // this.loadcounterdata();
    // window.location.assign(
    //   `${process.env.REACT_APP_NEW_APP}/switch-store/${this.props.auth.store.store_geo}/${this.props.auth.store.id}?redirect=${this.props.auth.store.store_geo}/${this.props.auth.store.id}`
    // );
  }

  toggleShopifyModal() {
    this.setState({
      connectShopifyModalVisible: !this.state.connectShopifyModalVisible,
    });
  }

  hideWelcome() {
    this.setState(
      { welcomModalLoadig: true, showWelcome: false, manualShowWelcome: true },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/snapshot/welcome_shown",
          method: "post",
          data: {
            storeid: this.props.auth.store.id,
          },
          withCredentials: true,
        }).then(() => {});
      }
    );
  }

  manualShowWelcomeHandler() {
    this.setState({ manualShowWelcome: !this.state.manualShowWelcome });
  }

  loadAllData() {
    this.loadData();
    // this.loadrecentProduct();
    this.loadGraphData();
    this.loadcounterdata();
  }
  changeField(date) {
    this.setState(
      {
        SnapShotGraph: {
          ...this.state.SnapShotGraph,
          ...date,
        },
      },
      () => this.loadAllData()
    );
  }
  linketonotification() {
    this.el.scrollIntoView({ behavior: "smooth" });
  }

  hideComponent(name) {
    if (name) {
      this.setState({
        showHideheaderdata: !this.state.showHideheaderdata,
        // transform: "rotateX(180deg)",
      });
    }
  }

  handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({ manualShowWelcome: false, tourEndModal: true });
    }

    console.groupCollapsed(type);
    console.log(data); //eslint-disable-line no-console
    console.groupEnd();
  };

  joyrideLocale = {
    back: "Back",
    close: "Close",
    last: "Next",
    next: "Next",
    skip: "I’ll Explore on my own",
  };

  render() {
    if (true) {
      window.location.assign(`${process.env.REACT_APP_NEW_APP}/switch-store`);

      // return <Skeleton active />;
    }

    // if (this.state.loading) {
    //   return <Skeleton active />;
    // }

    if (!this.state.loading && this.state.error) {
      return (
        <Row>
          <Alert
            message="There was an error"
            description={this.state.errorMsg}
            type="error"
          />
        </Row>
      );
    }

    return (
      <div className="snapshot-page">
        {/* subscription plans */}

        <Joyride
          ref={this.tourRef}
          showSkipButton={true}
          locale={{ ...this.joyrideLocale }}
          disableOverlayClose
          run={this.state.manualShowWelcome}
          autoStart={true}
          disableBeaco={true}
          placement="left"
          continuous={true}
          steps={steps}
          callback={(e) => this.handleJoyrideCallback(e)}
        />
        <div className="heading">
          <div>
            <h1
              className="content-page-title capitalize-text"
              style={{ marginBottom: 0 }}
            >
              Hello {this.props.auth.user.user_fname.split(" ")[0]}!{" "}
              <VideoTooltip
                showOverviewTour={true}
                showQuickOverTourHandler={() => this.manualShowWelcomeHandler()}
                title="Snapshot Overview"
                contentHeadings={["Snapshot"]}
                content={[
                  "Snapshot provides the summary of activity in your Dashboard with Quick Overview of Reports along with Actionable and Important Updates.",
                ]}
                // subtitle="Snapshot provides the summary of activity in your Dashboard with Quick Overview of Reports, Actionable and Important Updates."
              />
            </h1>
            <h3 className="content-page-subtitle" style={{ marginBottom: 0 }}>
              Welcome to vFulfill - an end-to-end eCommerce ecosystem.
            </h3>
          </div>

          {this.state.app_uninstall ? null : (
            <div className="Onboarding_Steps">
              <button
                style={{ display: "flex", alignItems: "center" }}
                className="ant-dropdown-link"
                onClick={() => this.hideComponent("showHideheaderdata")}
              >
                {" "}
                {this.props.auth.store.store_geo === "global" ? (
                  <span
                    className={
                      this.state.countsteps === 10 ? "green_text" : "red_text"
                    }
                  >
                    {this.state.countsteps}
                    /10
                  </span>
                ) : (
                  <span
                    className={
                      this.state.countsteps === 11 ? "green_text" : "red_text"
                    }
                  >
                    {this.state.countsteps + 1 > 11
                      ? this.state.contactCenterModalStatus === true
                        ? this.state.countsteps
                        : this.state.countsteps
                      : this.state.countsteps}
                    {/* {this.state.countsteps + 1 > 9
                  ? this.state.countsteps + 1
                  : this.state.countsteps} */}
                    /11
                  </span>
                )}{" "}
                <span
                  style={{
                    textDecoration: "underline",
                    marginRight: "10px",
                    marginLeft: "8px",
                  }}
                >
                  Onboarding Steps Completed
                </span>
                <span>
                  <svg
                    style={{
                      marginLeft: "5px",
                      transition: "all 0.5s",
                      transform: `${
                        this.state.showHideheaderdata
                          ? "rotateX(180deg)"
                          : "rotateX(0deg)"
                      }`,
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                  >
                    <g
                      id="Polygon_49"
                      data-name="Polygon 49"
                      transform="translate(16 8) rotate(180)"
                      fill={`${
                        this.state.showHideheaderdata ? "#212121" : "none"
                      }`}
                    >
                      <path d="M8,0l8,8H0Z" stroke="none" />
                      <path
                        d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                        stroke="none"
                        fill="#000"
                      />
                    </g>
                  </svg>
                </span>
              </button>
            </div>
          )}
        </div>

        <ConnectShopifyModal
          visible={this.state.connectShopifyModalVisible}
          onCancel={() => this.toggleShopifyModal()}
          auth={this.props.auth}
          {...this.props}
        />

        <Row
          className="row1"
          style={{
            transition: "max-height .4s linear",
            marginBottom: "0rem",

            maxHeight: `${
              this.state.showHideheaderdata === true ? "821px" : "0px"
            }`,
            padding: "0px 5px",
            margin: "0 -5px",
            overflow: "hidden",
          }}
        >
          <OverallStats
            {...this.props}
            app_uninstall={this.state.app_uninstall}
            // app_uninstall={true}
            stateCounter={this.state.contactCenterModalStatus}
            enableModal={() => this.contactCenterModalEnable()}
            animation={this.state.showHideheaderdata}
            steps={this.state.steps}
            toggleShopifyModal={() => this.toggleShopifyModal()}
            unlinkedProductsCount={this.state.alerts.unlinked_products_count}
            analytics={this.state.overallStats}
            store_currency={this.state.store_currency}
            setstoregeo={this.state.setstoregeo}
          />
        </Row>

        {/* <p>Rahul kumar </p> */}

        {/* <SnapshotStepCard
          clicked={this.state.cardClicked}
          steps={this.state.steps}
          setstoregeo={this.state.setstoregeo}
          store_currency={this.state.store_currency}
          cardClickHandler={() => this.cardClickHandler()}
        />
        <ViewStepsCard clicked={this.state.cardClicked} /> */}
        {/* <ViewStepsCard2 /> */}
        <div>
          <OrderStatus
            {...this.props}
            steps={this.state.steps}
            unlinkedProductsCount={this.state.alerts.unlinked_products_count}
            analytics={this.state.overallStats}
            store_currency={this.state.store_currency}
            linketonotification={() => this.linketonotification()}
          />
        </div>
        <div className="snapshot-picker">
          <div className="Your_Snapshot">
            <p>Your Snapshot</p>
          </div>
          <div className="right_datepicker">
            <span>Showing data from :</span>

            <RangePicker
              disabledDate={(current) => {
                return current > moment();
              }}
              style={{ marginLeft: "10px", maxWidth: "300px" }}
              // defaultValue={[moment(this.state.start), moment(this.state.end)]}
              value={[
                this.state.SnapShotGraph.startDate === ""
                  ? null
                  : moment(this.state.SnapShotGraph.startDate, "YYYY-MM-DD"),
                this.state.SnapShotGraph.endDate === ""
                  ? null
                  : moment(this.state.SnapShotGraph.endDate, "YYYY-MM-DD"),
              ]}
              ranges={{
                Today: [moment(), moment()],
                "This Week": [moment().startOf("week"), moment().endOf("week")],
                "Last 15 Days": [moment().subtract("days", 15), moment()],
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
                "Last Month": [
                  moment()
                    .subtract(1, "month")
                    .startOf("month"),
                  moment()
                    .subtract(1, "month")
                    .endOf("month"),
                ],
                Overall: [moment(this.state.storeCreatedDate), moment()],
              }}
              onChange={(_, dateString) =>
                this.changeField({
                  startDate: dateString[0],
                  endDate: dateString[1],
                })
              }
            />
          </div>
        </div>

        <StatisticCardS
          {...this.props}
          store_currency={this.state.store_currency}
          {...this.state}
          loadcounterdata={this.state.loadcounterdata}
        />
        <div className="gr" style={{ background: "#fff" }}>
          <Graph
            store_currency={this.state.store_currency}
            {...this.props}
            sales={this.state.sales}
            revenue={this.state.revenue}
          />
        </div>
        <div>
          <MostSellingProduct {...this.props} {...this.state} />
        </div>
        {/* <RecentSoldProduct {...this.props} {...this.state} /> */}
        <div
          className="Snapshot_notifications"
          ref={(el) => {
            this.el = el;
          }}
        >
          <AllNotification {...this.props} {...this.state} />
        </div>

        {/* <Modal
          visible={this.state.showWelcome}
          closable={false}
          maskClosable={false}
          footer={null}
          className="welcome-modal"
        >
          <div style={{ textAlign: "center" }}>
            <img src={vipulImage} alt="" />
            <h3>
              {this.props.auth.user.user_fname.split(" ")[0]} Welcome To
              vFulfill!
            </h3>
            <p style={{ marginBottom: "0px" }}>
              Vipul, this side, co-founder & CEO at vFulfill.{" "}
            </p>
            <br />
            {this.state.welcomeType === "yes_india" && (
              <p>
                We are happy you are here! With vFulfill, we are focussed to
                enable entrepreneurs like you to get started with dropshipping
                in India and make HUGE profits. <br />
                <br />
                India, as you see, is an untapped market with a huge potential.
                And our features like cash on delivery, buy back options on
                returns, real time sourcing from China enables anyone to
                leverage the 2-3X times lesser CPAs in India and make profits
                from day 1.
                <br />
                <br />
                Now focus on what really matters to you the most, bring orders &
                let us do the heavy lifting!!
              </p>
            )}
            {this.state.welcomeType === "no_india" && (
              <p>
                We are happy you are here! With vFulfill, we are focussed to
                bring dropshipping in India, the way it should be…Simple,
                profitable, and easy eCommerce business! <br />
                <br />
                In India, dropshipping, when scaled, often looks like “Stock-
                shipping,” resulting in one needing to invest huge sums, stock
                products, and being able to test only limited products
                <br />
                <br />
                With vFulfill, we cut this hassle with our features like:
                real-time sourcing & shipping from China, COD enablement, buy
                back on returns, etc., enabling anyone to make profits with even
                low volumes, from day 1 itself.
                <br />
                <br />
                So, let’s get started & make tons of money!!
              </p>
            )}
            {this.state.welcomeType === "no_dropship" && (
              <p>
                We are glad to know that you want to be a part of dropshipping
                revolution in India. <br />
                <br />
                With super cool services like{" "}
                <b>Cash On Delivery, Returns and Stocking</b> done and perfected
                to the finest so that you could focus on what really matters:{" "}
                <b>MAKING MONEY.</b>
                <br />
                <br />
                Just kidding, what really matters is that you enjoy using our
                service, fulfill your dream and make buttloads of money!
              </p>
            )}

            <Button
              style={{ marginTop: "24px" }}
              loading={this.state.welcomModalLoadig}
              type="primary"
              onClick={() => this.hideWelcome()}
            >
              Let’s Go{" "}
              <Icon
                component={rightArrow}
                style={{ transform: "rotate(180deg)" }}
              />
            </Button>
          </div>
        </Modal> */}

        {/* Last tour modal */}
        <AppTourEndModal
          visible={this.state.tourEndModal}
          closeModal={() => this.setState({ tourEndModal: false })}
        />

        {/* con */}
        <Modal
          className="contact-cen"
          visible={this.state.contactCenterModal}
          footer={null}
          onCancel={() => this.setState({ contactCenterModal: false })}
        >
          <div class="contact-center-modal">
            <h1>Contact Centre Settings</h1>
            <h2>Steps to enable Contact Centre Services:</h2>
            <div class="logo">
              <div class="vc">
                <img src={vcimage} alt="" />
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="43.033"
                height="38.73"
                viewBox="0 0 43.033 38.73"
              >
                <path
                  id="icons8-connect"
                  d="M38.578,3a6.488,6.488,0,0,0-6.455,6.455,6.337,6.337,0,0,0,.651,2.761l-8,8H14.507a6.455,6.455,0,1,0,0,4.3H24.777l8,8a6.337,6.337,0,0,0-.651,2.761,6.455,6.455,0,1,0,6.455-6.455,6.337,6.337,0,0,0-2.761.651l-7.106-7.106,7.106-7.106a6.337,6.337,0,0,0,2.761.651,6.455,6.455,0,1,0,0-12.91Zm0,4.3a2.152,2.152,0,1,1-2.152,2.152A2.12,2.12,0,0,1,38.578,7.3ZM8.455,20.213A2.152,2.152,0,1,1,6.3,22.365,2.12,2.12,0,0,1,8.455,20.213Zm30.123,12.91a2.152,2.152,0,1,1-2.152,2.152A2.12,2.12,0,0,1,38.578,33.123Z"
                  transform="translate(-2 -3)"
                />
              </svg>

              <svg
                style={{ marginLeft: "30px" }}
                id="g1440"
                xmlns="http://www.w3.org/2000/svg"
                width="53.757"
                height="53.715"
                viewBox="0 0 53.757 53.715"
              >
                <path
                  id="path53330"
                  d="M-.448,0H53.288l-.021,53.715H-.469Z"
                  transform="translate(0.469)"
                  fill="#191919"
                />
                <path
                  id="path53332"
                  d="M442.343,379.578c.005.008.017.013.022.023a.247.247,0,0,1,.025.032c.005.01.017.023.022.035s.012.025.018.037.012.027.017.04a.186.186,0,0,1,.012.042c0,.013.005.029.01.044a.192.192,0,0,1,.005.045.392.392,0,0,1,0,.046c0,.01,0,.022,0,.033s0,.031,0,.046,0,.031,0,.045a.229.229,0,0,1-.01.045.213.213,0,0,1-.012.043.208.208,0,0,1-.017.042c-.005.013-.012.027-.017.039s-.012.025-.018.037-.017.023-.022.034a.192.192,0,0,1-.025.031.376.376,0,0,1-.028.027.257.257,0,0,1-.028.023.136.136,0,0,1-.031.018c-.01,0-.022.01-.031.013l-.032.01a.167.167,0,0,1-.033,0h-.022a.185.185,0,0,1-.033,0,.135.135,0,0,1-.033-.008l-.032-.012a.125.125,0,0,1-.031-.017l-.031-.02c-.01-.008-.018-.017-.028-.024a.144.144,0,0,1-.025-.028.249.249,0,0,1-.025-.031c-.005-.01-.017-.023-.022-.035s-.012-.025-.018-.038-.012-.026-.017-.04a.189.189,0,0,1-.012-.042c0-.013-.005-.029-.01-.044a.194.194,0,0,1-.005-.045.223.223,0,0,1,0-.027L434.7,399.421h6.3l.04,0a.422.422,0,0,0,.056-.01.446.446,0,0,0,.056-.018.464.464,0,0,0,.054-.025.527.527,0,0,0,.052-.032.573.573,0,0,0,.05-.039.653.653,0,0,0,.046-.046q.022-.025.043-.052t.039-.058q.018-.03.035-.063c.01-.021.02-.043.028-.066l0-.008,7.067-19.432Zm-1.006,18.427.025.027-.025-.027Zm-.778.123-.018.036Zm-.035.063q-.017.033-.031.067Q440.507,398.224,440.523,398.191Zm1.041.451q0,.038,0,.075Q441.564,398.68,441.565,398.642Z"
                  transform="translate(-415.217 -362.577)"
                  fill="#67e9d9"
                />
                <path
                  id="path53334"
                  d="M234.041,376.7l3.765,11.053.01.028q.061.161.131.314l.3.876.19.558,2.284,6.705.01.028q.012.033.025.064t.033.064q.018.031.038.06t.042.054a.656.656,0,0,0,.046.048.593.593,0,0,0,.049.041.543.543,0,0,0,.052.035.489.489,0,0,0,.054.027.457.457,0,0,0,.056.02.408.408,0,0,0,.113.015h6.33L243.8,385.641l-.01-.028q-.061-.161-.131-.314l-.3-.876-.19-.558-2.284-6.705-.01-.028q-.013-.033-.028-.064t-.034-.064q-.018-.031-.038-.059a.678.678,0,0,0-.042-.054.649.649,0,0,0-.046-.048.592.592,0,0,0-.049-.041.522.522,0,0,0-.052-.034.5.5,0,0,0-.054-.027.468.468,0,0,0-.056-.02.442.442,0,0,0-.057-.012.416.416,0,0,0-.056,0h-6.327Zm5.927.223q-.021.027-.039.058h0q.019-.03.039-.058Zm-.074.121q-.017.033-.031.067h0q.014-.035.031-.067Zm-.038.091q-.009.024-.017.048h0c.005-.016.011-.032.017-.048Zm1.019.735q-.015.035-.031.067h0Q240.861,377.909,240.875,377.874Zm-.045.095-.018.036h0Zm-.031,17.424h0l-.018.036.018-.036Zm-.035.063h0q-.017.033-.031.067.014-.035.031-.067Zm1.048.536q0,.034-.005.068Q241.81,396.026,241.811,395.992Zm-.042.219h0c-.005.016-.011.032-.017.048Q241.762,396.235,241.77,396.211Zm-.025.071h0q-.015.035-.031.067Q241.73,396.316,241.744,396.282Zm-.065.131h0q-.019.03-.039.058Q241.661,396.443,241.679,396.412Z"
                  transform="translate(-223.544 -359.842)"
                  fill="#5db3ff"
                />
                <path
                  id="path53336"
                  d="M562.342,379.358l-4,.008h-.4l-11.625-.041h-.093a.678.678,0,0,0-.078.008c-.025,0-.052.008-.077.013a.385.385,0,0,0-.075.02l-.072.025-.069.031a.519.519,0,0,0-.064.036.454.454,0,0,0-.059.041.6.6,0,0,0-.054.045c-.017.017-.033.032-.048.049s-.025.031-.037.047l-.018.031-2.14,6.214,10.176-.02v0l6.385-.008Z"
                  transform="translate(-519.078 -362.346)"
                  fill="#fefefe"
                />
                <path
                  id="path53338"
                  d="M657.534,613.436H648.8l-1.748,4.812,8.735-.005Z"
                  transform="translate(-618.069 -585.977)"
                  fill="#fefefe"
                />
              </svg>
            </div>

            <p>
              1. Please connect your store on which you want to contact centre
              services enabled.
            </p>
            <p>
              2. Go to this page to install vConversio app
              <span>
                <a
                  href="https://app.vconversio.com/vfulfill/login"
                  target="_blank"
                >
                  here
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.259"
                    height="11.448"
                    viewBox="0 0 11.259 11.448"
                  >
                    <g
                      id="Group_3617"
                      data-name="Group 3617"
                      transform="translate(-961.362 -333.152)"
                    >
                      <path
                        id="Path_84"
                        data-name="Path 84"
                        d="M-4594.261-691.587h-1.944v10h10V-683.4"
                        transform="translate(5558.167 1025.587)"
                        fill="none"
                        stroke="#1370fc"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.2"
                      />
                      <g
                        id="Group_3178"
                        data-name="Group 3178"
                        transform="translate(965.64 334)"
                      >
                        <path
                          id="Path_85"
                          data-name="Path 85"
                          d="M-4590.015-685.789l6.133-6.2"
                          transform="translate(4590.015 691.988)"
                          fill="none"
                          stroke="#1370fc"
                          stroke-linecap="round"
                          stroke-width="1.2"
                        />
                        <path
                          id="Path_86"
                          data-name="Path 86"
                          d="M-4587.394-691.587h4.074v3.886"
                          transform="translate(4589.643 691.587)"
                          fill="none"
                          stroke="#1370fc"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.2"
                        />
                      </g>
                    </g>
                  </svg>{" "}
                </a>
              </span>
            </p>
            <p>3. Enter store name and login to vConversio app.</p>
            <p>4. Go to settings page in vConversio app.</p>
            <p>5. Click Dialer settings in vConversio app.</p>
            <p>6. Activate the dialer settings in vConversio app.</p>

            <button
              onClick={() => this.setState({ contactCenterModal: false })}
            >
              {" "}
              Close Window
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(Snapshot);
