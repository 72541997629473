import {
  Card,
  Checkbox,
  Col,
  Dropdown,
  Icon,
  Menu,
  message,
  Modal,
  Row,
  Tooltip,
  Switch,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { SHOPIFY_NAME } from "../../../../../constants";
import PLACEHOLDER_PRODUCT_IMAGE from "../../../../../assets/images/icons/image_placeholder.png";
import { hasPermission } from "../../../../../Helpers/Permissions";
import downArrowImage from "../../../../../assets/images/icons/downArrow.png";
import upArrowImage from "../../../../../assets/images/icons/upArrow.png";
import OpenInStore from "../../../Common/GlobalCommon/OpenInStore";
import StoreProductTitle from "../../../Common/GlobalCommon/ProductTitle/StoreTitle";
import {
  formatNumber,
  getCurrencySymbol,
} from "../../../../../Helpers/Pricing";
import "./index.scss";
import ClickToCopy from "../../../Common/ClickToCopy";
import { ReactComponent as GreenIcon } from "../../../../../assets/images/icons/circle-filled.svg";
import { ReactComponent as RightClick } from "../../../../../assets/images/icons/attcheck.svg";
const { confirm } = Modal;

class MyProduct extends Component {
  state = {
    confirmShopifyDelete: false,
    escalateLoading: false,
    escalateNowButton:
      this.props.product.user_buyback_setting &&
      this.props.product.buyback === 1
        ? true
        : false,
    flexibleFullfillment: this.props.flexible_fullfillment_on ? true : false,
    currentImgIndex: 0,
    loading: false,
    escalated: true,
    response: "",
    clicked: false,
    createBundleModalVisible: false,
    // bundlecreatemodal: true,
  };

  handlebuyback(ids, storeid, checked) {
    this.setState(
      {
        escalateLoading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/products/enable_buyback",
          method: "POST",
          data: {
            productid: ids,
            storeid: storeid,
            buyback: checked ? 1 : 0,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                escalateLoading: false,
                escalateNowButton:
                  this.state.escalateNowButton === true ? false : true,
              });

              message.success(res.data.msg);
              // this.props.loadData();
            } else {
              this.setState({
                escalateLoading: false,
              });
              message.warning(res.data.msg);
            }
          })
          .catch((err) => {
            this.setState({
              escalateLoading: false,
            });
            message.warning(err.message);
          });
      }
    );
  }
  //flexable fullfillment api
  // handleflexibleFullfillment(ids, storeid, checked) {
  //   this.setState(
  //     {
  //       escalateLoading: true,
  //     },
  //     () => {
  //       axios({
  //         url: process.env.REACT_APP_API_URL + "/products/enable_buyback",//new api have to be given by backend
  //         method: "POST",
  //         data: {
  //           productid: ids,
  //           storeid: storeid,
  //           flexible_fullfillment_on: checked ? 1 : 0,
  //           page_type:"product"
  //         },
  //         withCredentials: true,
  //       })
  //         .then((res) => {
  //           if (res.data.success === 1) {
  //             this.setState({
  //               escalateLoading: false,
  //               flexibleFullfillment:
  //                 this.state.flexibleFullfillment === true ? false : true,
  //             });

  //             message.success(res.data.msg);
  //             // this.props.loadData();
  //           } else {
  //             this.setState({
  //               escalateLoading: false,
  //             });
  //             message.warning(res.data.msg);
  //           }
  //         })
  //         .catch((err) => {
  //           this.setState({
  //             escalateLoading: false,
  //           });
  //           message.warning(err.message);
  //         });
  //     }
  //   );
  // }
  escalated = () => {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/products/escalated_my_product",
          method: "post",
          data: {
            id: this.props.product._id.$oid,
            store_id: this.props.product.product_storeid.$oid,
          },
          withCredentials: true,
        })
          .then((res) => {
            message.destroy();
            return res;
          })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({ escalated: false });

              message.success("Requested Successfully!");
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            message.error(e.message);
          });
      }
    );
  };

  requestUpdatedQuotation() {
    this.setState(
      {
        loading: true,
      },
      () => {
        if (this.cancelToken) {
          this.cancelToken.cancel();
        }
        this.cancelToken = axios.CancelToken.source();
        axios({
          url: process.env.REACT_APP_API_URL + "/user/create_partner_request",
          method: "post",
          cancelToken: this.cancelToken.token,
          data: {
            type: "vfcatalog_update_quotation",
            data: { vfprodid: this.props.product.vfprod.vfprodid },
            storeid: this.props.storeid,
          },
          withCredentials: true,
        })
          .then((res) => {
            this.setState({
              loading: false,
              response: res.data.msg,
              status: res.data.success,
              clicked: true,
            });

            this.props.lessRequestCount();
            return res;
          })
          .catch((e) => {
            this.setState({
              response: "There was an error!",
            });
            // console.log(e);
          });
      }
    );
  }

  bundleToggleLinkProductModal() {
    this.setState({
      createBundleModalVisible: !this.state.createBundleModalVisible,
    });
  }

  componentDidMount() {
    if (
      this.props.product.vfprod &&
      this.props.product.vfprod.lbh_escalated === 1
    ) {
      this.setState({
        escalateNowButton: true,
      });
    }
  }

  nextImg() {
    this.setState((state) => ({
      currentImgIndex:
        state.currentImgIndex === this.props.product.vfprod.gallery.length - 1
          ? 0
          : state.currentImgIndex + 1,
    }));
  }

  prevImg() {
    this.setState((state) => ({
      currentImgIndex:
        state.currentImgIndex === 0
          ? this.props.product.vfprod.gallery.length - 1
          : state.currentImgIndex - 1,
    }));
  }

  escalateNow() {
    this.setState(
      {
        escalateLoading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/products/escalate_lbh",
          method: "POST",
          data: {
            storeid: this.props.storeid,
            productid: this.props.product._id["$oid"],
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                escalateLoading: false,
                escalateNowButton: true,
              });
              message.success("Requested Successfully!");
            } else {
              this.setState({
                escalateLoading: false,
              });
              message.warning(res.data.msg);
            }
          })
          .catch((err) => {
            this.setState({
              escalateLoading: false,
            });
            message.warning(err.message);
          });
      }
    );
  }

  deleteProduct(ids) {
    const that = this;
    confirm({
      title: "Are you sure you want to delete?",
      content: (
        <div>
          <p>
            Once product deleted cannot be recovered. You need to import again.
          </p>
          <Checkbox
            defaultChecked={that.state.confirmShopifyDelete}
            onChange={(e) =>
              that.setState({ confirmShopifyDelete: e.target.checked })
            }
          >
            {`Delete From ${SHOPIFY_NAME} As Well`}
          </Checkbox>
        </div>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        that.props.deleteProduct(ids, that.state.confirmShopifyDelete);
        that.setState({ confirmShopifyDelete: false });
      },
      onCancel() {
        that.setState({ confirmShopifyDelete: false });
      },
    });
  }

  onFieldChange(storeid, productid, field, value, showAlert = true) {
    axios({
      url: process.env.REACT_APP_API_URL + "/products/update_product_field",
      method: "post",
      data: {
        storeid,
        productid,
        field,
        value,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          if (showAlert) {
            message.success("Updated Successfully");
          }
        } else {
          if (showAlert) {
            message.error(res.data.msg);
          }
        }
      })
      .catch((e) => {
        if (showAlert) {
          message.error(e.message);
        }
      });
  }

  productMessage = (product) => {
    let messageStatus = "default";
    if (product.product_status !== "connected") {
      messageStatus = "unlinked";
    } else {
      if (product.catalog_complete === 1) {
        messageStatus = "default";
      } else {
        if (product.rejected === 1) {
          messageStatus = "rejected";
        } else {
          if (product.escalated === 0) {
            messageStatus = "doescalate";
          } else if (product.escalated === 1) {
            messageStatus = "escalated";
          } else if (product.escalated === -1) {
            messageStatus = "doreminder";
          } else if (product.escalated === -2) {
            messageStatus = "reminded";
          }
        }
      }
    }
    return messageStatus;
  };

  handleVisibleChange = (flag) => {
    this.setState({ visible: flag });
  };

  handleMenuClick = (e) => {
    let el = document.querySelector(".navhead");

    if (el.classList.contains("downnav")) {
      el.classList.remove("downnav");
      el.classList.add("upnav");
    }

    if (e.key === "2") {
      this.setState({ visible: true });
    } else {
      this.setState({ visible: false });
    }
  };

  render() {
    const { product, currencies, store, flexibleFullfillmentOn } = this.props;
    let normalPrices = {};
    let messageStatus = this.productMessage(product);
    console.log(product);
    const vfmrp =
      product.vfprod && product.vfprod.prod_status === "1"
        ? { ...product.vfprod.retail_sourcing }
        : {};
    if (this.props.store.store_geo === "in") {
      // vfmrp.min = vfmrp.min / currencies["INR"];
      // vfmrp.max = vfmrp.max / currencies["INR"];
      // vfmrp.avg = vfmrp.avg / currencies["INR"];
    }

    const vfmrp2 =
      product.vfprod && product.vfprod.prod_status === "1"
        ? { ...product.vfprod.retail_sourcing }
        : {};
    if (this.props.store.store_geo === "in") {
      // vfmrp2.min = vfmrp2.min / currencies["USD"];
      // vfmrp2.max = vfmrp2.max / currencies["USD"];
      // vfmrp2.avg = vfmrp2.avg / currencies["USD"];
    }

    let linked = false;
    let showEscalateNow = false;
    const diff = moment().diff(moment(product.product_created), "hours");
    if (diff >= 24) {
      showEscalateNow = true;
      // console.log(showEscalateNow);
    }

    if (product.product_aliid) {
      linked = true;
    }
    let provisional = false;
    if (
      linked &&
      ((product.vfprod && product.vfprod.prod_status === "-1") ||
        (product.vfprod && product.vfprod.prod_status === "-2"))
    ) {
      provisional = true;
    }
    if (linked) {
      normalPrices = {
        ...product.prices,
        shop_price: {
          ...product.prices.shop_price,
          min: product.prices.shop_price.min,
          // /
          // (this.props.store.store_geo === "in" ? currencies.INR : 1),
          max: product.prices.shop_price.max,
          //  /
          // (this.props.store.store_geo === "in" ? currencies.INR : 1),
          avg: product.prices.shop_price.avg,
          // /
          // (this.props.store.store_geo === "in" ? currencies.INR : 1),
        },
      };
    }

    return (
      <Card
        id="test"
        className={`my-products-card ${linked ? "" : "not-linked"} ${
          provisional ? "provisional" : ""
        }`}
      >
        {/* {provisional && (
          <div className="ribbon">
            <span>In Processing...</span>
          </div>
        )} */}
        <Row>
          <Col xs={24} md={4}>
            <div
              className="img-container"
              style={{
                position: "relative",
                background: `url(${
                  product.vfprod &&
                  product.vfprod.gallery &&
                  product.vfprod.gallery[this.state.currentImgIndex]
                    ? product.vfprod.gallery[this.state.currentImgIndex]
                    : !!product.gallery[this.state.currentImgIndex]
                    ? product.gallery[this.state.currentImgIndex]
                    : PLACEHOLDER_PRODUCT_IMAGE
                })`,
              }}
            >
              {!linked ? null : (
                <div>
                  <button className="prev-img" onClick={() => this.prevImg()}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17.163"
                      height="41"
                      viewBox="0 0 17.163 41"
                    >
                      <g>
                        <path
                          d="M.915 19.4L14.63.932a.95.95 0 011.616.038 1.942 1.942 0 010 2.139L3.339 20.491l12.907 17.382a1.937 1.937 0 010 2.176.95.95 0 01-1.616 0L.915 21.579a1.937 1.937 0 010-2.179z"
                          fill="#fff"
                          stroke="#fff"
                        />
                      </g>
                    </svg>
                  </button>

                  <button className="next-img" onClick={() => this.nextImg()}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17.163"
                      height="41"
                      viewBox="0 0 17.163 41"
                    >
                      <g>
                        <path
                          d="M16.247 19.4L2.532.932A.95.95 0 00.916.97a1.942 1.942 0 000 2.139l12.907 17.382L.916 37.873a1.937 1.937 0 000 2.176.95.95 0 001.616 0l13.715-18.47a1.937 1.937 0 000-2.179z"
                          fill="#fff"
                          stroke="#fff"
                        />
                      </g>
                    </svg>
                  </button>
                </div>
              )}
            </div>
            <span style={{ textAlign: "center", display: "inherit" }}>
              {linked ? (
                product.vfprod && product.vfprod.vfprodid ? (
                  <OpenInStore
                    page={"storeproducts"}
                    url={product.shopify_url}
                  />
                ) : (
                  <p />
                )
              ) : null}
            </span>
          </Col>

          <Col xs={24} md={20} className="text-container">
            <Row
              style={{
                borderBottom: "1px solid #CECECE",
              }}
            >
              <Col xs={24} md={15}>
                <p
                  className={
                    product.catalog_complete === 1
                      ? "blue-store-product-title"
                      : "black-store-product-title"
                  }
                >
                  <StoreProductTitle
                    productname={product.name}
                    storegeo={this.props.geo}
                    storeid={this.props.storeid}
                    vfprodid={product.vfprod.vfprodid}
                    catalogComplete={product.catalog_complete}
                  />
                </p>

                <h2 style={{ fontSize: "12px", margin: "0px 0px 16px 0px" }}>
                  <span
                    style={{
                      color: "#898989",
                      fontWeight: "400 !important",
                      letterSpacing: "0.032em",
                    }}
                  >
                    PRODUCT ID:{" "}
                  </span>{" "}
                  {product.vfprod.vfprodid === undefined ? (
                    <span className="not-available-txt">Not Available</span>
                  ) : (
                    <span className="copied-id">
                      <ClickToCopy
                        textToCopy={product.vfprod.vfprodid}
                        style={{ color: "blue" }}
                      />
                    </span>
                  )}
                  {flexibleFullfillmentOn ? (
                    <span className="flex-on">
                      <span>|</span>
                      <RightClick /> Flexible Fullfillment{" "}
                    </span>
                  ) : null}
                </h2>
              </Col>

              <Col
                xs={24}
                lg={9}
                style={{ textAlign: "right" }}
                className="action_btn"
              >
                {product.vfprod && product.vfprod.vfprodid ? (
                  product.shopify_url ? (
                    <Link
                      className="clickable"
                      target="_blank"
                      to={`/${this.props.geo}/${this.props.storeid}/products/${
                        product.vfprod.vfprodid
                      }`}
                      style={{
                        marginRight: "28px",
                        padding: "14px 21.15px",
                        lineHeight: "12px",
                        background: "#0068FF",
                        border: "none",
                        color: "#fff",
                        borderRadius: "2px",
                        fontSize: "12px",
                        cursor: "pointer",
                        display: "inline-block",
                        marginBottom: "16px",
                      }}
                    >
                      View In vF Catalog
                    </Link>
                  ) : product.bundle_product_id ? (
                    <Link
                      style={{
                        marginRight: "28px",
                        padding: "14px 21.15px",
                        lineHeight: "12px",
                        background: "#0068FF",
                        border: "none",
                        color: "#fff",
                        borderRadius: "2px",
                        fontSize: "12px",
                        cursor: "pointer",
                        display: "inline-block",
                        marginBottom: "16px",
                      }}
                    >
                      View Bundle Products
                    </Link>
                  ) : null
                ) : null}

                {linked && (
                  <Dropdown
                    visible={this.state.visible}
                    onVisibleChange={this.handleVisibleChange}
                    overlay={
                      <Menu
                        onClick={this.handleMenuClick}
                        className="my-product-card-dropdown-menu"
                        style={{ width: "200px", textAlign: "left" }}
                      >
                        {this.props.geo === "global" ? null : (
                          <Menu.Item key="2">
                            Buyback{" "}
                            <Switch
                              checked={
                                this.state.escalateNowButton === true ? 1 : 0
                              }
                              onChange={(checked) =>
                                this.handlebuyback(
                                  product._id["$oid"],
                                  product.product_storeid.$oid,
                                  checked
                                )
                              }
                              style={{
                                color: "#000",
                                width: "30px",
                                marginLeft: "8px",
                              }}
                              size="small"
                            />
                          </Menu.Item>
                        )}
                        {this.props.geo === "global" ? null : (
                          <Menu.Item key="2">
                            <Link
                              to={`/${
                                this.props.storeid
                              }/storeproductviewvariants/${
                                product.vfprod.vfprodid
                              }/${this.props.viewvariantmatching}#${
                                product.bundle_product_id
                              }`}
                              target="_blank"
                            >
                              Flexible Fulfilment{" "}
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "5px",
                                }}
                              >
                                <svg
                                  className="svI"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13.019"
                                  height="13.207"
                                  viewBox="0 0 13.019 13.207"
                                  stroke="#898989"
                                >
                                  <g
                                    id="Group_3577"
                                    data-name="Group 3577"
                                    transform="translate(-961.462 -333.293)"
                                    stroke="#898989"
                                  >
                                    <path
                                      id="Path_84"
                                      data-name="Path 84"
                                      d="M-4591.471-691.587h-4.733v12h12v-4.576"
                                      transform="translate(5558.167 1025.587)"
                                      fill="none"
                                      stroke="#898989"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1"
                                    />
                                    <g
                                      id="Group_3178"
                                      data-name="Group 3178"
                                      transform="translate(967.669 334)"
                                      stroke="#898989"
                                    >
                                      <path
                                        id="Path_85"
                                        data-name="Path 85"
                                        d="M-4590.015-685.817l6.1-6.171"
                                        transform="translate(4590.015 691.988)"
                                        fill="none"
                                        stroke="#898989"
                                        stroke-linecap="round"
                                        stroke-width="1"
                                      />
                                      <path
                                        id="Path_86"
                                        data-name="Path 86"
                                        d="M-4587.394-691.587h4.055v3.868"
                                        transform="translate(4589.632 691.587)"
                                        fill="none"
                                        stroke="#898989"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="1"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </span>
                            </Link>
                          </Menu.Item>
                        )}
                        <Menu.Item>
                          <a
                            href="#"
                            onClick={() =>
                              this.props.toggleLinkImportListmodal()
                            }
                          >
                            Link Product
                          </a>
                        </Menu.Item>

                        <Menu.Item>
                          <a
                            href="#"
                            onClick={() => this.props.toggleBundelModal()}
                          >
                            Create Bundle
                          </a>
                        </Menu.Item>

                        {product.catalog_complete === 1 ? (
                          <Menu.Item>
                            <Link
                              to={`/${
                                this.props.storeid
                              }/storeproductviewvariants/${
                                product.vfprod.vfprodid
                              }/${this.props.viewvariantmatching}#${
                                product.bundle_product_id
                              }`}
                            >
                              View Variant Map
                            </Link>
                          </Menu.Item>
                        ) : null}

                        {hasPermission(store, "delete_products") && (
                          <Menu.Item key="3">
                            <a
                              href="#/"
                              onClick={() =>
                                this.deleteProduct(product._id["$oid"])
                              }
                            >
                              Delete
                            </a>
                          </Menu.Item>
                        )}
                      </Menu>
                    }
                    className="dropdownaction"
                  >
                    <span
                      className="ant-dropdown-link"
                      style={{
                        color: "#898989",
                        fontWeight: "400",
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                    >
                      Actions
                      <img
                        src={downArrowImage}
                        className="down-arrow"
                        alt={"Action down Arrow"}
                        style={{ marginLeft: "10px" }}
                      />
                      <img
                        src={upArrowImage}
                        className="up-arrow"
                        alt={"Action up Arrow"}
                        style={{ marginLeft: "10px" }}
                      />
                    </span>
                  </Dropdown>
                )}
              </Col>
            </Row>
            <Row style={{ paddingTop: "15px" }}>
              <Col xs={24} md={12}>
                <p
                  style={{
                    color: "#898989",
                    fontWeight: "400",
                    textAlign: "center",
                    fontSize: "12px",
                    letterSpacing: "0.032em",
                  }}
                >
                  <p
                    style={{
                      color: "#898989",
                      fontWeight: "400",
                      textAlign: "center",
                      fontSize: "12px",
                      letterSpacing: "0.032em",
                      textAlign: "left",
                    }}
                  >
                    {" "}
                    {`${SHOPIFY_NAME} Selling Price:`}
                  </p>

                  <span
                    style={{
                      background: "#F5F5F5",
                      display: "block",
                      marginRight: "17px",
                      padding: "6px 8px",
                      height: "32px",
                      textAlign: "center",
                      fontSize: "12px",
                      marginTop: "5px",
                    }}
                  >
                    {!linked ? (
                      "Product Not Linked"
                    ) : (
                      <React.Fragment>
                        {currencies.store_currency !== "USD" &&
                          (normalPrices.shop_price.min !==
                          normalPrices.shop_price.max ? (
                            <span style={{ color: "#1C6513" }}>
                              {getCurrencySymbol(currencies.store_currency)}{" "}
                              {normalPrices.shop_price.avg.toFixed(2)}
                            </span>
                          ) : (
                            <span style={{ color: "#1C6513" }}>
                              {getCurrencySymbol(currencies.store_currency)}{" "}
                              {normalPrices.shop_price.avg.toFixed(2)}
                            </span>
                          ))}
                        {currencies.store_currency === "USD" ||
                        currencies.store_currency === null ? (
                          normalPrices.shop_price.min !==
                          normalPrices.shop_price.max ? (
                            <span
                              style={{
                                color: "#1C6513",
                              }}
                            >
                              {getCurrencySymbol(currencies.store_currency)}{" "}
                              {formatNumber(product.prices.shop_price.avg)}
                            </span>
                          ) : (
                            <span style={{ color: "#1C6513" }}>
                              {getCurrencySymbol(currencies.store_currency)}{" "}
                              {formatNumber(product.prices.shop_price.avg)}
                            </span>
                          )
                        ) : null}
                      </React.Fragment>
                    )}

                    {this.props.store.store_geo === "in" &&
                    product.vfprod &&
                    product.vfprod.suggested_sellingprice ? (
                      <span
                        className="shopify-sell"
                        style={{
                          float: "right",
                          color: "#FF9500",
                          position: "relative",
                          fontSize: "16px",
                        }}
                      >
                        <Icon type="warning" theme="filled" />

                        <span
                          className="shopify-sell-tooltip"
                          style={{
                            width: "355px",
                            position: "absolute",
                            right: "-7px",
                            top: "25px",
                            border: "2px solid #FF9500",
                            zIndex: "99",
                            textAlign: "left",
                            background: "#FDF7F0",
                          }}
                        >
                          <span
                            style={{
                              background: "#FF9500",
                              padding: "5px 25px 5px 10px",
                              display: "inline-block",
                              color: "#fff",
                              fontSize: "12px",
                            }}
                          >
                            Suggested Selling Price:
                          </span>
                          <span
                            style={{
                              padding: "5px 48px",
                              // display: "inline-block",
                              color: "#000",
                              textAlign: "center",
                              fontSize: "12px",
                              width: "193px",
                            }}
                          >
                            <span style={{ color: "#1c6513" }}>
                              {" "}
                              {getCurrencySymbol("INR")}{" "}
                              {formatNumber(
                                product.vfprod.suggested_sellingprice
                              )}{" "}
                            </span>
                            {/* /piece */}
                          </span>
                        </span>
                      </span>
                    ) : null}
                  </span>
                </p>
              </Col>

              <Col xs={24} md={12}>
                <p
                  style={{
                    marginRight: "17px",
                    color: "#898989",
                    fontWeight: "400",
                    fontSize: "12px",
                    letterSpacing: "0.032em",
                  }}
                >
                  <span>Estimated Product Cost:</span>

                  <span className="request-latest-price">
                    {moment().diff(
                      moment(this.props.product.vfprod.lastupdated),
                      "days"
                    ) > 7 ? (
                      product.vfprod && product.vfprod.prod_status === "1" ? (
                        product.request_latest_price.success === 0 ? (
                          product.request_latest_price.success === 0 &&
                          this.props.requestCount === 0 ? (
                            <Tooltip
                              title={
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: "12px",
                                    letterSpacing: "0.032em",
                                  }}
                                  className="cursor-pointer"
                                >
                                  Request Latest Price
                                  <span
                                    style={{
                                      fontWeight: "500",
                                      textDecoration: "underline",
                                      display: "block",
                                    }}
                                  >
                                    Pricing Requests Left: 0
                                  </span>
                                  <span
                                    style={{
                                      marginTop: "20px",
                                      display: "block",
                                    }}
                                  >
                                    <i>*Your 5 Pricing Requests will be</i>
                                  </span>
                                  <span
                                    style={{
                                      display: "block",
                                    }}
                                  >
                                    <i>reset next week.</i>
                                  </span>
                                </p>
                              }
                            >
                              <span
                                style={{ float: "right", fontSize: "12px" }}
                                className="cursor-pointer"
                              >
                                Request Latest Price
                              </span>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: "12px",
                                    letterSpacing: "0.032em",
                                  }}
                                  className="cursor-pointer"
                                >
                                  Request Latest Price
                                  <span
                                    style={{
                                      fontWeight: "500",
                                      textDecoration: "underline",
                                      display: "block",
                                    }}
                                  >
                                    {product.request_latest_price.msg}
                                  </span>
                                </p>
                              }
                            >
                              <span
                                style={{ float: "right", fontSize: "12px" }}
                                className="cursor-pointer"
                              >
                                Request Latest Price
                              </span>
                            </Tooltip>
                          )
                        ) : (
                          <Tooltip
                            title={
                              this.props.requestCount === 0 ? (
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: "12px",
                                  }}
                                  className="cursor-pointer"
                                >
                                  Request Latest Price
                                  <span
                                    style={{
                                      fontWeight: "500",
                                      textDecoration: "underline",
                                      display: "block",
                                    }}
                                  >
                                    Pricing Requests Left: 0
                                  </span>
                                  <span
                                    style={{
                                      marginTop: "20px",
                                      display: "block",
                                    }}
                                  >
                                    <i>*Your 5 Pricing Requests will be</i>
                                  </span>
                                  <span
                                    style={{
                                      display: "block",
                                    }}
                                  >
                                    <i>reset next week.</i>
                                  </span>
                                </p>
                              ) : (
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: "12px",
                                    letterSpacing: "0.032em",
                                  }}
                                  className="cursor-pointer"
                                >
                                  Request latest price{" "}
                                  <span
                                    style={{
                                      textAlign: "center",
                                      textDecoration: "underline",
                                      display: "block",
                                    }}
                                  >
                                    {" "}
                                    Pricing Requests Left:{" "}
                                    {this.props.requestCount}
                                  </span>{" "}
                                </p>
                              )
                            }
                          >
                            <span style={{ float: "right" }}>
                              <span
                                style={{
                                  color: "#0068FF",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                                className={`req-latest-price cursor-pointer ${
                                  this.props.requestCount === 0 ||
                                  this.state.clicked === true
                                    ? `sv3`
                                    : null
                                }`}
                                onClick={() => this.requestUpdatedQuotation()}
                              >
                                Request Latest Price
                                <div
                                  className="loader"
                                  style={{
                                    display: this.state.loading
                                      ? "inline-block"
                                      : "none",
                                    width: 15,
                                    height: 15,
                                    marginLeft: 8,
                                    verticalAlign: "bottom",
                                  }}
                                />
                              </span>
                            </span>
                          </Tooltip>
                        )
                      ) : (
                        <Tooltip
                          title={
                            <p
                              style={{ textAlign: "center", fontSize: "12px" }}
                            >
                              {" "}
                              You can request latest pricing after you have
                              linked this product{" "}
                            </p>
                          }
                        >
                          <span
                            style={{ float: "right", fontSize: "12px" }}
                            className="cursor-pointer"
                          >
                            Request Latest Price
                          </span>
                        </Tooltip>
                      )
                    ) : (
                      <Tooltip
                        title={
                          <p
                            style={{
                              textAlign: "center",
                              fontSize: "10px",
                              letterSpacing: "0.032em",
                            }}
                            className="cursor-pointer"
                          >
                            Request Latest Price
                            <span
                              style={{
                                fontWeight: "500",
                                textDecoration: "underline",
                                display: "block",
                              }}
                            >
                              Pricing last updated on:{" "}
                              {moment().diff(
                                moment(this.props.product.vfprod.lastupdated),
                                "days"
                              ) === 1
                                ? `${moment().diff(
                                    moment(
                                      this.props.product.vfprod.lastupdated
                                    ),
                                    "days"
                                  )} Day ago`
                                : `${moment().diff(
                                    moment(
                                      this.props.product.vfprod.lastupdated
                                    ),
                                    "days"
                                  )} Days ago`}
                            </span>
                            <p>
                              You can request latest price after (
                              {7 -
                                moment().diff(
                                  moment(this.props.product.vfprod.lastupdated),
                                  "days"
                                ) ===
                              1
                                ? `1 Day`
                                : `${7 -
                                    moment().diff(
                                      moment(
                                        this.props.product.vfprod.lastupdated
                                      ),
                                      "days"
                                    )} Days`}
                              )
                            </p>
                          </p>
                        }
                      >
                        <span
                          style={{ float: "right", fontSize: "12px" }}
                          className="cursor-pointer"
                        >
                          {" "}
                          Request Latest Price
                        </span>
                      </Tooltip>
                    )}
                  </span>

                  <br />

                  <span
                    className="store-product-cost-1"
                    style={{
                      background: "#F5F5F5",
                      height: "32px",
                      textAlign: "center",
                      border:
                        !provisional &&
                        product.vfprod &&
                        product.vfprod.retail_pricing_discount
                          ? "2px solid red"
                          : "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: `${
                        provisional || !linked ? "center" : "space-between"
                      }`,
                      marginTop: "5px",
                    }}
                  >
                    {provisional ? (
                      <>
                        <span
                          style={{
                            padding: "6px 8px",
                            display: "block",
                            fontSize: "12px",
                            textAlign: "center",
                            color: "#898989",
                          }}
                        >
                          In Processing...
                        </span>
                      </>
                    ) : !linked ? (
                      "Product Not Linked"
                    ) : (
                      <>
                        {product.vfprod &&
                        product.vfprod.retail_pricing_discount ? (
                          <span
                            className="store-product-cost-2"
                            style={{
                              background: "#FD104D",
                              padding: "6px 8px 6px",
                              display: "inline-block",
                              color: "#fff",
                              fontSize: "12px",
                              marginLeft: -1,
                              minWidth: "24%",
                            }}
                          >
                            {product.vfprod &&
                              product.vfprod.retail_pricing_discount}
                            % off
                          </span>
                        ) : null}

                        <span
                          className="store-product-cost-3"
                          style={{
                            padding: "6px 8px",
                            display: "inline-block",
                            fontSize: "12px",
                            textAlign: "center",
                            width: "100%",
                            color: "#898989",
                          }}
                        >
                          <React.Fragment>
                            {currencies.store_currency !== "USD" &&
                              (vfmrp.min !== vfmrp.max ? (
                                <span>
                                  <span
                                    className="ff4"
                                    style={{
                                      color: "#212121",
                                      fontSize: "11px",
                                      marginRight: "8px",
                                      textDecoration: "line-through",
                                      textDecorationColor: "#898989",
                                    }}
                                  >
                                    {" "}
                                    {product.vfprod.default_retail_sourcing
                                      ? getCurrencySymbol(
                                          this.props.currencies
                                            .store_currency === "INR"
                                            ? "INR"
                                            : "USD"
                                        )
                                      : null}{" "}
                                    {product.vfprod.default_retail_sourcing
                                      ? product.vfprod.default_retail_sourcing
                                          .avg
                                      : null}
                                  </span>
                                  <span style={{ color: "#1C6513" }}>
                                    {getCurrencySymbol(
                                      this.props.currencies.store_currency ===
                                        "INR"
                                        ? "INR"
                                        : "USD"
                                    )}{" "}
                                    {vfmrp.avg.toFixed(2)}
                                  </span>
                                </span>
                              ) : this.props.store.store_geo === "in" &&
                                currencies.store_currency !== "INR" ? null : (
                                <span>
                                  {product.vfprod &&
                                  product.vfprod.default_retail_sourcing &&
                                  Object.keys(
                                    product.vfprod.default_retail_sourcing
                                  ).length > 0 ? (
                                    <span
                                      style={{
                                        color: "#212121",
                                        fontSize: "12px",
                                        marginRight: "8px",
                                        textDecoration: "line-through",
                                        textDecorationColor:
                                          "rgb(137, 137, 137)",
                                      }}
                                    >
                                      {getCurrencySymbol(
                                        this.props.currencies.store_currency ===
                                          "INR"
                                          ? "INR"
                                          : "USD"
                                      )}{" "}
                                      {
                                        product.vfprod.default_retail_sourcing
                                          .max
                                      }
                                    </span>
                                  ) : null}
                                  <span>
                                    <span style={{ color: "#1C6513" }}>
                                      {getCurrencySymbol(
                                        this.props.currencies.store_currency ===
                                          "INR"
                                          ? "INR"
                                          : "USD"
                                      )}{" "}
                                      {vfmrp.avg.toFixed(2)}
                                      {/* {toStoreCurrency(
                                        vfmrp.avg,
                                        currencies,
                                        true
                                      )} */}
                                    </span>
                                  </span>
                                </span>
                              ))}

                            {this.props.store.store_geo === "in" &&
                            currencies.store_currency !== "INR" ? (
                              currencies.store_currency !== "INR" ||
                              currencies.store_currency === null ? (
                                vfmrp2.min !== vfmrp2.max ? (
                                  // <Tooltip
                                  //   title={`$ ${formatNumber(
                                  //     vfmrp2.min
                                  //   )} - $ ${formatNumber(vfmrp2.max)}`}
                                  // >
                                  //   <span
                                  //     style={{
                                  //       color: "#2BC822",
                                  //     }}
                                  //   >
                                  //     {" "}
                                  //     ₹ {formatNumber(vfmrp2.avg)}
                                  //   </span>
                                  // </Tooltip>
                                  <span
                                    style={{
                                      color: "#1C6513",
                                    }}
                                  >
                                    {" "}
                                    {getCurrencySymbol(
                                      this.props.currencies.store_currency ===
                                        "INR"
                                        ? "INR"
                                        : "USD"
                                    )}{" "}
                                    {formatNumber(vfmrp2.avg)}
                                  </span>
                                ) : (
                                  <span>
                                    <span>
                                      {product.vfprod &&
                                      product.vfprod.default_retail_sourcing &&
                                      Object.keys(
                                        product.vfprod.default_retail_sourcing
                                      ).length > 0 ? (
                                        <span
                                          style={{
                                            fontSize: "10px",
                                            marginRight: "8px",
                                            textDecoration: "line-through",
                                          }}
                                        >
                                          {getCurrencySymbol(
                                            this.props.currencies
                                              .store_currency === "INR"
                                              ? "INR"
                                              : "USD"
                                          )}{" "}
                                          {
                                            product.vfprod
                                              .default_retail_sourcing.max
                                          }
                                        </span>
                                      ) : null}{" "}
                                    </span>

                                    <span
                                      style={{
                                        color: "#1C6513",
                                      }}
                                    >
                                      {" "}
                                      {getCurrencySymbol(
                                        this.props.currencies.store_currency ===
                                          "INR"
                                          ? "INR"
                                          : "USD"
                                      )}{" "}
                                      {formatNumber(vfmrp2.avg)}
                                    </span>
                                  </span>
                                )
                              ) : null
                            ) : currencies.store_currency === "USD" ||
                              currencies.store_currency === null ? (
                              vfmrp.min !== vfmrp.max ? (
                                <span
                                  style={{
                                    color: "#1C6513",
                                  }}
                                >
                                  {" "}
                                  {getCurrencySymbol(
                                    this.props.currencies.store_currency ===
                                      "INR"
                                      ? "INR"
                                      : "USD"
                                  )}{" "}
                                  {formatNumber(vfmrp.avg)}
                                </span>
                              ) : (
                                <span
                                  style={{
                                    color: "#1C6513",
                                  }}
                                >
                                  {" "}
                                  {getCurrencySymbol(
                                    this.props.currencies.store_currency ===
                                      "INR"
                                      ? "INR"
                                      : "USD"
                                  )}{" "}
                                  {formatNumber(vfmrp.avg)}
                                </span>
                              )
                            ) : null}

                            <br />
                          </React.Fragment>
                        </span>
                      </>
                    )}
                  </span>
                  <span style={{ float: "right" }}>
                    {/* {product.vfprod &&
                    product.vfprod.default_retail_sourcing &&
                    Object.keys(product.vfprod.default_retail_sourcing).length >
                      0 ? (
                      <span
                        style={{ fontSize: "8px", textDecoration: "underline" }}
                      >
                        Original Cost:{" "}
                        {getCurrencySymbol(
                          this.props.geo === "in" ? "INR" : "USD"
                        )}{" "}
                        {product.vfprod.default_retail_sourcing.min !==
                        product.vfprod.default_retail_sourcing.max
                          ? `${product.vfprod.default_retail_sourcing.min} - ${
                              product.vfprod.default_retail_sourcing.max
                            }`
                          : `${
                              product.vfprod.default_retail_sourcing.min
                            }`}{" "}
                        / unit
                      </span>
                    ) : null} */}
                    {/* {product.vfprod &&
                    product.vfprod.moq_sourcing &&
                    Object.keys(product.vfprod.moq_sourcing).length > 0 ? (
                      <>
                        <span style={{ fontSize: "8px", margin: "0px 10px" }}>
                          |
                        </span>
                        <span
                          style={{
                            fontSize: "8px",
                            textDecoration: "underline",
                          }}
                        >
                          MOQ:{" "}
                          {
                            Object.keys(product.vfprod.moq_sourcing)[0].split(
                              "-"
                            )[0]
                          }{" "}
                          unit(s)
                        </span>
                      </>
                    ) : null} */}
                  </span>
                </p>
              </Col>
            </Row>

            {hasPermission(store, "pricing_request_add") &&
            product.vfprod &&
            product.vfprod.prod_status === "1" ? (
              <Row style={{ width: "100%", textAlign: "right" }}>
                <div
                  href="#!"
                  style={{
                    fontSize: "12px",
                    marginRight: "19px",
                    marginTop: "16px",
                  }}
                >
                  {this.state.response ? (
                    this.state.status === 0 ? (
                      <span
                        className="f12"
                        style={{
                          marginBottom: 0,
                          background: "#FFFCE3",
                          padding: "4px 8px",
                          color: "#898989",
                          display: "inline-flex",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                        >
                          <g
                            id="Group_3136"
                            data-name="Group 3136"
                            transform="translate(-12648 1256)"
                          >
                            <path
                              id="Polygon_47"
                              data-name="Polygon 47"
                              d="M7.106,1.789a1,1,0,0,1,1.789,0l6.382,12.764A1,1,0,0,1,14.382,16H1.618a1,1,0,0,1-.894-1.447Z"
                              transform="translate(12648 -1256)"
                              fill="#ffea46"
                            />
                            <g
                              id="Group_3135"
                              data-name="Group 3135"
                              transform="translate(12629.9 -1268)"
                            >
                              <path
                                id="Shape"
                                d="M26.709,22.316l-.077.786a.255.255,0,0,1-.255.23h-.558a.255.255,0,0,1-.255-.23L25.1,18.278a.252.252,0,0,1,.066-.195.257.257,0,0,1,.19-.083h1.486a.257.257,0,0,1,.19.083.252.252,0,0,1,.066.2l-.243,2.514Z"
                                transform="translate(0 0)"
                                fill="#212121"
                              />
                              <ellipse
                                id="Oval"
                                cx="0.759"
                                cy="0.801"
                                rx="0.759"
                                ry="0.801"
                                transform="translate(25.34 24.399)"
                                fill="#212121"
                              />
                            </g>
                          </g>
                        </svg>{" "}
                        <span
                          className="underline"
                          style={{
                            letterSpacing: "0.032em",
                            fontSize: "12px",
                            fontWeight: "400",
                            marginLeft: "8px",
                          }}
                        >
                          {this.state.response}
                        </span>
                      </span>
                    ) : (
                      <span
                        className="f12"
                        style={{
                          marginBottom: 0,
                          background: "#F5FFF4",
                          padding: "4px 8px",
                          color: "#898989",
                          display: "inline-flex",
                        }}
                      >
                        <svg
                          id="exclamation-circle"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                        >
                          <path
                            id="Shape"
                            d="M12,24A12,12,0,0,1,3.515,3.515,12,12,0,0,1,20.486,20.486,11.922,11.922,0,0,1,12,24Zm0-7.5a1.055,1.055,0,1,0,1.055,1.055A1.056,1.056,0,0,0,12,16.5ZM12,4.477a.938.938,0,0,0-.937.938v8.648a.937.937,0,1,0,1.875,0V5.414A.939.939,0,0,0,12,4.477Z"
                            fill="#2bc822"
                          />
                        </svg>{" "}
                        <span
                          className="underline"
                          style={{
                            letterSpacing: "0.032em",
                            fontSize: "12px",
                            fontWeight: "400",
                            marginLeft: "8px",
                          }}
                        >
                          {this.state.response}
                        </span>
                      </span>
                    )
                  ) : (
                    <div
                      style={{ display: "flex", marginTop: "16px" }}
                      className="f12"
                    >
                      <p
                        style={{
                          flex: 1,
                          marginRight: "0px",
                          marginBottom: "0px",
                        }}
                      >
                        {product.vfprod.prod_status === "1" ? (
                          product.vfprod.lastupdated === null ? (
                            <span
                              style={{ fontSize: "12px", color: "#898989" }}
                            >
                              Pricing last updated on: Not Available
                            </span>
                          ) : (
                            <span
                              style={{ fontSize: "12px", color: "#898989" }}
                            >
                              Pricing last updated on:{" "}
                              {moment(product.vfprod.lastupdated).format(
                                "MMM DD, YYYY"
                              )}{" "}
                              (
                              {moment().diff(
                                moment(product.vfprod.lastupdated),
                                "days"
                              )}{" "}
                              Days ago)
                            </span>
                          )
                        ) : null}
                      </p>
                    </div>
                  )}
                </div>
              </Row>
            ) : (
              <span
                style={{
                  fontSize: "12px",
                  color: "#898989",
                  float: "right",
                  marginRight: "16px",
                  marginTop: "16px",
                }}
              >
                Pricing last updated on: Not Available
              </span>
            )}
          </Col>
        </Row>

        {messageStatus === "unlinked" && (
          <div className="notlinked">
            <Row
              style={{
                width: "100%",
                background: "#FFF7F5",
                border: "1px solid #FD4726",
                padding: "10px",
                borderRadius: "3px",
                marginTop: "16px",
              }}
            >
              <Col md={24}>
                <p
                  style={{
                    fontSize: "12px",
                    color: "#000000D9",
                    textAlign: "center",
                  }}
                >
                  <span
                    style={{
                      width: "24px",
                      height: "24px",
                      fontSize: "24px",
                      lineHeight: "24px",
                      display: "inline-block",
                      color: "#FD4726",
                      textAlign: "right",
                      marginRight: "16px",
                      verticalAlign: "middle",
                    }}
                  >
                    <Icon type="exclamation-circle" theme="filled" />
                  </span>
                  {/* You must link this product with either a product from
                  AliExpress or Import List to manage price & orders. */}
                  You must link this product from Import List to manage price &
                  orders
                </p>
                <div className="mapping-product">
                  {hasPermission(store, "link_products") && (
                    <button
                      onClick={() => this.props.toggleLinkImportListmodal()}
                      className="link-product"
                    >
                      Link Product
                    </button>
                  )}

                  {hasPermission(store, "link_products") && (
                    <button
                      className="bundle-product"
                      onClick={() => this.props.toggleBundelModal()}
                    >
                      {" "}
                      Create Bundle
                    </button>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        )}

        {/* messages start */}

        {messageStatus === "default" && (
          <Row
            style={{
              width: "100%",
              background: "#F5FFF4",
              border: "2px solid #2BC822",
              padding: "10px",
              borderRadius: "3px",
              marginTop: "16px",
              textAlign: "center",
            }}
          >
            <Col md={24}>
              <p
                style={{
                  fontSize: "12px",
                  color: "#000000D9",
                }}
              >
                <span
                  style={{
                    width: "24px",
                    height: "24px",
                    fontSize: "24px",
                    lineHeight: "24px",
                    display: "inline-block",
                    color: "#2BC822",
                    verticalAlign: "middle",
                    marginRight: "8px",
                  }}
                >
                  <Icon type="check-circle" theme="filled" />
                </span>
                <span>
                  <span>
                    This product has been successfully linked to {""}
                    {this.props.vfprod && this.props.vfprod.product_name ? (
                      <span>
                        {this.props.vfprod &&
                        this.props.vfprod.product_name.length > 50 ? (
                          <Tooltip title={this.props.vfprod.product_name}>
                            {" "}
                            <a
                              href={`./${product.vfprod.vfprodid}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {`${this.props.vfprod.product_name.substring(
                                0,
                                40
                              )}...`}
                            </a>
                          </Tooltip>
                        ) : (
                          <a
                            href={`./${product.vfprod.vfprodid}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {this.props.vfprod.product_name}
                          </a>
                        )}
                      </span>
                    ) : (
                      <span className="product-name-id">
                        {this.props.vfprod && this.props.vfprod.vfprodid ? (
                          <a
                            href={`./${product.vfprod.vfprodid}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {this.props.vfprod.vfprodid}
                          </a>
                        ) : null}{" "}
                      </span>
                    )}{" "}
                    . You can start selling it. Or, view product in VF Catalog
                    for more details.
                  </span>
                </span>
              </p>
            </Col>
          </Row>
        )}

        {messageStatus === "rejected" && (
          <Row
            style={{
              width: "100%",
              background: "#FFF7F5",
              border: "1px solid #FD4726",
              padding: "10px",
              borderRadius: "3px",
              marginTop: "16px",
              textAlign: "center",
            }}
          >
            <Col md={24}>
              <p
                style={{
                  fontSize: "12px",
                  color: "#000000D9",
                }}
              >
                <span
                  style={{
                    width: "24px",
                    height: "24px",
                    fontSize: "24px",
                    lineHeight: "24px",
                    display: "inline-block",
                    color: "#FD4726",
                    verticalAlign: "middle",
                    marginRight: "8px",
                  }}
                >
                  <Icon type="exclamation-circle" theme="filled" />
                </span>
                Uh-oh! Looks like we are unable to catalog this product at this
                point in time. This is mostly because our catalog team thinks
                that we cannot fulfill orders on this product.
              </p>
            </Col>
          </Row>
        )}

        {messageStatus === "doescalate" && (
          <Row
            style={{
              width: "100%",
              background: this.state.escalated ? "#F5FAFF" : "#F5FFF4",
              border: this.state.escalated
                ? "2px solid #0068FF"
                : " 2px solid #2BC822",
              padding: "10px",
              borderRadius: "3px",
              marginTop: "16px",
            }}
          >
            <Col md={24} style={{ textAlign: "center" }}>
              <p
                style={{
                  fontSize: "12px",
                  color: "#000000D9",
                }}
              >
                <>
                  <p>
                    <span
                      style={{
                        width: "24px",
                        height: "24px",
                        fontSize: "24px",
                        lineHeight: "24px",
                        display: "inline-block",
                        color: this.state.escalated ? "#0068FF" : "#2BC822",
                        verticalAlign: "middle",
                        marginRight: "8px",
                      }}
                    >
                      <Icon type="exclamation-circle" theme="filled" />
                    </span>
                    <span style={{ fontWeight: "600" }}> Next Steps? </span> Get
                    this product Cataloged by vFulfill team to see more details
                    around this product, including its product quotation. This
                    is a mandatory step before you start selling this product
                    with vFulfill.
                  </p>
                  <p
                    style={{
                      backgroundColor: "#0068FF",
                      color: "#fff",
                      textAlign: "center",
                      display: "inline-block",
                      padding: "8px 12px",
                      borderRadius: "2px",
                      marginTop: "12px",
                      cursor: "pointer",
                    }}
                    onClick={() => this.escalated()}
                  >
                    Request to Catalog & Quote
                  </p>
                </>
              </p>
            </Col>
          </Row>
        )}

        {messageStatus === "escalated" && (
          <>
            <Row
              style={{
                width: "100%",
                background: this.state.escalated ? "#F5FAFF" : "#F5FFF4",
                border: this.state.escalated
                  ? "2px solid #0068FF"
                  : " 2px solid #2BC822",
                padding: "10px",
                borderRadius: "3px",
                marginTop: "16px",
              }}
            >
              <Col md={1}>
                <span
                  style={{
                    width: "24px",
                    height: "24px",
                    fontSize: "24px",
                    lineHeight: "24px",
                    display: "inline-block",
                    color: this.state.escalated ? "#0068FF" : "#2BC822",
                  }}
                >
                  <Icon type="exclamation-circle" theme="filled" />
                </span>
              </Col>
              <Col md={23} style={{ textAlign: "center" }}>
                <p
                  style={{
                    fontSize: "12px",
                    color: "#000000D9",
                  }}
                >
                  <p>
                    Your request to get this product cataloged has been
                    submitted. It takes us between 2-3 working days on average
                    to completely catalog a product. You will be sent a
                    notification on your vFulfill dashboard once it has been
                    done!
                  </p>
                </p>
              </Col>
            </Row>
          </>
        )}
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
  };
};
export default connect(mapStateToProps)(MyProduct);
