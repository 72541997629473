import React from "react";
import { Row } from "antd";

// import { ReactComponent as OrderIcon } from "../../../../assets/images/icons/open-orders-snapshot.svg";
// import { ReactComponent as ProcessingIcon } from "../../../../assets/images/icons/processing-snapshot.svg";
// import { ReactComponent as NotificationIcon } from "../../../../assets/images/icons/notifications-snapshot.svg";

import NextSteps from "../NextSteps";
import "./index.scss";

const OverallStats = (props) => {
  return (
    <React.Fragment>
      <NextSteps
        stateCounter={props.stateCounter}
        enableModal={() => this.props.enableModal()}
        {...props}
      />
    </React.Fragment>
  );
};

export default OverallStats;
