import React, { Component } from "react";
import "./index.scss";
import { DatePicker, Button, message, Select, Divider } from "antd";

import moment from "moment";
import Globalsearch from "../../../Common/Globalsearch";
import axios from "axios";
const { RangePicker } = DatePicker;
const Option = Select.Option;

class InventoryNav extends Component {
  state = {
    inventoryBtn: true,
    badInventoryBtn: false,
    logsBtn: false,
    rtoBtn: false,
    loading: false,
    logsLoading: false,
  };

  // download rto logs

  downloadRTOlogs() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/sourcing/rto_inventory_logs",
          method: "POST",
          data: {
            storeid: this.props.storeId,
            filters: this.props.rtoFilters,
            downloadcsv: 1,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              message.success(res.data.msg);
              this.setState({ loading: false });
            } else {
              message.error(res.data.msg);
              this.setState({ loading: false });
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
              error: true,
              errorMsg: err.message,
            });
          });
      }
    );
  }

  // download inventory logs
  downloadLogs() {
    this.setState({ logsLoading: true }, () => {
      axios({
        url: process.env.REACT_APP_API_URL + "/sourcing/inventory_logs",
        method: "POST",
        data: {
          storeid: this.props.storeId,
          filters: this.props.logsfilter,
          downloadcsv: 1,
        },

        withCredentials: true,
      })
        .then((res) => {
          if (res.data.success === 1) {
            message.success(res.data.msg);
            this.setState({ logsLoading: false });
          } else {
            message.error(res.data.msg);
            this.setState({ logsLoading: false });
          }
        })
        .catch((err) => {
          this.setState({
            logsLoading: false,
            error: true,
            errorMsg: err.message,
          });
        });
    });
  }

  // logs tab movement filter

  selectAll() {
    let logFilter = [
      "cancel_order",
      "rto",
      "orders",
      "client_sourcing_request",
      "rtv",
      "escalation_request",
      "buyback",
      "manual_add",
      "manual_sub",
    ];

    this.props.logsFunction({ movement_type: logFilter });
  }

  render() {
    let movementFilters = [<Option key="">{"d"}</Option>];

    return (
      <div className="inventory_order_data fullorder_data">
        <div className="inv-nav">
          <div className="ordfill">
            <div className="ordfillwrap" style={{ height: "32px" }}>
              <div className="left">
                <div
                  style={{
                    color: "#000",
                    fontSize: "12px",
                    letterSpacing: "0.032em",
                    lineHeight: "24px",
                  }}
                >
                  Inventory <span>&#62;</span>{" "}
                  <span style={{ textDecoration: "underline" }}>
                    Live Inventory
                  </span>
                </div>
              </div>

              <div className="right">
                <div className="store-filters" />
                {this.props.activeTab === "logs" ||
                this.props.activeTab === "RTO" ? (
                  <p className="show_data_from" style={{ marginLeft: "8px" }}>
                    Show Data From:{" "}
                  </p>
                ) : null}

                {this.props.activeTab === "logs" ? (
                  <RangePicker
                    className="inventory-datepicker"
                    disabledDate={(current) => {
                      return current > moment();
                    }}
                    style={{ marginLeft: "10px" }}
                    value={[
                      this.props.logsfilter.startDate === "" ||
                      this.props.logsfilter.startDate === null
                        ? undefined
                        : moment(this.props.logsfilter.startDate, "YYYY-MM-DD"),
                      this.props.logsfilter.endDate === "" ||
                      this.props.logsfilter.startDate === null
                        ? undefined
                        : moment(this.props.logsfilter.endDate, "YYYY-MM-DD"),
                    ]}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Week": [
                        moment().startOf("week"),
                        moment().endOf("week"),
                      ],
                      "Last 15 Days": [moment().subtract("days", 15), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                      "Last Month": [
                        moment()
                          .subtract(1, "month")
                          .startOf("month"),
                        moment()
                          .subtract(1, "month")
                          .endOf("month"),
                      ],
                      "Last Year": [
                        moment()
                          .subtract(1, "year")
                          .startOf("year"),
                        moment()
                          .subtract(1, "year")
                          .endOf("year"),
                      ],
                    }}
                    onChange={(_, dateString) =>
                      this.props.logsFunction({
                        startDate: dateString[0],
                        endDate: dateString[1],
                      })
                    }
                  />
                ) : null}

                {this.props.activeTab === "RTO" ? (
                  <RangePicker
                    className="inventory-datepicker"
                    disabledDate={(current) => {
                      return current > moment();
                    }}
                    style={{ marginLeft: "10px" }}
                    value={[
                      this.props.rtoFilters.startDate === "" ||
                      this.props.rtoFilters.startDate === null
                        ? undefined
                        : moment(this.props.rtoFilters.startDate, "YYYY-MM-DD"),
                      this.props.rtoFilters.endDate === "" ||
                      this.props.rtoFilters.startDate === null
                        ? undefined
                        : moment(this.props.rtoFilters.endDate, "YYYY-MM-DD"),
                    ]}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Week": [
                        moment().startOf("week"),
                        moment().endOf("week"),
                      ],
                      "Last 15 Days": [moment().subtract("days", 15), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                      "Last Month": [
                        moment()
                          .subtract(1, "month")
                          .startOf("month"),
                        moment()
                          .subtract(1, "month")
                          .endOf("month"),
                      ],
                      "Last Year": [
                        moment()
                          .subtract(1, "year")
                          .startOf("year"),
                        moment()
                          .subtract(1, "year")
                          .endOf("year"),
                      ],
                    }}
                    onChange={(_, dateString) =>
                      this.props.rtoFilterFunction({
                        startDate: dateString[0],
                        endDate: dateString[1],
                      })
                    }
                  />
                ) : null}
              </div>
            </div>
            <div className="secondwrap">
              <div className="filtertab">
                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-inventory-underline-animation "
                      : this.state.inventoryBtn
                      ? "tab  hover-inventory-underline-animation-click"
                      : "tab hover-inventory-underline-animation"
                  }
                >
                  <button
                    className={this.state.inventoryBtn ? "active" : ""}
                    style={{ fontWeight: 500 }}
                    onClick={() => {
                      this.setState(
                        {
                          inventoryBtn: true,
                          badInventoryBtn: false,

                          logsBtn: false,
                          rtoBtn: false,
                        },
                        this.props.tabHandler("inventory")
                      );
                    }}
                  >
                    Live Inventory
                  </button>

                  <div
                    className={` ${
                      this.state.inventoryBtn ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.count
                      ? this.props.count > 1000
                        ? (Number(this.props.count) / 1000).toFixed(1) + "k"
                        : this.props.count < 1000
                        ? this.props.count
                        : null
                      : this.props.count === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>

                {/* bad inventory */}

                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-inventory-underline-animation "
                      : this.state.badInventoryBtn
                      ? "tab  hover-inventory-underline-animation-click"
                      : "tab hover-inventory-underline-animation"
                  }
                >
                  <button
                    className={this.state.badInventoryBtn ? "active" : ""}
                    style={{ fontWeight: 500 }}
                    onClick={() => {
                      this.setState(
                        {
                          badInventoryBtn: true,
                          inventoryBtn: false,
                          logsBtn: false,
                          rtoBtn: false,
                        },
                        this.props.tabHandler("badInventory")
                      );
                    }}
                  >
                    Bad Inventory
                  </button>

                  <div
                    className={` ${
                      this.state.badInventoryBtn ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.badInventoryCount
                      ? this.props.badInventoryCount > 1000
                        ? (Number(this.props.badInventoryCount) / 1000).toFixed(
                            1
                          ) + "k"
                        : this.props.badInventoryCount < 1000
                        ? this.props.badInventoryCount
                        : null
                      : this.props.badInventoryCount === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>

                {/* Delivered */}

                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-inventory-underline-animation "
                      : this.state.logsBtn
                      ? "tab  hover-inventory-underline-animation-click"
                      : "tab hover-inventory-underline-animation"
                  }
                >
                  <button
                    className={this.state.logsBtn ? "active" : ""}
                    style={{ fontWeight: 500 }}
                    onClick={() => {
                      this.setState(
                        {
                          inventoryBtn: false,
                          badInventoryBtn: false,

                          logsBtn: true,
                          rtoBtn: false,
                        },
                        this.props.tabHandler("logs")
                      );
                    }}
                  >
                    Logs
                  </button>
                </div>
                {/* bad inventoy */}
                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-inventory-underline-animation "
                      : this.state.rtoBtn
                      ? "tab  hover-inventory-underline-animation-click"
                      : "tab hover-inventory-underline-animation"
                  }
                >
                  <button
                    className={this.state.rtoBtn ? "active" : ""}
                    onClick={() => {
                      this.setState(
                        {
                          badInventoryBtn: false,

                          inventoryBtn: false,
                          logsBtn: false,
                          rtoBtn: true,
                        },
                        this.props.tabHandler("RTO")
                      );
                    }}
                  >
                    RTO In-Transit
                  </button>
                </div>
              </div>

              {this.props.logs.length > 0 && this.props.activeTab === "logs" ? (
                <Button
                  className="download_csv clickable logs-btn"
                  loading={this.state.logsLoading}
                  onClick={() => this.downloadLogs()}
                >
                  Download Logs
                </Button>
              ) : null}

              {this.props.badInventory.length > 0 &&
              this.state.badInventoryBtn ? (
                <Button
                  loading={this.props.badInventoryCSVLoading}
                  onClick={() => this.props.fetchBadInventoryCSV()}
                  className="download_csv clickable logs-btn"
                >
                  Download CSV
                </Button>
              ) : null}
               {this.props.LiveInventoryData.length > 0 &&
              this.state.inventoryBtn ? (
                <Button
                  loading={this.props.loadingInventoryCSV}
                  onClick={() => this.props.fetchOrdersDownloadCSV()}
                  className="download_csv clickable logs-btn"
                >
                  Download CSV
                </Button>
              ) : null}
              {this.props.rto.length > 0 && this.props.activeTab === "RTO" ? (
                <Button
                  loading={this.state.loading}
                  onClick={() => this.downloadRTOlogs()}
                  className="download_csv clickable logs-btn"
                >
                  Download CSV
                </Button>
              ) : null}
            </div>
          </div>
        </div>

        <div className="bottom_data">
          <div className="Globalsearch">
            {this.props.activeTab === "badInventory" ? (
              <Globalsearch
                data={this.props.badInvFilters}
                placeholder={"Search by Product Name or ID"}
                change={(value) =>
                  this.props.badChangeHandler({
                    search: value.target.value,
                  })
                }
                searchFunc={(value) =>
                  this.props.badChangeFilter({
                    search: value,
                  })
                }
              />
            ) : (
              <Globalsearch
                data={
                  this.props.activeTab === "inventory"
                    ? this.props.filters
                    : this.props.activeTab === "logs"
                    ? this.props.logsfilter
                    : this.props.rtoFilters
                }
                placeholder={
                  this.props.activeTab === "RTO"
                    ? "Search by Product ID or AWB"
                    : "Search by Product Name or ID"
                }
                change={
                  this.props.activeTab === "inventory"
                    ? (value) =>
                        this.props.changeHandler({ search: value.target.value })
                    : this.props.activeTab === "logs"
                    ? (value) =>
                        this.props.changeHandler2({
                          search: value.target.value,
                        })
                    : (value) =>
                        this.props.rtoChangeHandler({
                          search: value.target.value,
                        })
                }
                searchFunc={
                  this.props.activeTab === "inventory"
                    ? (value) => this.props.changeFilter({ search: value })
                    : this.props.activeTab === "logs"
                    ? (value) => this.props.logsFunction({ search: value })
                    : (value) => this.props.rtoFilterFunction({ search: value })
                }
              />
            )}
          </div>
          {this.props.activeTab === "logs" ? (
            <>
              <div className="movement-filter-wraper">
                {/* <div className="custom-text">Payment Mode: </div> */}
                <Select
                  mode="multiple"
                  value={this.props.logsfilter.movement_type}
                  style={{ width: "288px", height: "40px" }}
                  placeholder="Please select Movement type"
                  dropdownClassName="movement-filter-overlay"
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        className="overlay-footer"
                        style={{ cursor: "pointer" }}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {this.props.logsfilter.movement_type.length > 0 ? (
                          <Button
                            type=""
                            onClick={() =>
                              this.props.logsFunction({ movement_type: [] })
                            }
                          >
                            Clear All
                          </Button>
                        ) : (
                          <Button
                            onClick={() => this.selectAll()}
                            type="primary"
                          >
                            Select All
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                  maxTagCount={3}
                  onChange={(value) =>
                    this.props.logsFunction({ movement_type: value })
                  }
                >
                  <Option value="cancel_order">Cancel Order</Option>
                  <Option value="rto">RTO</Option>
                  <Option value="orders">Orders</Option>
                  <Option value="client_sourcing_request">
                    Client Sourcing Request
                  </Option>
                  <Option value="rtv">Return to Vendor</Option>
                  <Option value="escalation_request">Escalations</Option>
                  <Option value="buyback">Buyback</Option>
                  <Option value="manual_add">Manual Add</Option>
                  <Option value="manual_sub">Manual Sub</Option>
                </Select>
              </div>

              <div className="type-filter-wraper">
                <div className="custom-text">Type: </div>

                <Select
                  style={{ width: "288px", height: "40px" }}
                  placeholder="Please select Movement type"
                  value={this.props.logsfilter.type}
                  onChange={(value) => this.props.logsFunction({ type: value })}
                  dropdownClassName="type-filter-overlay"
                  // onChange={handleChange}
                >
                  <Option value="good">Good</Option>
                  <Option value="bad">Bad</Option>
                </Select>
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  }
}

export default InventoryNav;
