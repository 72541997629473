import React, { Component } from "react";
import {
  Layout,
  Menu,
  Icon,
  Dropdown,
  Avatar,
  Tag,
  Spin,
  Alert,
  Tooltip,
} from "antd";
import { connect } from "react-redux";
import { signOut } from "../../../store/actions/authActions";
import { Link } from "react-router-dom";
import { ReactComponent as GeoIn } from "../../../assets/images/icons/india.svg";
import { ReactComponent as GeoGlobal } from "../../../assets/images/icons/global.svg";

import { ReactComponent as StoreConnected } from "../../../assets/images/icons/connectedStore.svg";
import { ReactComponent as StoreNotConnected } from "../../../assets/images/icons/disconnectedstore.svg";
import { ReactComponent as Download } from "../../../assets/images/icons/download_sidebar.svg";
import { ReactComponent as shopifyLogo } from "../../../assets/images/icons/shopify.svg";
import { ReactComponent as shopifyLogoColored } from "../../../assets/images/icons/shopify_colored.svg";
import { ReactComponent as rateCardicon } from "../../../assets/images/icons/rate-card-icon.svg";
import Woocommerce from "../../../assets/images/icons/logoWoocommerceSmall.png";
import logoWoocommerce from "../../../assets/images/icons/logoWoocommerce.png";
import Shopify from "../../../assets/images/icons/logoShopify.png";
import moment from "moment";
import { ReactComponent as help } from "../../../assets/images/icons/help_mark.svg";
import { ReactComponent as help_colored } from "../../../assets/images/icons/help_copy.svg";
import "./Navbar.scss";
import Notifications from "../Notifications";
import { getCurrencySymbol, formatBalance } from "../../../Helpers/Pricing";
import { hasPermission } from "../../../Helpers/Permissions";
import { SHOPIFY_NAME } from "../../../constants";
const { Header } = Layout;

class Navbar extends Component {
  state = {
    helpDropdownVisible: false,
    navStatus: "",
    userDropdownVisible: false,
    search: "",
    arrow: false,
    appTop: this.props.top,
  };

  dropdownToggle(key, visible) {
    this.setState({
      [key]: visible,
    });
  }

  dropdownToggle2(key, visible) {
    this.setState({
      [key]: visible,
    });
  }

  storesArrow() {
    this.setState({ arrow: false });
    this.props.toggleAddStoreModal();
  }

  arr = [];

  widthCounter() {
    Object.values(this.props.user.user_stores)
      .filter((s) => s.store_active === 1)
      .forEach((store) => {
        this.arr.push(store.store_name.length);
      });
  }

  prp = 100;
  bool = "";

  somefunc(e) {
    let otherel = document.querySelector(".fullorder_data");

    if (e.target.scrollTop > this.prp) {
      this.bool = "up";

      if (otherel && otherel.firstElementChild !== null) {
        otherel.firstElementChild.classList.add("uppp");
        otherel.firstElementChild.classList.remove("downn");

        this.bool = "up2";
      }
    } else {
      this.bool = "down";

      if (otherel && otherel.firstElementChild !== null) {
        otherel.firstElementChild.classList.add("downn");
        otherel.firstElementChild.classList.remove("uppp");
        this.bool = "down2";
      }
    }

    this.prp = e.target.scrollTop;

    this.setState({ navStatus: this.bool });
  }

  componentDidMount() {
    let el = document.querySelector(".lay");

    el.addEventListener("scroll", (e) => this.somefunc(e));
  }

  daysDiff = (current_plan, showFormatDate = false, overdue = false) => {
    if (current_plan) {
      if (overdue) {
        let a = moment();
        let b = moment(current_plan.due_from_date).add(7, "days");
        let diff = b.diff(a, "days"); //

        if (diff == 0) {
          return "Today";
        } else {
          return diff + " Day(s)";
        }
      }

      if (showFormatDate) {
        if (current_plan.renewal_date) {
          return moment(current_plan.renewal_date).format("DD MMM YY");
        }
      }

      if (current_plan.renewal_date) {
        let a = moment();
        let b = moment(current_plan.renewal_date);
        let diff = b.diff(a, "days"); //

        if (diff == 0) {
          return "Today";
        } else {
          return diff + " Day(s)";
        }
      }
    }
  };

  planType = (current_plan) => {
    if (current_plan) {
      if (
        current_plan.plan_name &&
        String(current_plan.plan_name)
          .toLowerCase()
          .includes("shaker")
      ) {
        return "Shaker";
      }

      if (
        current_plan.plan_name &&
        String(current_plan.plan_name)
          .toLowerCase()
          .includes("mover")
      ) {
        return "Mover";
      }

      if (
        current_plan.plan_name &&
        String(current_plan.plan_name)
          .toLowerCase()
          .includes("earth")
      ) {
        return "Earthquaker";
      }

      if (
        current_plan.plan_name &&
        String(current_plan.plan_name)
          .toLowerCase()
          .includes("free")
      ) {
        return "Free";
      }

      return current_plan.plan_name;
    }
  };

  planNameHandler = (current_plan) => {
    if (current_plan) {
      // free
      if (current_plan.plan_type && String(current_plan.plan_type) === "free") {
        return (
          <Link to="/profile?plan">
            <div className="plan-info">
              <p className="plan-name"> Free Plan</p>
            </div>
          </Link>
        );
      }

      // overdue
      if (
        current_plan.plan_status &&
        String(current_plan.plan_status) === "past_due"
      ) {
        return (
          <Link to="/profile?plan">
            <div className="plan-info">
              <p className="plan-name ng-margin red">
                {this.planType(current_plan) + ` - Overdue`}
              </p>

              <p>
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="M360-840v-80h240v80H360Zm80 440h80v-240h-80v240Zm40 320q-74 0-139.5-28.5T226-186q-49-49-77.5-114.5T120-440q0-74 28.5-139.5T226-694q49-49 114.5-77.5T480-800q62 0 119 20t107 58l56-56 56 56-56 56q38 50 58 107t20 119q0 74-28.5 139.5T734-186q-49 49-114.5 77.5T480-80Zm0-80q116 0 198-82t82-198q0-116-82-198t-198-82q-116 0-198 82t-82 198q0 116 82 198t198 82Zm0-280Z" />
                </svg>{" "}
                Suspends in {this.daysDiff(current_plan, false, true)}{" "}
              </p>
            </div>
          </Link>
        );
      }

      if (
        current_plan.plan_status &&
        String(current_plan.plan_status) === "trialing"
      ) {
        return (
          <Link to="/profile?plan">
            <div className="plan-info">
              <p className="plan-name ng-margin blue">
                {this.planType(current_plan) + ` - Trial`}
              </p>

              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="M360-840v-80h240v80H360Zm80 440h80v-240h-80v240Zm40 320q-74 0-139.5-28.5T226-186q-49-49-77.5-114.5T120-440q0-74 28.5-139.5T226-694q49-49 114.5-77.5T480-800q62 0 119 20t107 58l56-56 56 56-56 56q38 50 58 107t20 119q0 74-28.5 139.5T734-186q-49 49-114.5 77.5T480-80Zm0-80q116 0 198-82t82-198q0-116-82-198t-198-82q-116 0-198 82t-82 198q0 116 82 198t198 82Zm0-280Z" />
                </svg>{" "}
                End's in {this.daysDiff(current_plan)}{" "}
              </p>
            </div>
          </Link>
        );
      }
    }
  };

  dropDownPlanName = (current_plan) => {
    if (current_plan) {
      // agency Plan

      if (
        current_plan.plan_name &&
        String(current_plan.plan_name)
          .toLowerCase()
          .includes("agency")
      ) {
        return (
          <Tag
            color="#FF8200"
            style={{
              borderRadius: "2px",
              cursor: "pointer",
            }}
          >
            <p className="plan-name-badge"> Agency</p>
          </Tag>
        );
      }

      // active
      if (
        current_plan.plan_status &&
        String(current_plan.plan_status) === "active"
      ) {
        return (
          <Tag
            color="#FF8200"
            style={{
              borderRadius: "2px",
              cursor: "pointer",
            }}
          >
            <p className="plan-name-badge">{this.planType(current_plan)}</p>{" "}
          </Tag>
        );
      }

      return (
        <Tag
          color="#FF8200"
          style={{
            borderRadius: "2px",
            cursor: "pointer",
          }}
        >
          <p className="plan-name-badge"> {this.planType(current_plan)} </p>
        </Tag>
      );
    }
  };

  render() {
    this.widthCounter();

    const currency = this.props.store.store_geo === "global" ? "USD" : "INR";

    const userDropdownMenu = (
      <Menu className="usernav">
        <Menu.Item
          onClick={() => this.dropdownToggle2("userDropdownVisible", false)}
          key="0"
        >
          <Link to="/profile">
            <Icon type="user" /> Profile
          </Link>
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          className="plan-link"
          onClick={() => this.dropdownToggle2("userDropdownVisible", false)}
          key="0"
        >
          <Link to="/profile?plan">
            <div className="icon-wraperr">
              {" "}
              <Icon type="alert" /> Plan{" "}
            </div>{" "}
            {this.dropDownPlanName(
              this.props.auth.plan && this.props.auth.plan.current_plan
            )}
          </Link>
        </Menu.Item>

        <Menu.Divider />

        {hasPermission(this.props.store, "access_wallet") && (
          <Menu.Item
            key="3"
            onClick={() => this.dropdownToggle2("userDropdownVisible", false)}
          >
            <Link to={`/${this.props.store.store_geo}/my-wallet`}>
              <Icon type="dollar" /> Manage Funds
            </Link>
          </Menu.Item>
        )}

        {hasPermission(this.props.store, "access_wallet") && <Menu.Divider />}

        {hasPermission(this.props.store, "manage_users_permissions") && (
          <Menu.Item
            key="4"
            onClick={() => this.dropdownToggle2("userDropdownVisible", false)}
          >
            <Link to={`/${this.props.store.id}/manage-users`}>
              <Icon type="user" /> Manage Users
            </Link>
          </Menu.Item>
        )}
        {hasPermission(this.props.store, "manage_users_permissions") && (
          <Menu.Divider />
        )}
        {hasPermission(this.props.store, "owner") && (
          <Menu.Item
            onClick={() => {
              this.setState({
                userDropdownVisible: !this.state.userDropdownVisible,
              });
            }}
            key="5"
          >
            <Link to={`/manage-stores`}>
              <Icon component={shopifyLogo} /> Manage Stores
            </Link>
          </Menu.Item>
        )}
        {hasPermission(this.props.store, "owner") && <Menu.Divider />}

        {/* <Menu.Item
          onClick={() => {
            this.setState({
              userDropdownVisible: !this.state.userDropdownVisible,
            });
          }}
          key="5"
        >
          <Link to={`/rate-cards`}>
            <Icon component={rateCardicon} /> Active Rate Cards
          </Link>
        </Menu.Item>
        <Menu.Divider /> */}

        <Menu.Item key="6">
          <a
            href="/logout"
            onClick={(e) => {
              e.preventDefault();
              this.props.signOut();
            }}
          >
            <Icon type="poweroff" /> Logout
          </a>
        </Menu.Item>
      </Menu>
    );

    const helpDropdownMenu = (
      <Menu className="helpnav">
        {/* <Menu.Item key="help_notifications">Notifications</Menu.Item> */}
        <Menu.Item
          onClick={() => {
            this.setState({
              helpDropdownVisible: !this.state.helpDropdownVisible,
            });
          }}
          key="help_center"
        >
          <a
            href="https://help.vfulfill.io/portal/en/kb/vfulfill"
            target="_blank"
            rel="noopener noreferrer"
          >
            Help Center
          </a>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            this.setState({
              helpDropdownVisible: !this.state.helpDropdownVisible,
            });
          }}
          key="help_submit_ticket"
        >
          <a
            href="https://help.vfulfill.io/portal/en/newticket?departmentId=244206000011742048&layoutId=244206000011744275"
            target="_blank"
            rel="noopener noreferrer"
          >
            Submit Ticket
          </a>
        </Menu.Item>
        {/* <Menu.Item key="3">
          <a
            href="/settings/serviceable_pincodes"
            download="serviceable_pincodes.csv"
          >
            Download Pin Code
          </a>

          <div />
        </Menu.Item> */}
      </Menu>
    );

    const storeListDropDown = (
      <div className="userStore">
        <Menu
          className="userStore-nested"
          style={{ maxHeight: 300, overflow: "auto" }}
        >
          {Object.values(this.props.user.user_stores)
            .filter((s) => s.store_active === 1)
            .map((store) => {
              return (
                <Menu.Item
                  className="storenav"
                  key={store.id}
                  onClick={() =>
                    window.location.assign(
                      `${process.env.REACT_APP_NEW_APP}/switch-store`
                    )
                  }
                >
                  <span className="userli">
                    <div className="left_Store">
                      {store.store_geo === "global" ? (
                        <Icon component={GeoGlobal} />
                      ) : (
                        <Icon component={GeoIn} />
                      )}{" "}
                      <p
                        style={{
                          minWidth: `${Math.max(...this.arr) * 7}px`,
                          marginLeft: "8px",
                        }}
                      >
                        {" "}
                        {store.store_name
                          ? store.store_name
                          : this.props.user.user_fname.split(" ")[0] +
                            "'s Store"}
                      </p>
                    </div>

                    <div className="right_store">
                      <Tag
                        color="#FF8200"
                        style={{
                          marginLeft: 8,
                          borderRadius: "2px",
                          cursor: "pointer",
                        }}
                      >
                        {store.store_role === "owner" ? "Owner" : "User"}
                      </Tag>
                      {store.store_connected === 1 ? (
                        <>
                          <Tooltip
                            title={"Store is connected"}
                            className="wocommerce_shopify"
                          >
                            <Icon
                              className="storeStatusIcon"
                              component={StoreConnected}
                            />
                          </Tooltip>
                          <Tooltip
                            title={
                              store.store_platform === "shopify"
                                ? "Shopify"
                                : "WooCommerce"
                            }
                          >
                            {store.store_platform === "shopify" ? (
                              <img
                                src={Shopify}
                                alt=""
                                className="shopify_img"
                              />
                            ) : (
                              <img
                                src={Woocommerce}
                                alt=""
                                className="woocommerce_img"
                              />
                            )}
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          <Tooltip
                            title={"Store is not connected"}
                            className="wocommerce_shopify"
                          >
                            <Icon
                              className="storeStatusIcon"
                              component={StoreNotConnected}
                            />
                          </Tooltip>
                          <Tooltip
                            title={
                              store.store_platform === "shopify"
                                ? "Shopify"
                                : "WooCommerce"
                            }
                          >
                            {store.store_platform === "shopify" ? (
                              <img
                                src={Shopify}
                                alt=""
                                className="shopify_img"
                              />
                            ) : (
                              <img
                                src={Woocommerce}
                                alt=""
                                className="woocommerce_img"
                              />
                            )}
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </span>
                </Menu.Item>
              );
            })}
        </Menu>
      </div>
    );

    const storeListDropDown2 = (
      <div className="userStore">
        <Menu
          className="userStore-nested"
          style={{ maxHeight: 300, overflow: "auto" }}
        >
          {Object.values(this.props.user.user_stores)
            .filter((s) => s.store_active === 1)
            .map((store) => {
              return (
                <Menu.Item
                  className="storenav"
                  key={store.id}
                  onClick={() =>
                    window.location.assign(
                      `${process.env.REACT_APP_NEW_APP}/switch-store`
                    )
                  }
                >
                  <span className="userli">
                    <div className="left_Store">
                      {store.store_geo === "global" ? (
                        <Icon component={GeoGlobal} />
                      ) : (
                        <Icon component={GeoIn} />
                      )}{" "}
                      <p
                        style={{
                          minWidth: `${Math.max(...this.arr) * 7}px`,
                          marginLeft: "8px",
                        }}
                      >
                        {" "}
                        {store.store_name
                          ? store.store_name
                          : this.props.user.user_fname.split(" ")[0] +
                            "'s Store"}
                      </p>
                    </div>

                    <div className="right_store">
                      <Tag
                        color="#FF8200"
                        style={{
                          marginLeft: 8,
                          borderRadius: "2px",
                          cursor: "pointer",
                        }}
                      >
                        {store.store_role === "owner" ? "Owner" : "User"}
                      </Tag>
                      {store.store_connected === 1 ? (
                        <>
                          <Tooltip
                            title={"Store is connected"}
                            className="wocommerce_shopify"
                          >
                            <Icon
                              className="storeStatusIcon"
                              component={StoreConnected}
                            />
                          </Tooltip>

                          <Tooltip
                            title={
                              store.store_platform === "shopify"
                                ? "Shopify"
                                : "WooCommerce"
                            }
                          >
                            {store.store_platform === "shopify" ? (
                              <img
                                src={Shopify}
                                alt=""
                                className="shopify_img"
                              />
                            ) : (
                              <img
                                src={Woocommerce}
                                alt=""
                                className="woocommerce_img"
                              />
                            )}
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          <Tooltip
                            title={"Store is not connected"}
                            className="wocommerce_shopify"
                          >
                            <Icon
                              className="storeStatusIcon"
                              component={StoreNotConnected}
                            />
                          </Tooltip>
                          <Tooltip
                            title={
                              store.store_platform === "shopify"
                                ? "Shopify"
                                : "WooCommerce"
                            }
                            className="wocommerce_shopify"
                          >
                            {store.store_platform === "shopify" ? (
                              <img
                                src={Shopify}
                                alt=""
                                className="shopify_img"
                              />
                            ) : (
                              <img
                                src={Woocommerce}
                                alt=""
                                className="woocommerce_img"
                              />
                            )}
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </span>
                </Menu.Item>
              );
            })}
          {hasPermission(this.props.store, "add_shopify_store_to_account") && (
            <Menu.Item
              className="userli add-shopify-store"
              key="add_store"
              onClick={() => this.storesArrow()}
            >
              {`Add New ${SHOPIFY_NAME}`}
            </Menu.Item>
          )}
        </Menu>
      </div>
    );

    let currentStoreName =
      this.props.user.user_fname.split(" ")[0] + "'s Store";
    if (this.props.store && this.props.store.store_name) {
      currentStoreName = this.props.store.store_name;
    }

    return (
      <>
        <Header
          className={`header navhead ${
            this.state.navStatus === "up"
              ? "upnav"
              : this.state.navStatus === "down"
              ? "downnav"
              : ""
          } ${this.props.appStatus === true ? `upheader` : `downheader`} ${
            this.props.fullWidthNavbar === true ? `full-width` : ``
          }${
            this.state.navStatus === "up2"
              ? "up2"
              : this.state.navStatus === "down2"
              ? "down2"
              : ""
          }`}
        >
          {this.props.fullWidthNavbar ? (
            <a
              onClick={() =>
                window.location.assign(
                  `${process.env.REACT_APP_NEW_APP}/switch-store`
                )
              }
              href="#"
              // to={`/${this.props.store.store_geo}/${this.props.store.id}/`}
              style={{ height: 64, marginRight: 24 }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="64"
                height="64"
                viewBox="0 0 48 48"
              >
                <g data-name="Group 1759">
                  <path d="M0 0h47.779L48 48H.221z" fill="#191919" />
                  <path
                    d="M24.205 15.377c0 .008.015.012.019.021a.213.213 0 01.022.027c0 .009.015.02.02.03l.016.033.015.035a.156.156 0 01.01.037c0 .012 0 .025.009.039a.174.174 0 010 .039.271.271 0 010 .04V15.787a.215.215 0 01-.009.039.192.192 0 01-.01.038.17.17 0 01-.015.037l-.015.034a.331.331 0 01-.016.032c0 .01-.015.02-.019.029a.157.157 0 01-.022.027l-.025.023a.276.276 0 01-.025.02.1.1 0 01-.028.016c-.009 0-.019.009-.027.012l-.028.009H24a.118.118 0 01-.029-.007l-.028-.011a.113.113 0 01-.027-.015.223.223 0 01-.028-.018c-.009-.007-.016-.015-.025-.021a.135.135 0 01-.022-.025.324.324 0 01-.022-.027c0-.009-.015-.021-.019-.031l-.016-.033-.015-.036a.141.141 0 01-.01-.037c0-.011 0-.025-.009-.038a.165.165 0 010-.04.189.189 0 010-.023l-6.232 17h5.546a.35.35 0 00.049-.009.341.341 0 00.049-.016l.047-.022a.412.412 0 00.045-.028.442.442 0 00.043-.034.488.488 0 00.041-.041.471.471 0 00.038-.046l.034-.05a.483.483 0 00.055-.113v-.008l6.184-17zm-.881 16.124l.022.024zm-.681.108l-.016.031zm-.03.055a.588.588 0 00-.028.059.591.591 0 01.027-.059zm.911.394v.065-.065z"
                    fill="#67e9d9"
                  />
                  <path
                    d="M9.427 15.254l3.295 9.671.009.024c.035.094.074.185.115.275l.261.766.166.488 2 5.867.009.025a.931.931 0 00.051.112.66.66 0 00.033.052.505.505 0 00.037.047.5.5 0 00.04.042.4.4 0 00.043.036l.045.031c.015.008.031.017.047.023a.35.35 0 00.049.018.291.291 0 00.05.01.331.331 0 00.049 0h5.539L17.97 23.07l-.009-.025q-.053-.141-.115-.274l-.261-.767-.166-.488-2-5.867-.009-.024-.025-.056-.029-.056a.617.617 0 00-.033-.052l-.037-.047a.5.5 0 00-.04-.042.509.509 0 00-.043-.036.52.52 0 00-.045-.03.359.359 0 00-.047-.024l-.049-.018a.436.436 0 00-.05-.01H9.427zm5.186.2c-.012.017-.024.033-.035.051a.548.548 0 01.035-.056zm-.065.106a.636.636 0 01.001 0zm-.034.079l-.015.042zm.892.643a.53.53 0 01-.027.059l.027-.064zm-.039.083l-.016.031zm-.027 15.246l-.016.031zm-.031.055a.53.53 0 000-.004zm.917.469v.06l.001-.061zm-.036.191c0 .014-.01.028-.015.042a.967.967 0 01.014-.046zm-.022.063l-.027.059.026-.064zm-.057.114c-.011.017-.022.034-.035.05l.035-.054z"
                    fill="#5db3ff"
                  />
                  <path
                    d="M38.536 15.244l-3.5.007h-.354l-10.172-.035h-.081a.456.456 0 00-.068.008c-.022 0-.045.007-.068.011a.377.377 0 00-.066.018l-.063.022-.06.027a.415.415 0 00-.056.031.475.475 0 00-.052.036.506.506 0 00-.047.04c-.015.015-.029.028-.042.043l-.033.041-.016.028-1.872 5.437 8.9-.018 5.587-.007zM35.183 24.588h-7.64l-1.53 4.21h7.643z"
                    fill="#fefefe"
                  />
                </g>
              </svg>
            </a>
          ) : null}
          <div className="navbar-ham">
            <Icon type="bars" onClick={() => this.props.toggleSidebar()} />
          </div>

          <Menu mode="horizontal" className="menu-left">
            <Menu.Item key="1">
              <Dropdown
                overlay={
                  hasPermission(
                    this.props.store,
                    "add_shopify_store_to_account"
                  )
                    ? storeListDropDown2
                    : storeListDropDown
                }
                onVisibleChange={(val) => this.setState({ arrow: val })}
                // trigger={["click"]}
                // visible={true}
              >
                <div
                  className="div"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {this.props.store.store_geo === "global" ? (
                    <Icon
                      component={GeoGlobal}
                      style={{ marginRight: "8px", fontSize: "28px" }}
                    />
                  ) : (
                    <Icon
                      component={GeoIn}
                      style={{ marginRight: "8px", fontSize: "28px" }}
                    />
                  )}
                  <a href="#!">
                    <span style={{ color: "#212121" }}>Viewing Data For:</span>{" "}
                    <span
                      style={{ color: "#4398ED", textTransform: "capitalize" }}
                    >
                      {currentStoreName}
                      <svg
                        style={{
                          marginLeft: "5px",
                          transition: "all 0.5s",

                          transform: `${
                            this.state.arrow
                              ? "rotateX(180deg)"
                              : "rotateX(0deg)"
                          }`,
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="8"
                        viewBox="0 0 16 8"
                      >
                        <g
                          id="Polygon_39"
                          data-name="Polygon 39"
                          transform="translate(16 8) rotate(180)"
                          fill={`${this.state.arrow ? "#4398ED" : "none"}`}
                        >
                          <path d="M8,0l8,8H0Z" stroke="none" />
                          <path
                            d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                            stroke="none"
                            fill="#4398ed"
                          />
                        </g>
                      </svg>
                    </span>
                  </a>
                </div>
              </Dropdown>
            </Menu.Item>

            {!this.props.showSearch &&
            hasPermission(this.props.store, "access_wallet") ? (
              <Menu.Item key="2">
                <Link
                  className="walletParent"
                  to={`/${this.props.store.store_geo}/my-wallet`}
                  style={{ marginLeft: 30 }}
                >
                  {this.props.wallet_balance >= 0 ? (
                    <Alert
                      className="wallet"
                      // style={{ background: "#F5FAFF", padding: "13px 8px" }}
                      message={
                        <>
                          <span>Wallet Balance: </span>

                          {this.props.walletLoading ? (
                            <Spin />
                          ) : (
                            <span
                              style={{ fontWeight: "600" }}
                            >{`${getCurrencySymbol(currency)} ${formatBalance(
                              this.props.wallet_balance
                            )}`}</span>
                          )}
                        </>
                      }
                      type="info"
                    />
                  ) : (
                    <Alert
                      className="wallet"
                      // style={{ background: "#F5FAFF", padding: "13px 8px" }}
                      message={
                        <>
                          <span>Wallet Balance: </span>

                          {this.props.walletLoading ? (
                            <Spin />
                          ) : (
                            <span
                              style={{ fontWeight: "600" }}
                            >{`${getCurrencySymbol(currency)} ${formatBalance(
                              this.props.wallet_balance
                            )}`}</span>
                          )}
                        </>
                      }
                      type="info"
                    />
                  )}
                </Link>
              </Menu.Item>
            ) : null}
          </Menu>

          {this.props.showSearch ? (
            <div style={{ flex: 1 }}>
              <form
                className="global-search"
                onSubmit={(e) => {
                  e.preventDefault();

                  if (window.location.href.includes("packaging")) {
                    this.props.history.push(
                      `/${this.props.match.params.storegeo}/${this.props.match.params.storeid}/products/search-products?packaging=true?search=${this.state.search}&listing=1`
                    );
                  } else if (window.location.href.includes("search-products")) {
                    this.props.history.push(
                      `/${this.props.match.params.storegeo}/${this.props.match.params.storeid}/products/search-products?search=${this.state.search}&listing=1`
                    );
                  } else {
                    this.props.history.push(
                      `/${this.props.match.params.storegeo}/${this.props.match.params.storeid}/products/search-products?search=${this.state.search}&listing=0`
                    );
                  }
                }}
              >
                <input
                  type="text"
                  placeholder="What are you looking for?"
                  value={this.state.search}
                  onChange={(e) => this.setState({ search: e.target.value })}
                />
                <button type="submit">Search</button>
              </form>
            </div>
          ) : null}

          <Menu
            mode="horizontal"
            className="menu-right"
            defaultSelectedKeys={["2"]}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Menu.Item className="plan-name-wraper">
              {this.planNameHandler(
                this.props.auth.plan && this.props.auth.plan.current_plan
              )}
            </Menu.Item>

            <Menu.Item className="download-route">
              {" "}
              <Tooltip title="Downloaded Reports">
                <Link to="/downloads">
                  {" "}
                  <Icon color={"red"} component={Download} />
                </Link>{" "}
              </Tooltip>
            </Menu.Item>
            <Menu.Item style={{ marginRight: 15 }}>
              <Notifications />
            </Menu.Item>
            {this.props.store.store_shopifyadmin && (
              <Menu.Item key="1" style={{ marginRight: 15 }}>
                <Dropdown
                  onVisibleChange={(visible) =>
                    this.dropdownToggle("help", visible)
                  }
                  // visible={true}
                  overlay={
                    <Menu className="shop">
                      <Menu.Item key="0">
                        <a
                          href={this.props.store_url}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Web {SHOPIFY_NAME}
                        </a>
                      </Menu.Item>

                      <Menu.Divider />

                      <Menu.Item
                        onClick={() => {
                          this.setState({ help: !this.state.help });
                        }}
                        key="3"
                      >
                        <a
                          href={this.props.store_admin_url}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          {SHOPIFY_NAME} Admin
                        </a>
                      </Menu.Item>
                    </Menu>
                  }
                  className="shopify-notification"
                >
                  <a
                    className="ant-dropdown-link"
                    href="#/"
                    style={{ color: "black" }}
                  >
                    {this.props.store.store_platform === "shopify" ? (
                      <>
                        <Icon
                          component={shopifyLogo}
                          style={{
                            fontSize: "24px",
                            marginRight: 6,
                            verticalAlign: "middle",
                            color: "#000",
                          }}
                          className="icon-without-hover"
                        />{" "}
                        <Icon
                          component={shopifyLogoColored}
                          style={{
                            fontSize: "24px",
                            marginRight: 6,
                            verticalAlign: "middle",
                            color: "#000",
                          }}
                          className="icon-with-hover"
                        />{" "}
                      </>
                    ) : (
                      <img
                        src={logoWoocommerce}
                        alt=""
                        className="ogoWoocommerce_img"
                      />
                    )}

                    <svg
                      style={{
                        marginLeft: "0px",
                        transition: "all 0.5s",
                        transform: `${
                          this.state.help ? "rotateX(180deg)" : "rotateX(0deg)"
                        }`,
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="8"
                      viewBox="0 0 16 8"
                    >
                      <g
                        id="Polygon_49"
                        data-name="Polygon 49"
                        transform="translate(16 8) rotate(180)"
                        fill={`${this.state.help ? "#898989" : "none"}`}
                      >
                        <path d="M8,0l8,8H0Z" stroke="none" />
                        <path
                          d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                          stroke="none"
                          fill="#000"
                        />
                      </g>
                    </svg>
                  </a>
                </Dropdown>
              </Menu.Item>
            )}
            {/* <Menu.Item key="2" style={{ marginRight: 15 }}>
              <Dropdown
                // visible={true}
                overlay={helpDropdownMenu}
                onVisibleChange={(visible) =>
                  this.dropdownToggle("helpDropdownVisible", visible)
                }
                className="help-notification"
              >
                <a
                  className="ant-dropdown-link "
                  href="#/"
                  style={{ color: "black" }}
                >
                  <Icon
                    component={help}
                    style={{
                      fontSize: "21px",
                      marginRight: 6,
                      verticalAlign: "middle",
                      color: "#000",
                    }}
                    className="help-icon"
                  />{" "}
                  <Icon
                    component={help_colored}
                    style={{
                      fontSize: "21px",
                      marginRight: 6,
                      verticalAlign: "middle",
                      color: "#000",
                    }}
                    className="help-icon-hover"
                  />
                  Help{" "}
                  <svg
                    style={{
                      marginLeft: "5px",
                      transition: "all 0.5s",
                      transform: `${
                        this.state.helpDropdownVisible
                          ? "rotateX(180deg)"
                          : "rotateX(0deg)"
                      }`,
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                  >
                    <g
                      id="Polygon_49"
                      data-name="Polygon 49"
                      transform="translate(16 8) rotate(180)"
                      fill={`${
                        this.state.helpDropdownVisible ? "#898989" : "none"
                      }`}
                    >
                      <path d="M8,0l8,8H0Z" stroke="none" />
                      <path
                        d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                        stroke="none"
                        fill="#000"
                      />
                    </g>
                  </svg>
                </a>
              </Dropdown>
            </Menu.Item> */}

            <Menu.Item className="navbar-user-detail" key="3">
              <Dropdown
                overlay={userDropdownMenu}
                onVisibleChange={(visible) =>
                  this.dropdownToggle("userDropdownVisible", visible)
                }
              >
                <a className="ant-dropdown-link" href="#/">
                  <Avatar
                    style={{
                      backgroundColor: "#FC4747",
                      verticalAlign: "middle",
                      textTransform: "uppercase",
                      fontSize: "2.25",
                    }}
                    size="large"
                  >
                    {this.props.user.user_fname[0]}
                  </Avatar>

                  <div className="avatar-details">
                    {this.props.user.user_fname}
                    <br />
                    <span
                      style={{
                        textDecoration: "underline",
                        textTransform: "capitalize",
                      }}
                    >
                      {" "}
                      {currentStoreName}
                    </span>
                  </div>
                  <svg
                    style={{
                      marginLeft: "5px",
                      transition: "all 0.5s",
                      transform: `${
                        this.state.userDropdownVisible
                          ? "rotateX(180deg)"
                          : "rotateX(0deg)"
                      }`,
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                  >
                    <g
                      id="Polygon_49"
                      data-name="Polygon 49"
                      transform="translate(16 8) rotate(180)"
                      fill={`${
                        this.state.userDropdownVisible ? "#898989" : "none"
                      }`}
                    >
                      <path d="M8,0l8,8H0Z" stroke="none" />
                      <path
                        d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                        stroke="none"
                        fill="#000"
                      />
                    </g>
                  </svg>
                </a>
              </Dropdown>
            </Menu.Item>
          </Menu>
        </Header>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
