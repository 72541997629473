import React, { Component } from "react";
import { Collapse, Tooltip, Icon, Switch, message } from "antd";
import ClickToCopy from "../../../Common/ClickToCopy";
import { ReactComponent as link } from "../../../../../assets/images/icons/link.svg";
import { formatBalance } from "../../../../../Helpers/Pricing";
import CutomTooltip from "../../../Common/GlobalTooltipIconWhite";
// import axios from "axios";

import "./index.scss";

const { Panel } = Collapse;

class Expandable extends Component {
  state = {
    open: false,
    // flexibleFullfillment:
    //   this.props.order.flexible_fullfillment_on ? true : false,
  };
  // handleflexibleFullfillment(checked) {
  //   this.setState(
  //     {
  //       escalateLoading: true,
  //     },
  //     () => {
  //       axios({
  //         url: process.env.REACT_APP_API_URL + "/products/enable_buyback", //new api have to be given by backend
  //         method: "POST",
  //         data: {
  //           productid:  this.props.order._id,
  //           storeid: this.props.match.params.storeid,
  //           flexible_fullfillment_on: checked ? 1 : 0,
  //           page_type:"inventory"
  //         },
  //         withCredentials: true,
  //       })
  //         .then((res) => {
  //           if (res.data.success === 1) {
  //             this.setState({
  //               escalateLoading: false,
  //               flexibleFullfillment:
  //                 this.state.flexibleFullfillment === true ? false : true,
  //             });

  //             message.success(res.data.msg);
  //             // this.props.loadData();
  //           } else {
  //             this.setState({
  //               escalateLoading: false,
  //             });
  //             message.warning(res.data.msg);
  //           }
  //         })
  //         .catch((err) => {
  //           this.setState({
  //             escalateLoading: false,
  //           });
  //           message.warning(err.message);
  //         });
  //     }
  //   );
  // }

  render() {
    let order = this.props.order;
    let product_img =
      order && order.images && order.images.length > 0 && order.images[0].url;

    // console.log("in",this.props.match.params.storeid);
    return (
      <>
        <Collapse
          className="collapse-item"
          showArrow={false}
          expandIconPosition="right"
          onChange={() => this.setState({ open: !this.state.open })}
        >
          <Panel
            showArrow={false}
            header={
              <div className="container">
                <div
                  className="prod-details"
                  onClick={(event) => event.stopPropagation()}
                >
                  <div className="img">
                    <img src={product_img} alt="" />
                  </div>
                  <div className="product-link-name">
                    <p>
                      {order.product_name.length <= 20 ? (
                        <a
                          href={`./products/${order.product_vfid}`}
                          target="_blank"
                          className="view-in-vf-link"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          {order && order.product_name
                            ? order.product_name
                            : null}
                          <Icon
                            component={link}
                            style={{ marginLeft: "6px" }}
                          />
                        </a>
                      ) : (
                        <Tooltip title={order.product_name}>
                          {" "}
                          <p>
                            {" "}
                            <a
                              href={`./products/${order.product_vfid}`}
                              target="_blank"
                              className="view-in-vf-link"
                              rel="noopener noreferrer"
                            >
                              {" "}
                              {order && order.product_name
                                ? order.product_name.slice(0, 20) + "..."
                                : null}
                              <Icon
                                component={link}
                                style={{ marginLeft: "6px" }}
                              />
                            </a>
                          </p>
                        </Tooltip>
                      )}
                    </p>

                    <ClickToCopy
                      inventoryId={order.product_vfid}
                      textToCopy={order.product_vfid}
                    />
                  </div>{" "}
                </div>
                <div
                  onClick={(event) => event.stopPropagation()}
                  className="live-inv"
                >
                  {order.total_live_qty}
                </div>
                <div
                  onClick={(event) => event.stopPropagation()}
                  className="pricing"
                >
                  <p>
                    {order.total_live_qty > 0 ? "₹ " : null}
                    {order.total_live_qty > 0
                      ? formatBalance(
                          order.total_floating_value.base / order.total_live_qty
                        )
                      : "N/A"}

                    <span>
                      {order.total_live_qty > 0 &&
                      Number(order.total_floating_value.gst) > 0 ? (
                        <p> +GST ({order.product_gst} %)</p>
                      ) : null}
                    </span>
                  </p>{" "}
                </div>

                <div
                  className="dist"
                  onClick={(event) => event.stopPropagation()}
                >
                  {order.total_floating_value.base > 0 ? (
                    <span>
                      ₹ {formatBalance(order.total_floating_value.base)}
                    </span>
                  ) : (
                    "N/A"
                  )}

                  {order.total_live_qty > 0 &&
                  Number(order.total_floating_value.gst) > 0 ? (
                    <p> +GST ({order.product_gst} %)</p>
                  ) : null}
                </div>

                <div
                  className="expand"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  {this.props.activeTab === "inventory" ? (
                    <div
                      onClick={(event) => event.stopPropagation()}
                      className="flexible"
                    >
                      {/* {order}  */}
                      {order.show_restock_button ? (
                        <a
                          href={`./products/${order.product_vfid}`}
                          target="_blank"
                          className="view-in-vf-link restock-button"
                          rel="noopener noreferrer"
                        >
                          Re-Stock
                          <CutomTooltip data={order} custom={true} />
                        </a>
                      ) : null}
                    </div>
                  ) : null}

                  <div className="ic-w">
                    <span>
                      <p>
                        {" "}
                        {this.state.open ? "Contract View" : "Expand View"}
                      </p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="8"
                        viewBox="0 0 16 8"
                        style={{
                          marginLeft: "5px",
                          transition: "all 0.5s",
                          transform: `${
                            this.state.open
                              ? "rotateX(180deg)"
                              : "rotateX(0deg)"
                          }`,
                        }}
                      >
                        <g
                          id="Polygon_41"
                          data-name="Polygon 41"
                          transform="translate(16 8) rotate(180)"
                          fill={`${this.state.open ? "#1370FC" : "none"}`}
                        >
                          <path d="M8,0l8,8H0Z" stroke="none" />
                          <path
                            d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                            stroke="none"
                            fill="#1370fc"
                          />
                        </g>
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            }
            key="1"
          >
            <div className="head-inner">
              <div className="prod-details">Variant SKU Details</div>
              <div className="live-inv">Live Variant Qty</div>
              {/* <div className="pricing">
                <p>Pricing</p>
              </div>

              <div className="dist"> Variant Distribution</div> */}

              <div className="expand">Expand View</div>
            </div>

            {order.sku_breakup.map((sku) => {
              return (
                <div className="bottom-head2">
                  <div
                    className="prod-details"
                    onClick={(event) => event.stopPropagation()}
                  >
                    <div className="product-link-name">
                      <span>SKU:</span> {sku.sku}
                    </div>
                    <div>
                      <span>Specs:</span> {sku.allspecs}
                    </div>
                  </div>

                  <div className="live-inv">{sku.total_live_qty}</div>
                  {/* <div className="pricing">
                    <div className="price-values">
                      <span>{sku.total_float_value.base}</span> +{" "}
                      <span>{sku.total_float_value.gst}</span> ={" "}
                      <span>₹ {sku.total_float_value.total}</span>
                    </div>
                  </div>

                  <div className="dist"> {sku.total_india_fc}</div> */}

                  <div className="expand">Expand View</div>
                </div>
              );
            })}
          </Panel>
        </Collapse>

        {/* logs */}
      </>
    );
  }
}

export default Expandable;
