import React from "react";
import {
  Button,
  Modal,
  DatePicker,
  Select,
  InputNumber,
  Checkbox,
  Icon,
  Tooltip,
  Divider,
  message,
} from "antd";
import moment from "moment";
import "./index.scss";
import {
  getCurrencySymbol,
  formatNumber,
} from "../../../../../Helpers/Pricing";
import { Link } from "react-router-dom";
import TooltipIcon from "../../../Common/GlobalTooltipIcon";
import { ReactComponent as requestalert } from "../../../../../assets/images/icons/requestalert.svg";
import { SHOPIFY_NAME } from "../../../../../constants";
import { cloneDeep } from "lodash";

const { Option } = Select;

const OrderConfirmationModal = (props) => {
  const storegeo = props.storegeo;
  const currency = props.storegeo === "in" ? "INR" : "USD";
  let ordersToDisplay = {};
  let retailNotAllowed = {};

  if (props.id && props.orders[props.id]) {
    ordersToDisplay[props.id] = Object.values(props.orders[props.id]);
  } else {
    Object.keys(props.orders).forEach((orderid) => {
      ordersToDisplay[orderid] = Object.values(props.orders[orderid]);
    });
  }

  const reasons = [
    {
      code: "not_contactable",
      description: "Unable to Contact the Customer",
    },
    {
      code: "duplicate_order",
      description: "Duplicate Order",
    },
    {
      code: "email_cancellation",
      description: "Cancellation Requested by Customer",
    },
    {
      code: "not_interested",
      description: "Customer Not Interested",
    },
    {
      code: "fake_order",
      description: "Fraudulent Order",
    },
    {
      code: "wrong_or_invalid_number",
      description: "Invalid Contact Number",
    },
    {
      code: "language_barrier",
      description: "Language Barrier",
    },
    {
      code: "repeated_rto",
      description: "Customer has High RTO History",
    },
    {
      code: "not_serviceable",
      description: "Not Serviceable Area",
    },
    {
      code: "lost_to_competition",
      description: "Lost to Competition",
    },
    {
      code: "issue_with_product_price",
      description: "Product Price seems to be High",
    },
    {
      code: "inventory_not_available",
      description: "Product/Variant Out of Stock",
    },
    {
      code: "variant_change_requested",
      description: "Variant Change Requested",
    },
    {
      code: "customer_not_available",
      description: "Customer Not Available / Out of Station",
    },
    {
      code: "test_order",
      description: "Test order",
    },
  ];

  for (let key in ordersToDisplay) {
    // Check if the key is a property of the object
    if (ordersToDisplay.hasOwnProperty(key)) {
      // Iterate over each inner object
      for (let innerKey in ordersToDisplay[key]) {
        // Check if the inner object has the retail_allow property and its value is false
        if (
          ordersToDisplay[key][innerKey].hasOwnProperty("retail_allow") &&
          !ordersToDisplay[key][innerKey]["retail_allow"]
        ) {
          // Move the entire object to the retailNotAllowed object
          retailNotAllowed[key] = ordersToDisplay[key];
          // Remove the object from the data object
          delete ordersToDisplay[key];
          // Break the loop as we have moved the entire object
          break;
        }
      }
    }
  }

  const titles = {
    verify: "Verify Order Confirmation",
    verifyorder: "Acknowledge Order Confirmation",
    hold: "Put Order On Hold Confirmation",
    nofulfill: "Not To Fulfill Order Confirmation",
    cancel: "Order Cancellation Confirmation",
    move_to_calling: "Move To Calling",
  };

  const headings = {
    verify: `By verifying the order(s) for items below, you are acknowledging that all the information related to the order(s) such as customer info &
      mapping with vFulfill variants is correct and the order(s) are ready for fulfilment.`,
    verifyorder:
      "Once order(s) for items below are confirmed and placed, vFulfill will manage & fulfill them for you.",
    hold: `The following orders will be put on hold for the duration that you select. Once an order has been put on hold, no further action can be
    taken on it until it has been Unheld manually or after the hold duration ends.`,
    nofulfill: `By marking following orders as “Do Not Fulfill” you are acknowledging that these orders should not be fulfilled by vFulfill and will be
      taken care of by you.`,
    cancel: "You are about to cancel the following orders.",
  };

  const ctas = {
    verify: "Verify Order(s)",
    verifyorder: "Place Order(s)",
    hold: "Put Order(s) On Hold",
    nofulfill: "Do Not Fulfill Order(s)",
    move_to_calling: "Move To Calling",
    cancel: "Confirm Cancellation",
  };

  const ctasbtn = {
    verify: "Verify Order",
    verifyorder: "Confirm & Place Order",
    hold: "Put On Hold",
    move_to_calling: "Move To Calling",
    nofulfill: "Do Not Fulfill",
    cancel: "Confirm Cancellation",
  };

  // const orderCost = ordersToDisplay.reduce(
  //   (acc, o) => acc + o.quantity * o.item_mrp,
  //   0
  // );

  const zeroPP = !props.shippingCost.itemCost;
  const zeroFF = !props.shippingCost.fulfilment;

  function totalPlatformFees(obj) {
    let value = 0;
    Object.keys(obj).map((d) => {
      value +=
        !!props.shippingCost.orders[d] &&
        !!props.shippingCost.orders[d].total_platformCost
          ? props.shippingCost.orders[d].total_platformCost
          : 0;
    });
    return value;
  }
  function totalPlatformFeesField(obj) {
    let value = 0;
    Object.keys(obj).map((d) => {
      value +=
        !!props.shippingCost.orders[d] &&
        !!props.shippingCost.orders[d].total_platformCost
          ? props.shippingCost.orders[d].total_platformCost
          : 0;
    });
    if (value > 0) {
      return (
        <p className="order-cost-txt custom-width-120">
          Platform Cost{" "}
          <TooltipIcon
            tooltipText={
              "Platform fees is charged against each order placed to vFulfill"
            }
          />{" "}
        </p>
      );
    } else {
      return null;
    }
  }
  let detailsColWidth = 6;
  if (storegeo === "in") {
    if (!zeroPP && !zeroFF) {
      detailsColWidth = 6;
    } else {
      detailsColWidth = 9;
    }
  } else {
    if (!zeroPP && !zeroFF) {
      detailsColWidth = 9;
    } else {
      detailsColWidth = 12;
    }
  }

  function fetchskudata(props) {
    try {
      const skudata = Object.entries(props.shippingCost.inventory_count);
      return (
        <>
          <div className="sku_container">
            <div className="sku_data_heading">
              <p className="sku_txt">SKU</p>
              <p className="order_count_txt">Order Count</p>
              <p className="current_qty_tx">Current Quantity</p>
              <p className="placing_order_txt">Quantity After Placing Order</p>
            </div>

            {skudata.map((s) => (
              <div className="sku_value">
                <p className="sku_txt">{s[0]}</p>
                <p className="order_count_txt">
                  {s[1].order_count}{" "}
                  <span className="piece">
                    {s && s[1].order_count === 1 ? "piece" : "pieces"}
                  </span>
                </p>
                <p className="current_qty_tx">
                  {s[1].pre_live_qty}{" "}
                  <span className="piece">
                    {s && s[1].pre_live_qty === 1 ? "piece" : "pieces"}
                  </span>
                </p>
                <p className="placing_order_txt">
                  {s[1].post_live_qty}{" "}
                  <span className="piece">
                    {s && s[1].post_live_qty === 1 ? "piece" : "pieces"}
                  </span>
                </p>
              </div>
            ))}
          </div>

          <Divider />
        </>
      );
    } catch (error) {
      message.error(error);
    }
  }

  function getFfAndPp(orderid) {
    if (props.type === "verifyorder") {
      try {
        if (
          !!props.apiPlaceResponse.orders[orderid]["fulfilment"] &&
          !!props.apiPlaceResponse.orders[orderid]["itemCost"]
        ) {
          return "both";
        }

        if (!!props.apiPlaceResponse.orders[orderid]["fulfilment"]) {
          return "fulfilment";
        }

        if (!!props.apiPlaceResponse.orders[orderid]["itemCost"]) {
          return "itemCost";
        }
      } catch (error) {
        return "loading";
      }
    } else {
      return null;
    }
  }

  function rtoRiskChecker(score) {
    if (!props.showMavFilters) {
      return;
    }

    try {
      if (score < 50) {
        return <p className="rto-badge high"> High RTO Risk </p>;
      }

      if (score > 50 && score < 70) {
        return <p className="rto-badge moderate"> Moderate RTO Risk </p>;
      }

      if (score > 70) {
        return <p className="rto-badge no"> Low RTO Risk </p>;
      }
    } catch (error) {}
  }

  function retailAllowPriceCalc(retailAllowData) {
    if (props.bulkSelect) {
      let amount = 0;

      let bulkData = cloneDeep(props.bulkCopy);
      let selectedConfirmed = cloneDeep(props.selectedConfirmed);

      for (const key in bulkData) {
        const ogDataItem = selectedConfirmed[key];
        if (ogDataItem && Object.keys(ogDataItem).length > 0) {
          const retailAllow =
            ogDataItem[Object.keys(ogDataItem)[0]].retail_allow;
          for (const innerKey in bulkData[key]) {
            bulkData[key][innerKey].retail_allow = retailAllow ? true : false;
          }
        }
      }

      for (let key in bulkData) {
        // Check if the key is a property of the object
        if (bulkData.hasOwnProperty(key)) {
          // Iterate over each inner object
          for (let innerKey in bulkData[key]) {
            // Check if the inner object has the retail_allow property and its value is false
            if (
              bulkData[key][innerKey].hasOwnProperty("retail_allow") &&
              !bulkData[key][innerKey]["retail_allow"]
            ) {
              // Remove the object from the data object
              delete bulkData[key];
              // Break the loop as we have moved the entire object
              break;
            }
          }
        }
      }

      Object.keys(bulkData).map((orderid, idx) => {
        amount +=
          (props.shippingCost.orders[orderid] &&
            props.shippingCost.orders[orderid].total) ||
          0;
      });

      return amount;
    } else {
      let amount = 0;

      Object.keys(retailAllowData).map((orderid, idx) => {
        amount +=
          (props.shippingCost.orders[orderid] &&
            props.shippingCost.orders[orderid].total) ||
          0;
      });

      return amount;
    }
  }

  return (
    <Modal
      className="orders-confirmation-modal"
      title={
        <div className="confirm-modal-header flex-between">
          <div style={{ width: "60% !important" }}>
            <p>
              {titles[props.type] === undefined
                ? "Unhold order"
                : ctas[props.type]}
            </p>
            <p className="subheading-txt">{headings[props.type]}</p>
          </div>

          {props.type === "verifyorder" ? (
            <div className="colors-grad">
              <div className="r">
                <span /> Inventory
              </div>
              <div className="g">
                <span /> Retail
              </div>
              <div className="b">
                <span /> Both
              </div>
            </div>
          ) : null}
        </div>
      }
      visible={props.visible}
      closable={!props.saving}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={props.onCancel}
      // width={props.type === "verifyorder" ? 1050 : 800}
      width={952}
      footer={
        props.shippingLoader ? null : (
          <React.Fragment>
            <div className="footer-line-item">
              <div className="footer-left-line-item">
                {props.type === "cancel" && (
                  <span className="line-item-select-resons">
                    <Select
                      showSearch
                      placeholder="Cancellation Reason"
                      onChange={(val) =>
                        props.onChangeConfirmationModalField(
                          "cancelReason",
                          val
                        )
                      }
                      popupMatchSelectWidth
                    >
                      {reasons.map((data) => (
                        <Option value={data.code} className="commondropdown">
                          {data.description}
                        </Option>
                      ))}
                    </Select>
                  </span>
                )}
                {props.type === "hold" && (
                  <span>
                    <span style={{ color: "#FF0A0A" }}>*</span>
                    <span className="snooze-until">Snooze Until: </span>
                    <DatePicker
                      style={{ borderRadius: "1px", fontsize: "12px" }}
                      format="YYYY-MM-DD"
                      onChange={(_, dateString) =>
                        props.onChangeConfirmationModalField(
                          "snoozeDate",
                          dateString
                        )
                      }
                      disabledDate={(current) =>
                        current &&
                        current <
                          moment()
                            .subtract(0, "days")
                            .endOf("day")
                      }
                    />
                  </span>
                )}
                {props.type === "nofulfill" && (
                  <div className="check-box-wraper">
                    <Checkbox
                      style={{ marginBottom: 15 }}
                      onChange={(e) =>
                        props.onChnagenotfulfill(
                          "notfulfillcheck",
                          e.target.checked
                        )
                      }
                    >
                      {" "}
                    </Checkbox>
                    <p
                      className={
                        props.notfulfillcheck
                          ? "notfulfill-check-txt"
                          : "notfulfill-check-txt2"
                      }
                    >
                      {" "}
                      I understand that this action cannot be reverted back and
                      would like to continue with it.
                    </p>
                  </div>
                )}
              </div>
              <div className="footer-right-line-item-btn">
                <Button
                  key="back"
                  onClick={props.onCancel}
                  className="lineitem-cancel-btn"
                >
                  {props.type === "cancel" ? "Close Window" : "Close Window"}
                </Button>{" "}
                <Button
                  key="submit"
                  type="primary"
                  // disabled={props.notfulfillcheck ? false : true}
                  className={
                    props.type === "nofulfill" && !props.notfulfillcheck
                      ? "disbaledropdown"
                      : "lineitem-conform-btn clickable-dropdown"
                  }
                  disabled={
                    !!Object.keys(ordersToDisplay).length ? false : true
                  }
                  onClick={
                    props.type === "move_to_calling"
                      ? () => props.moveToCallingHandler()
                      : () => props.onConfirm(props.type)
                  }
                  loading={props.saving}
                >
                  {ctas[props.type] === undefined
                    ? "Unhold order "
                    : ctasbtn[props.type]}
                </Button>
              </div>
            </div>
          </React.Fragment>
        )
      }
    >
      {/* <div>{props.inventory_count. map((sku) => sku.ordersToDisplay. )}</div> */}

      {/* {fetchskudata(props)} */}
      <div className="selected-orders">
        <div className="header-row">
          {props.bulkSelect ? (
            <Checkbox
              style={{ opacity: "0" }}
              className={"flex-center"}
              defaultChecked
            />
          ) : null}

          <p className="order-number-txt">Order Number</p>
          <p className="order-items-txt">Order Items</p>
          {props.type === "verify" ||
          props.type === "hold" ||
          props.type === "nofulfill" ||
          props.type === "cancel" ? (
            <p className="quantity-txt">Quantity</p>
          ) : null}

          {props.type === "verifyorder" && (
            <>
              <p className="quantity-txt">Quantity</p>
              {zeroPP ? null : (
                <p className="product-price-txt"> Retail Price</p>
              )}

              {zeroFF ? null : (
                <p className="fulfillment-price-txt">Fulfilment Fee</p>
              )}
              <p className="forward-shipping-txt">Forward Shipping</p>
              <p className="cod-txt">COD</p>

              {totalPlatformFeesField(ordersToDisplay)}

              <p className="order-cost-txt">Order Cost</p>
            </>
          )}
        </div>

        {Object.keys(ordersToDisplay).map((orderid, idx) => (
          <>
            <div className={`line-item ${getFfAndPp(orderid)}`}>
              {props.bulkSelect ? (
                !props.shippingCost.orders[orderid] ? (
                  <Icon type="loading" />
                ) : (
                  <Checkbox
                    className={"flex-center"}
                    defaultChecked
                    value={orderid}
                    onChange={(e) => props.bulkHandler(e.target)}
                  />
                )
              ) : null}

              <p className="orderid-txt">
                <p className="vFulfill-txt">
                  <span className="vfulfill-txt">vFulfill:</span>{" "}
                  <span className="order-id">
                    #
                    {Object.keys(ordersToDisplay).length &&
                    Object.keys(ordersToDisplay).length === 1
                      ? props.modalvforderid
                      : props.multiplevforderid[idx]}
                    {/* 
                  {!!props.multiplevforderid.length
                    ? props.multiplevforderid[idx]
                    : props.modalvforderid} */}
                  </span>
                </p>

                <p className="vfulfill-txt">
                  <span>{`${SHOPIFY_NAME}:`}</span>{" "}
                  <span className="order-id">
                    <a
                      href={`https://${props.store.store_shopifyadmin}/admin/orders/${props.ordershopifyid}`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {orderid}
                    </a>
                  </span>
                </p>

                {rtoRiskChecker(
                  Object.keys(ordersToDisplay).length &&
                    Object.keys(ordersToDisplay).length === 1
                    ? props.cardRiskScore
                    : props.riskScore[idx]
                )}
              </p>

              <div className="product-description">
                {ordersToDisplay[orderid].map((li) => {
                  return (
                    <p className="line-item-product-detail">
                      <img src={li.img} alt="#" />
                      <div className="side">
                        <p>
                          {li && li.name && li.name.length > 20 ? (
                            <Tooltip title={li.name}>
                              <a
                                href={li.product_link}
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                {`${li.name.substring(0, 20)}...`}
                              </a>
                            </Tooltip>
                          ) : li && li.name ? (
                            <span>
                              <a
                                href={li.product_link}
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                {li.name}
                              </a>
                            </span>
                          ) : (
                            <span className="not-available">Not Available</span>
                          )}
                        </p>{" "}
                        <p
                          style={{
                            margin: "0 0 0 0px",
                            whiteSpace: "nowrap",
                          }}
                          className="line-item-sku"
                        >
                          <span>SKU:</span>
                          <span className="bundleitem-vfsku">
                            {" "}
                            {li && li.sku && li.sku.length > 14 ? (
                              <Tooltip title={li.sku}>
                                {`${li.sku.substring(0, 14)}...`}
                              </Tooltip>
                            ) : li && li.sku ? (
                              <span>{li.sku}</span>
                            ) : (
                              <span className="not-available">
                                Not Available
                              </span>
                            )}
                          </span>
                        </p>{" "}
                      </div>
                    </p>
                  );
                })}
              </div>

              <div className="product-description">
                {ordersToDisplay[orderid].map((li) => {
                  return (
                    <p className="line-item-quantity-txt">
                      <span style={{ color: "#000" }}>{li.quantity} </span>{" "}
                      <span className="piece">
                        {li && li.quantity === 1 ? "piece" : "pieces"}
                      </span>
                    </p>
                  );
                })}
              </div>

              {props.type === "verifyorder" && (
                <>
                  {zeroPP ? null : (
                    <div className="lineitem-product-price">
                      {props.shippingCost.orders[orderid] &&
                      props.shippingCost.orders[orderid].itemCost !==
                        undefined ? (
                        `${getCurrencySymbol(currency)} ${formatNumber(
                          Number(props.shippingCost.orders[orderid].itemCost)
                        )}`
                      ) : (
                        <Icon type="loading" />
                      )}
                    </div>
                  )}
                  {zeroFF ? null : (
                    <div className="lineitem-fulfilment-price">
                      {props.shippingCost.orders[orderid] &&
                      props.shippingCost.orders[orderid].fulfilment !==
                        undefined ? (
                        Number(
                          props.shippingCost.orders[orderid].fulfilment
                        ) === 0 ? (
                          `${getCurrencySymbol(currency)} 0.00`
                        ) : (
                          `${getCurrencySymbol(currency)} ${formatNumber(
                            Number(
                              props.shippingCost.orders[orderid].fulfilment
                            )
                          )}`
                        )
                      ) : (
                        <Icon type="loading" />
                      )}
                    </div>
                  )}
                  <div className="linitem-forwading-price">
                    {props.shippingCost.orders[orderid] &&
                    props.shippingCost.orders[orderid].shipping ? (
                      `${getCurrencySymbol(currency)} ${formatNumber(
                        Number(props.shippingCost.orders[orderid].shipping)
                      )}`
                    ) : (
                      <Icon type="loading" />
                    )}
                  </div>
                  {storegeo === "in" && (
                    <div className="linitem-cod-price">
                      {props.shippingCost.orders[orderid] &&
                      props.shippingCost.orders[orderid].codCost !==
                        undefined ? (
                        Number(props.shippingCost.orders[orderid].codCost) ===
                        0 ? (
                          `${getCurrencySymbol(currency)} 0.00`
                        ) : (
                          `${getCurrencySymbol(currency)} ${formatNumber(
                            Number(props.shippingCost.orders[orderid].codCost)
                          )}`
                        )
                      ) : (
                        <Icon type="loading" />
                      )}
                    </div>
                  )}

                  {props.shippingCost.orders[orderid] &&
                  props.shippingCost.orders[orderid].total &&
                  props.shippingCost.orders[orderid].total_platformCost > 0 ? (
                    <div className="lineitem-total-product-price custom-width-120">
                      {props.shippingCost.orders[orderid] &&
                      props.shippingCost.orders[orderid].total ? (
                        `${getCurrencySymbol(currency)}  ${formatNumber(
                          Number(
                            props.shippingCost.orders[orderid]
                              .total_platformCost
                          ).toFixed(2)
                        )}`
                      ) : (
                        <Icon type="loading" />
                      )}
                    </div>
                  ) : null}
                  <div className="lineitem-total-product-price">
                    {props.shippingCost.orders[orderid] &&
                    props.shippingCost.orders[orderid].total ? (
                      `${getCurrencySymbol(currency)} ${formatNumber(
                        Number(props.shippingCost.orders[orderid].total)
                      )}`
                    ) : (
                      <Icon type="loading" />
                    )}
                  </div>
                </>
              )}
            </div>
          </>
        ))}

        {Object.keys(retailNotAllowed).map((orderid, idx) => (
          <>
            <Tooltip title={"No Inventory To Ship This Order"}>
              <div
                className={`line-item retail-not-allowed ${getFfAndPp(
                  orderid
                )}`}
              >
                {props.bulkSelect ? (
                  !props.shippingCost.orders[orderid] ? (
                    <Icon type="loading" />
                  ) : (
                    <Checkbox
                      className={"flex-center"}
                      // defaultChecked
                      disabled
                      value={orderid}
                      onChange={(e) => props.bulkHandler(e.target)}
                    />
                  )
                ) : null}

                <p className="orderid-txt">
                  <p className="vFulfill-txt">
                    <span className="vfulfill-txt">vFulfill:</span>{" "}
                    <span className="order-id">
                      #
                      {Object.keys(retailNotAllowed).length &&
                      Object.keys(retailNotAllowed).length === 1
                        ? props.modalvforderid
                        : props.multiplevforderid[idx]}
                      {/* 
                  {!!props.multiplevforderid.length
                    ? props.multiplevforderid[idx]
                    : props.modalvforderid} */}
                    </span>
                  </p>

                  <p className="vfulfill-txt">
                    <span>{`${SHOPIFY_NAME}:`}</span>{" "}
                    <span className="order-id">
                      <a
                        href={`https://${props.store.store_shopifyadmin}/admin/orders/${props.ordershopifyid}`}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {orderid}
                      </a>
                    </span>
                  </p>

                  {rtoRiskChecker(
                    Object.keys(retailNotAllowed).length &&
                      Object.keys(retailNotAllowed).length === 1
                      ? props.cardRiskScore
                      : props.riskScore[idx]
                  )}
                </p>

                <div className="product-description">
                  {retailNotAllowed[orderid].map((li) => {
                    return (
                      <p className="line-item-product-detail">
                        <img src={li.img} alt="#" />
                        <div className="side">
                          <p>
                            {li && li.name && li.name.length > 20 ? (
                              <Tooltip title={li.name}>
                                <a
                                  href={li.product_link}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                >
                                  {`${li.name.substring(0, 20)}...`}
                                </a>
                              </Tooltip>
                            ) : li && li.name ? (
                              <span>
                                <a
                                  href={li.product_link}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                >
                                  {li.name}
                                </a>
                              </span>
                            ) : (
                              <span className="not-available">
                                Not Available
                              </span>
                            )}
                          </p>{" "}
                          <p
                            style={{
                              margin: "0 0 0 0px",
                              whiteSpace: "nowrap",
                            }}
                            className="line-item-sku"
                          >
                            <span>SKU:</span>
                            <span className="bundleitem-vfsku">
                              {" "}
                              {li && li.sku && li.sku.length > 14 ? (
                                <Tooltip title={li.sku}>
                                  {`${li.sku.substring(0, 14)}...`}
                                </Tooltip>
                              ) : li && li.sku ? (
                                <span>{li.sku}</span>
                              ) : (
                                <span className="not-available">
                                  Not Available
                                </span>
                              )}
                            </span>
                          </p>{" "}
                        </div>
                      </p>
                    );
                  })}
                </div>

                <div className="product-description">
                  {retailNotAllowed[orderid].map((li) => {
                    return (
                      <p className="line-item-quantity-txt">
                        <span style={{ color: "#000" }}>{li.quantity} </span>{" "}
                        <span className="piece">
                          {li && li.quantity === 1 ? "piece" : "pieces"}
                        </span>
                      </p>
                    );
                  })}
                </div>

                {props.type === "verifyorder" && (
                  <>
                    {zeroPP ? null : (
                      <div className="lineitem-product-price">
                        {props.shippingCost.orders[orderid] &&
                        props.shippingCost.orders[orderid].itemCost !==
                          undefined ? (
                          `${getCurrencySymbol(currency)} ${formatNumber(
                            Number(props.shippingCost.orders[orderid].itemCost)
                          )}`
                        ) : (
                          <Icon type="loading" />
                        )}
                      </div>
                    )}
                    {zeroFF ? null : (
                      <div className="lineitem-fulfilment-price">
                        {props.shippingCost.orders[orderid] &&
                        props.shippingCost.orders[orderid].fulfilment !==
                          undefined ? (
                          Number(
                            props.shippingCost.orders[orderid].fulfilment
                          ) === 0 ? (
                            `${getCurrencySymbol(currency)} 0.00`
                          ) : (
                            `${getCurrencySymbol(currency)} ${formatNumber(
                              Number(
                                props.shippingCost.orders[orderid].fulfilment
                              )
                            )}`
                          )
                        ) : (
                          <Icon type="loading" />
                        )}
                      </div>
                    )}
                    <div className="linitem-forwading-price">
                      {props.shippingCost.orders[orderid] &&
                      props.shippingCost.orders[orderid].shipping ? (
                        `${getCurrencySymbol(currency)} ${formatNumber(
                          Number(props.shippingCost.orders[orderid].shipping)
                        )}`
                      ) : (
                        <Icon type="loading" />
                      )}
                    </div>
                    {storegeo === "in" && (
                      <div className="linitem-cod-price">
                        {props.shippingCost.orders[orderid] &&
                        props.shippingCost.orders[orderid].codCost !==
                          undefined ? (
                          Number(props.shippingCost.orders[orderid].codCost) ===
                          0 ? (
                            `${getCurrencySymbol(currency)} 0.00`
                          ) : (
                            `${getCurrencySymbol(currency)} ${formatNumber(
                              Number(props.shippingCost.orders[orderid].codCost)
                            )}`
                          )
                        ) : (
                          <Icon type="loading" />
                        )}
                      </div>
                    )}

                    {props.shippingCost.orders[orderid] &&
                    props.shippingCost.orders[orderid].total &&
                    props.shippingCost.orders[orderid].total_platformCost >
                      0 ? (
                      <div className="lineitem-total-product-price custom-width-120">
                        {props.shippingCost.orders[orderid] &&
                        props.shippingCost.orders[orderid].total ? (
                          `${getCurrencySymbol(currency)}  ${formatNumber(
                            Number(
                              props.shippingCost.orders[orderid]
                                .total_platformCost
                            ).toFixed(2)
                          )}`
                        ) : (
                          <Icon type="loading" />
                        )}
                      </div>
                    ) : null}
                    <div className="lineitem-total-product-price">
                      {props.shippingCost.orders[orderid] &&
                      props.shippingCost.orders[orderid].total ? (
                        `${getCurrencySymbol(currency)} ${formatNumber(
                          Number(props.shippingCost.orders[orderid].total)
                        )}`
                      ) : (
                        <Icon type="loading" />
                      )}
                    </div>
                  </>
                )}
              </div>
            </Tooltip>
          </>
        ))}

        {props.type === "verifyorder" && (
          <div className="line-item-total-order-cost">
            <span className="total-order-cost">Total Order Cost:</span>

            {/* {zeroPP ? null : (
              <Col xs={3}>
                {props.shippingCost &&
                props.shippingCost.itemCost !== undefined ? (
                  `${getCurrencySymbol(currency)} ${formatNumber(
                    Number(props.shippingCost.itemCost)
                  )}`
                ) : (
                  <Icon type="loading" />
                )}
              </Col>
            )} */}

            {/* {zeroFF ? null : (
              <Col xs={3}>
                {props.shippingCost !== undefined &&
                props.shippingCost.fulfilment !== undefined ? (
                  Number(props.shippingCost.fulfilment) === 0 ? (
                    "N.A."
                  ) : (
                    `${getCurrencySymbol(currency)} ${formatNumber(
                      Number(props.shippingCost.fulfilment)
                    )}`
                  )
                ) : (
                  <Icon type="loading" />
                )}
              </Col>
            )} */}
            {/* 
            <Col xs={3}>
              {props.shippingCost && props.shippingCost.shipping ? (
                `${getCurrencySymbol(currency)} ${formatNumber(
                  Number(props.shippingCost.shipping)
                )}`
              ) : (
                <Icon type="loading" />
              )}
            </Col> */}
            {/* {storegeo === "in" && (
              <Col xs={3} style={{ textAlign: "center" }}>
                {props.shippingCost !== undefined &&
                props.shippingCost.cod !== undefined ? (
                  Number(props.shippingCost.cod) === 0 ? (
                    "N.A."
                  ) : (
                    `${getCurrencySymbol(currency)} ${formatNumber(
                      Number(props.shippingCost.cod)
                    )}`
                  )
                ) : (
                  <Icon type="loading" />
                )}
              </Col>
            )} */}
            <span className="total-order-value">
              {props.type === "verifyorder" ? (
                isNaN(retailAllowPriceCalc(ordersToDisplay)) ? (
                  <Icon type="loading" />
                ) : (
                  `${getCurrencySymbol(currency)} ${formatNumber(
                    Number(retailAllowPriceCalc(ordersToDisplay))
                  )}`
                )
              ) : props.shippingCost !== undefined &&
                props.shippingCost.cod !== undefined ? (
                <span>{`${getCurrencySymbol(currency)} ${formatNumber(
                  Number(props.shippingCost.itemCost) +
                    Number(props.shippingCost.shipping) +
                    Number(props.shippingCost.cod) +
                    Number(props.shippingCost.fulfilment || 0) +
                    totalPlatformFees(ordersToDisplay)
                )}`}</span>
              ) : (
                <Icon type="loading" />
              )}
            </span>
          </div>
        )}

        {!!Object.keys(retailNotAllowed).length ? (
          <p className="inv-error">
            Your stock levels are currently low, and we are unable to fulfill
            retail orders at this time. Please consider replenishing your
            inventory
          </p>
        ) : null}

        {props.type === "cancel" && (
          <div className="linitem-checkbox-selection">
            <Checkbox
              defaultChecked={false}
              onChange={(e) => props.setShopifyOrderCancel(e.target.checked)}
            >
              {" "}
              <span
                className={
                  props.cancelOnShopify ? "refunded-txt" : "refunded-txt2"
                }
              >
                {" "}
                {`Cancel order on ${SHOPIFY_NAME}`}
              </span>
            </Checkbox>
          </div>
        )}

        {/* { props.type === "cancel" ?  */}

        {!!props.ordersdata.filter(
          (order) => order.order_vfid === props.modalvforderid
        ).length ? (
          props.ordersdata.find(
            (order) => order.order_vfid === props.modalvforderid
          ).is_cod !== 1 && props.type === "cancel" ? (
            <div className="linitem-checkbox-selection">
              <Checkbox
                style={{ marginBottom: 15 }}
                onChange={(e) =>
                  props.onChangeConfirmationModalField(
                    "refund",
                    e.target.checked
                  )
                }
              >
                {" "}
                <span
                  className={props.refund ? "refunded-txt" : "refunded-txt2"}
                >
                  {" "}
                  Refund these items as well
                </span>
              </Checkbox>

              {props.refund && (
                <div style={{ marginBottom: "15px" }}>
                  <p>
                    <span className="refundAmount-txt"> Refund Amount:</span>
                    <span>
                      <InputNumber
                        style={{ width: 225, marginLeft: 20 }}
                        value={props.refundAmount}
                        onChange={(e) =>
                          props.onChangeConfirmationModalField(
                            "refundAmount",
                            e
                          )
                        }
                      />
                    </span>
                  </p>
                </div>
              )}
            </div>
          ) : null
        ) : null}

        {/* {!!props.ordersdata.filter(
          (order) => order.order_vfid === props.modalvforderid
        ).length ? (
          props.ordersdata.find(
            (order) => order.order_vfid === props.modalvforderid
          ).is_cod === 1 ? (
            <div className="linitem-checkbox-selection">
              <Checkbox
                style={{ marginBottom: 15 }}
                onChange={(e) =>
                  props.onChangeConfirmationModalField(
                    "refund",
                    e.target.checked
                  )
                }
              >
                {" "}
                <span
                  className={props.refund ? "refunded-txt" : "refunded-txt2"}
                >
                  {" "}
                  Refund these items as well
                </span>
              </Checkbox>
              }
              {props.refund && (
                <div style={{ marginBottom: "15px" }}>
                  <p>
                    <span className="refundAmount-txt"> Refund Amount:</span>
                    <span>
                      <InputNumber
                        style={{ width: 225, marginLeft: 20 }}
                        value={props.refundAmount}
                        onChange={(e) =>
                          props.onChangeConfirmationModalField(
                            "refundAmount",
                            e
                          )
                        }
                      />
                    </span>
                  </p>
                </div>
              )}
            </div>
          ) : null)
        ) : null} */}

        {props.type === "verify" || props.type === "verifyorder" ? (
          <p>
            <span className="alert_txt_ican">
              <Icon component={requestalert} />
            </span>
            <span className="alert_txt">
              Please make sure your “Channel Branding” settings are up-to-date
              as they are printed on the Order Invoice which is visible to the
              customer.
              <p className="link_branding">
                <Link
                  to={`/${props.storegeo}/${props.store.id}/settings?from_order`}
                  className="backBtn"
                  target="_blank"
                >
                  "Link: Visit My Channel Branding”
                </Link>
              </p>
            </span>
          </p>
        ) : null}
      </div>
    </Modal>
  );
};

export default OrderConfirmationModal;
