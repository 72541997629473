import React from "react";
import { Modal, Skeleton, Tabs, message, Icon } from "antd";
import PlansScreen from "./PlansScreen";
import StripePaymentForm from "./StripeScreen";
import AppSubscriptionComplete from "./AppSubscriptionComplete";
import axios from "axios";
import { signOut } from "../../../store/actions/authActions";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import "./index.scss";

const { TabPane } = Tabs;

class AppSubscriptionPlan extends React.Component {
  state = {
    activeKey: "1",
    plans: null,
    selectedPlan: null,
    loading: false,
    planType: "month",
    isPlanLoading: true,
    planName: "",
    clientSecretKey: "",
    clientSecretKeyLoading: false,
    trial: false,
    plan_id: "",
    website_signup: {},
    isFromWebsite: false,
    couponCode: "",
    couponPrice: "",
  };

  // get all plans
  planFetcher() {
    this.setState({ isPlanLoading: true }, () => {
      axios({
        url: process.env.REACT_APP_API_URL + "/user/get_all_subscription_plan",
        method: "post",
        withCredentials: true,
      })
        .then((res) => {
          this.setState({ isPlanLoading: false });

          if (res.data.success === 1) {
            if (res.data.data.hasOwnProperty("website_signup")) {
              if (
                !!res.data.data.website_signup &&
                !!res.data.data.website_signup.client_secret
              ) {
                return this.setState(
                  {
                    plans: res.data.data,
                    website_signup: res.data.data.website_signup,
                    isFromWebsite: true,
                    clientSecretKey: res.data.data.website_signup.client_secret,
                    selectedPlan: res.data.data.website_signup.plan_id,
                    planType: res.data.data.website_signup.interval,
                    plan_id: res.data.data.website_signup.plan_id,
                    trial: !!res.data.data.website_signup.trial ? true : false,
                  },
                  () => {
                    this.setState({ activeKey: "2" });
                  }
                );
              }

              if (
                !!res.data.data.website_signup &&
                !!res.data.data.website_signup.trial_period
              ) {
                return this.setState({
                  plans: res.data.data,
                  website_signup: res.data.data.website_signup,
                  isFromWebsite: false,
                });
              }
            } else {
              this.setState({
                plans: res.data.data,
                website_signup: {},
                isFromWebsite: false,
              });
            }
          } else {
            message.error(res.data.msg);
          }
        })
        .catch((e) => {
          message.error(e.message);
          this.setState({
            isPlanLoading: false,
          });
        });
    });
  }

  componentDidMount() {
    if (!!this.props.upgradePlan) {
      this.setState({
        planType: JSON.parse(JSON.stringify(this.props.current_plan.interval)),
        plans: JSON.parse(JSON.stringify(this.props.upgradable_plans.data)),
        isPlanLoading: false,
      });
    } else {
      this.planFetcher();
    }
  }

  tabHandler = (key) => {
    this.setState({ activeKey: key });
  };

  render() {
    if (!this.props.auth.logged_in) return <Redirect to={`/switch-store`} />;

    if (!!this.props.auth.user.subscription && !this.props.upgradePlan) {
      return <Redirect to={`/switch-store`} />;
    }

    return (
      <Modal
        destroyOnClose={true}
        closable={this.props.upgradePlan ? true : false}
        footer={null}
        onCancel={this.props.upgradePlan ? () => this.props.onClose() : null}
        className={`app-subscription-modal screen-${this.state.activeKey}`}
        visible={true}
      >
        <>
          {!this.props.upgradePlan && this.state.activeKey === "1" ? (
            <div
              className="logout"
              onClick={(e) => {
                this.props.signOut();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
              >
                <path
                  d="M11 20H6a2 2 0 01-2-2V6a2 2 0 012-2h5M20 12l-4-4m4 4l-4 4m4-4H10"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Logout
            </div>
          ) : null}

          <Tabs defaultActiveKey="1" activeKey={this.state.activeKey}>
            <TabPane tab="Tab 1" key="1">
              <div id="fade-in" className="subscription-modal-wraper">
                {this.state.isPlanLoading ? (
                  <Skeleton active />
                ) : (
                  <PlansScreen
                    website_signup={this.state.website_signup}
                    signOut={() => this.props.signOut()}
                    isFreePlan={this.props.isFreePlan}
                    onClose={() => this.props.onClose()}
                    current_plan={this.props.current_plan}
                    upgradePlan={this.props.upgradePlan}
                    state={this.state}
                    setState={(state) => this.setState(state)}
                    plans={this.state.plans}
                    planType={this.state.planType}
                    loading={this.state.loading}
                    tabHandler={(key) => this.tabHandler(key)}
                  />
                )}
              </div>
            </TabPane>
            <TabPane tab="Tab 2" key="2">
              <div id="fade-in" className="subscription-modal-wraper">
                {!!this.state.clientSecretKey &&
                !!this.state.clientSecretKey.length ? (
                  <StripePaymentForm
                    website_signup={this.state.website_signup}
                    trial={this.state.trial}
                    state={this.state}
                    setState={(state) => this.setState(state)}
                    plans={this.state.plans}
                    planId={this.state.plan_id}
                    planName={this.state.planName}
                    planType={this.state.planType}
                    clientSecretKey={this.state.clientSecretKey}
                    tabHandler={(key) => this.tabHandler(key)}
                  />
                ) : null}
              </div>
            </TabPane>
            <TabPane id="fade-in" tab="Tab 3" key="3">
              <div className="subscription-modal-wraper">
                <AppSubscriptionComplete
                  state={this.state}
                  trial={this.state.trial}
                  setState={(state) => this.setState(state)}
                  plans={this.state.plans}
                  plan_id={this.state.plan_id}
                  planName={this.state.planName}
                  planType={this.state.planType}
                  clientSecretKey={this.state.clientSecretKey}
                  tabHandler={(key) => this.tabHandler(key)}
                />
              </div>
            </TabPane>
          </Tabs>
        </>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppSubscriptionPlan);
