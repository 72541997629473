import React, { Component } from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import LoginForm from "./Components/Login/LoginForm/";
import ForgotForm from "./Components/Login/ForgotForm/";
import ResetPasswordForm from "./Components/Login/ResetPasswordForm/";
import RegisterForm from "./Components/Login/RegisterForm/";
import AddUserSignup from "./Components/Login/AddUserSignup";
import Signup from "./Components/Signup";
import LoginNew from "./Components/LoginNew";
import SwitchToStore from "./Components/Dashboard/Common/SwitchToStore";
import NotFound404 from "./Components/NotFound404";
import Onboarding from "./Components/Onboarding";
import EmailVerify from "./Components/Dashboard/EmailVerify";
import OnboardingKYC from "./Components/OnboardingKYC";
import AppSubscriptionPlan from "./Components/Dashboard/AppSubscriptionPlan";

import StoreGeoChecker from "./Components/Dashboard/Common/StoreGeoChecker";
import Dashboard from "./Components/Dashboard/";
import Snapshot from "./Components/Dashboard/Snapshot/";
import ImportList from "./Components/Dashboard/Products/ImportList";
import PrivateProducts from "./Components/Dashboard/Products/PrivateProduct";
import MyProducts from "./Components/Dashboard/Products/MyProducts";
import WishlistProduct from "./Components/Dashboard/Products/WishlistProduct";
import VariantMatching from "./Components/Dashboard/Products/MyProducts/VariantMatching";

import ViewLinkedVariantMatching from "./Components/Dashboard/Products/MyProducts/MyProduct/ViewLinkedVariantMatching";

import ManageOrders from "./Components/Dashboard/Orders/ManageOrders";
import Profile from "./Components/Dashboard/Profile";
import Settings from "./Components/Dashboard/Settings";
// import Services from "./Components/Dashboard/Services";
import SearchProducts from "./Components/Dashboard/Products/SearchProducts";
import CatalogProduct from "./Components/Dashboard/Catalog/CatalogProduct";
import CatalogProduct2 from "./Components/Dashboard/Catalog/CatalogProduct2";
import PackagingCatalog from "./Components/Dashboard/Catalog/PackagingCatalog";

import CatalogListing from "./Components/Dashboard/Catalog/CatalogListing";
import MyWallet from "./Components/Dashboard/MyWallet";
import UserSettings from "./Components/Dashboard/UserSettings";
import VfCostMis from "./Components/Dashboard/Reporting2/VfCostMis";
import VfRemittanceMis from "./Components/Dashboard/Reporting2/VfRemittanceMIS";
import ShipmentsStatusReport from "./Components/Dashboard/Reporting2/ShipmentsStatusReport";
import VfBuyBack from "./Components/Dashboard/Reporting2/VFBuyBack";
import OverallMISReport from "./Components/Dashboard/Reporting2/OverallMISReport";
import Shipments from "./Components/Dashboard/Shipments";
import Disputes from "./Components/Dashboard/Disputes";
import Ndr from "./Components/Dashboard/Ndr";
import TransactionReports from "./Components/Dashboard/ReportingNew/TransactionsReport";
import OrderReports from "./Components/Dashboard/ReportingNew/OrdersReport";
import NdrReports from "./Components/Dashboard/ReportingNew/NdrReport";
import LiveInventory from "./Components/Dashboard/Inventory/LiveInventory";
import SourcingRequests from "./Components/Dashboard/Inventory/SourcingRequests";
import PricingRequests from "./Components/Dashboard/Inventory/PricingRequests";
import StoreListing from "./Components/Dashboard/StoreListing";
import RateCards from "./Components/Dashboard/RateCards";

import AllNotification from "./Components/Dashboard/Notifications/AllNotification";
import ContactCenter from "./Components/Dashboard/ContactCenter";
import BundledVariantMatching from "./Components/Dashboard/Products/MyProducts/BundledVariantMatching";
import AddStoreModal2 from "./Components/Dashboard/Common/AddStoreModal2";
import NdrEscalation from "./Components/Dashboard/NdrEscalation";
import DownloadUtility from "./Components/Dashboard/DownloadUtility";
import ShipmentRequests from "./Components/Dashboard/Inventory/ShipmentRequests";
import Rfq from "./Components/Dashboard/Rfq";

// import VariantQuantitylSelect from "./Components/Dashboard/Products/MyProducts/BundledVariantMatching/VariantQuantitylSelect";

// import AllBroadcast from "./Components/Dashboard/AllBroadcast";

import Preview from "./Components/Dashboard/AllBroadcast/Preview";
import ShipmentSetting from "./Components/Dashboard/ShipmentSetting";
import Signupnew from "./Components/Signupnew";
import ReSetPassword from "./Components/ReSetPassword";
class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="content-body">
          <Switch>
            <Route
              exact
              path="/login"
              render={(props) => (
                <LoginNew {...{ ...props, title: "vFulfill | Login" }} />
              )}
            />

            <Route
              path="/signup"
              render={(props) => (
                <Signupnew {...{ ...props, title: "vFulfill | Signup" }} />
              )}
            />

            <Route
              path="/reset/:hash"
              render={(props) => (
                <ReSetPassword
                  {...{ ...props, title: "vFulfill | Reset Password" }}
                />
              )}
            />

            <Route
              path="/reg/56db7269e926ec3e072b76dfff67d6b7"
              render={(props) => (
                <RegisterForm {...{ ...props, title: "vFulfill | Register" }} />
              )}
            />
            <Route
              path="/switch-store/:storeid([a-fA-F0-9]{24})?"
              render={(props) => <SwitchToStore {...props} />}
            />
            <Route
              path="/login/adduser/:hash"
              render={(props) => (
                <AddUserSignup {...{ ...props, title: "vFulfill | Signup" }} />
              )}
            />

            <Route
              exact
              path="/onboarding"
              render={(props) => (
                <EmailVerify {...{ ...props, title: "vFulfill" }} />
              )}
            />

            {/* <Route
              exact
              path="/onboarding-question"
              render={(props) => (
                <Onboarding {...{ ...props, title: "vFulfill" }} />
              )}
            /> */}

            <Route
              exact
              path="/:storeid([a-fA-F0-9]{24})/onboarding"
              render={(props) => <OnboardingKYC {...props} />}
            />

            <Route
              exact
              path="/"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfill" }} />
              )}
            />

            <Route
              path="/profile"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfill | My Profile" }}>
                  {" "}
                  <Profile {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfill | Snapshot" }}>
                  <StoreGeoChecker {...props}>
                    <Snapshot {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />
            {/* <Route
              exact
              path="/:storeid([a-fA-F0-9]{24})/services"
              render={props => (
                <Dashboard {...{ ...props, title: "vFulfill | Services" }}>
                  {" "}
                  <Services {...props} />
                </Dashboard>
              )}
            /> */}
            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/settings"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfill | Preferences" }}>
                  <StoreGeoChecker {...props}>
                    <Settings {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/shippingSettings"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfill | Preferences" }}>
                  <StoreGeoChecker {...props}>
                    <ShipmentSetting {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/:storeid([a-fA-F0-9]{24})/storeproductviewvariants/:aliId/:productId"
              render={(props) => (
                <Dashboard
                  {...{ ...props, title: "vFulfill | Match Variants" }}
                >
                  {" "}
                  <ViewLinkedVariantMatching {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storeid([a-fA-F0-9]{24})/variants/:aliId/:productId"
              render={(props) => (
                <Dashboard
                  {...{ ...props, title: "vFulfill | Match Variants" }}
                >
                  {" "}
                  <VariantMatching {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storeid([a-fA-F0-9]{24})/bundle/:aliIds/:productId"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfill | Bundle" }}>
                  {" "}
                  <BundledVariantMatching {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storegeo(in|global)/my-wallet"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfill | My Wallet" }}>
                  {" "}
                  <MyWallet {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storeid([a-fA-F0-9]{24})/manage-users"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfill | Manage Users" }}>
                  {" "}
                  <UserSettings {...props} />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/manage-stores"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfill | Manage Stores" }}>
                  {" "}
                  <StoreListing {...props} />
                </Dashboard>
              )}
            />

            {/* <Route
              exact
              path="/rate-cards"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfill | Rate Cards" }}>
                  {" "}
                  <RateCards {...props} />
                </Dashboard>
              )}
            /> */}

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/products/import-list"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfill | Import List" }}>
                  <StoreGeoChecker {...props}>
                    <ImportList {...{ ...props }} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/subscription"
              render={(props) => (
                // <Dashboard
                //   {...{ ...props, title: "vFulfill | SubscriptionPlan" }}
                // >
                <AppSubscriptionPlan {...{ ...props }} />
                // </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/products/store-products"
              render={(props) => (
                <Dashboard
                  {...{ ...props, title: "vFulfill | Store Products " }}
                >
                  <StoreGeoChecker {...props}>
                    <MyProducts {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/products/private-products"
              render={(props) => (
                <Dashboard
                  {...{ ...props, title: "vFulfill | Private Products " }}
                >
                  <StoreGeoChecker {...props}>
                    <PrivateProducts {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/products/store-products/:linkStatus(linked|unlinked)"
              render={(props) => (
                <Dashboard
                  {...{ ...props, title: "vFulfill | Store-Products" }}
                >
                  <StoreGeoChecker {...props}>
                    <MyProducts {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/products/wishlist-products"
              render={(props) => (
                <Dashboard
                  {...{ ...props, title: "vFulfill | Wishlist-Product" }}
                >
                  <StoreGeoChecker {...props}>
                    <WishlistProduct {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/products/rfq"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfill | RFQ" }}>
                  <StoreGeoChecker {...props}>
                    <Rfq {...{ ...props }} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/orders/open"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfill | Open Orders" }}>
                  <StoreGeoChecker {...props}>
                    <ManageOrders pageType="openorders" {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/orders"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfill | All Orders" }}>
                  <StoreGeoChecker {...props}>
                    <ManageOrders pageType="allorders" {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            {/* closed orders route */}

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/orders/closedOrders"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfill | Closed Orders" }}>
                  <StoreGeoChecker {...props}>
                    <ManageOrders pageType="closedOrders" {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/orders/processing"
              render={(props) => (
                <Dashboard
                  {...{ ...props, title: "vFulfill | In-Processing " }}
                >
                  <StoreGeoChecker {...props}>
                    <ManageOrders pageType="processingorders" {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/tracking"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfil | Shipments" }}>
                  <StoreGeoChecker {...props}>
                    <Shipments {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/delivered-shipments"
              render={(props) => (
                <Dashboard
                  {...{ ...props, title: "vFulfil | Delivered Shipments" }}
                >
                  <StoreGeoChecker {...props}>
                    <Shipments {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/contact-center"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfil | Contact Center" }}>
                  <StoreGeoChecker {...props}>
                    <ContactCenter {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            <Route
              exact
              // path="/in/:storeid([a-fA-F0-9]{24})/escalations"
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/escalation/escalations"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfil | Escalations" }}>
                  <StoreGeoChecker {...props}>
                    <Disputes {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            {/* ndr escalation tab */}
            <Route
              exact
              // path="/in/:storeid([a-fA-F0-9]{24})/escalations"
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/escalation/ndr-escalations"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfil | Ndr Escalation" }}>
                  <StoreGeoChecker {...props}>
                    <NdrEscalation {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            {/* download utility tab */}
            <Route
              exact
              path="/downloads"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfil | Download" }}>
                  <DownloadUtility {...props} />
                </Dashboard>
              )}
            />

            {/* download utility tab */}
            <Route
              exact
              // path="/in/:storeid([a-fA-F0-9]{24})/escalations"
              path="/downloads"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfil | Download" }}>
                  <DownloadUtility {...props} />
                </Dashboard>
              )}
            />

            {/* <Route
              exact
              path="/in/:storeid([a-fA-F0-9]{24})/ndr/all"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfil | All-NDR" }}>
                  <StoreGeoChecker {...props}>
                    <Ndr {...props} status="All" />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            /> */}

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/ndr"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfil | NDR" }}>
                  <StoreGeoChecker {...props}>
                    <Ndr {...props} status="pending" />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/transactionsReports"
              render={(props) => (
                <Dashboard
                  {...{ ...props, title: "vFulfil | Transaction Reporting" }}
                >
                  <StoreGeoChecker {...props}>
                    <TransactionReports {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/reports"
              render={(props) => (
                <Dashboard
                  {...{ ...props, title: "vFulfil | Orders Reporting"}}
                >
                  <StoreGeoChecker {...props}>
                    <OrderReports {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/NdrReports"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfil | NDR Reporting" }}>
                  <StoreGeoChecker {...props}>
                    <NdrReports {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            {/* <Route
              exact
              path="/in/:storeid([a-fA-F0-9]{24})/ndr/responded"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfil | Responded-NDR" }}>
                  <StoreGeoChecker {...props}>
                    <Ndr {...props} status="responded" />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            /> */}

            {/* <Route
              exact
              path="/in/:storeid([a-fA-F0-9]{24})/ndr/expired"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfil | Expired-NDR" }}>
                  <StoreGeoChecker {...props}>
                    <Ndr {...props} status="expired" />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            /> */}

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/ndr"
              render={(props) => (
                <Dashboard
                  {...{ ...props, title: "vFulfill | Non Delivery Report" }}
                >
                  <StoreGeoChecker {...props}>
                    <ManageOrders pageType="ndr" {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/live-inventory"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfil | Inventory" }}>
                  <StoreGeoChecker {...props}>
                    <LiveInventory {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/requests/sourcing-requests"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfil | Sourcing" }}>
                  <StoreGeoChecker {...props}>
                    <SourcingRequests {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            {/* broadcast notification */}

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/broadcast/view"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfil | Broadcast" }}>
                  <StoreGeoChecker {...props}>
                    <Preview {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/requests/pricing-requests"
              render={(props) => (
                <Dashboard
                  {...{ ...props, title: "vFulfil | Pricing Updates" }}
                >
                  <StoreGeoChecker {...props}>
                    <PricingRequests {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/requests/shipment-requests"
              render={(props) => (
                <Dashboard
                  {...{ ...props, title: "vFulfil | Shipment-requests" }}
                >
                  <StoreGeoChecker {...props}>
                    <ShipmentRequests {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            {/* <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/notification/all-broadcast"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfil | notification" }}>
                  <StoreGeoChecker {...props}>
                    <AllBroadcast {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            /> */}

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/notification/all-broadcast/:vfprodid"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfil | Preview" }}>
                  <StoreGeoChecker {...props}>
                    <Preview {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/all-notification"
              render={(props) => (
                <Dashboard
                  {...{ ...props, title: "vFulfil | all-notification" }}
                >
                  <StoreGeoChecker {...props}>
                    <AllNotification {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/reporting/vf-cost-mis"
              render={(props) => (
                <Dashboard {...{ ...props, title: "vFulfill | VF Cost MIS" }}>
                  <StoreGeoChecker {...props}>
                    <VfCostMis {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/reporting/vf-remittance-mis"
              render={(props) => (
                <Dashboard
                  {...{ ...props, title: "vFulfill | VF Remittance MIS" }}
                >
                  <StoreGeoChecker {...props}>
                    <VfRemittanceMis {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/reporting/shipments-status-report"
              render={(props) => (
                <Dashboard
                  {...{ ...props, title: "vFulfill | Shipments Status Report" }}
                >
                  <StoreGeoChecker {...props}>
                    <ShipmentsStatusReport {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/in/:storeid([a-fA-F0-9]{24})/reporting/vf-buy-back-credit-mis"
              render={(props) => (
                <Dashboard
                  {...{ ...props, title: "vFulfill | VF Buy Back Credit MIS" }}
                >
                  <StoreGeoChecker {...props}>
                    <VfBuyBack {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/reporting/overall-mis-report"
              render={(props) => (
                <Dashboard
                  {...{ ...props, title: "vFulfill | Overall MIS Report" }}
                >
                  <StoreGeoChecker {...props}>
                    <OverallMISReport {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/products/search-products"
              render={(props) => (
                <Dashboard
                  {...{ ...props, title: "vFulfill | Product Research" }}
                  hideSidebar
                  showSearch
                  fullWidthNavbar
                  navbarType="search"
                >
                  <StoreGeoChecker {...props}>
                    <CatalogListing {...props} />
                  </StoreGeoChecker>
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/products/:vfprodid"
              render={(props) => (
                <Dashboard
                  {...{ ...props, title: "vFulfill | Catalog" }}
                  hideSidebar
                  showSearch
                  fullWidthNavbar
                  navbarType="search"
                >
                  {/* <StoreGeoChecker {...props}> */}
                  <CatalogProduct {...props} />
                  {/* </StoreGeoChecker> */}
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/products2/:vfprodid"
              render={(props) => (
                <Dashboard
                  {...{ ...props, title: "vFulfill | Catalog" }}
                  hideSidebar
                  showSearch
                  fullWidthNavbar
                  navbarType="search"
                >
                  {/* <StoreGeoChecker {...props}> */}
                  <CatalogProduct2 {...props} />
                  {/* </StoreGeoChecker> */}
                </Dashboard>
              )}
            />

            {/* packaging catalog route */}
            <Route
              exact
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/packaging/:vfprodid"
              render={(props) => (
                <Dashboard
                  {...{ ...props, title: "vFulfill | Catalog" }}
                  hideSidebar
                  showSearch
                  fullWidthNavbar
                  navbarType="search"
                >
                  {/* <StoreGeoChecker {...props}> */}
                  <PackagingCatalog {...props} />
                  {/* </StoreGeoChecker> */}
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:storeid([a-fA-F0-9]{24})/products/search-products/:prodid"
              render={(props) => (
                <Dashboard
                  {...{ ...props, title: "vFulfill | Search Products" }}
                >
                  {" "}
                  <SearchProducts
                    {...props}
                    handleCancel={() => this.handleCancel()}
                    openDetails={true}
                  />
                </Dashboard>
              )}
            />

            <Route
              path="/:storegeo(in|global)/:storeid([a-fA-F0-9]{24})/addstore"
              render={(props) => (
                <Dashboard
                  {...{ ...props, title: "vFulfill | Search Products" }}
                >
                  {" "}
                  <AddStoreModal2 {...props} openDetails={true} />
                </Dashboard>
              )}
            />

            <Route path="*" render={() => <NotFound404 />} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
