import React, { Component } from "react";
import { Collapse, Tooltip, Icon } from "antd";
import ClickToCopy from "../../../../Common/ClickToCopy";
import { ReactComponent as link } from "../../../../../../assets/images/icons/link.svg";
import moment from "moment";
import "./index.scss";

const { Panel } = Collapse;

class Expandable extends Component {
  render() {
    let order = this.props.order;
    let product_img =
      order && order.images && order.images.length > 0 && order.images[0].url;

    return (
      <>
        <Collapse className="collapse-item-logs" expandIconPosition="right">
          <Panel
            onClick={(event) => event.stopPropagation()}
            showArrow={false}
            header={
              <div
                className="container"
                style={{ cursor: "auto" }}
                onClick={(event) => event.stopPropagation()}
              >
                <div
                  className="details"
                  onClick={(event) => event.stopPropagation()}
                >
                  <div className="img">
                    <img src={order.image} alt="" />
                  </div>
                  <div className="product-link-name">
                    <p>
                      {order.name && order.name.length <= 20 ? (
                        <a
                          href={`./products/${order.prod_id}`}
                          target="_blank"
                          className="view-in-vf-link"
                          rel="noopener noreferrer"
                        >
                          {order.name}
                          <Icon
                            component={link}
                            style={{ marginLeft: "6px" }}
                          />
                        </a>
                      ) : (
                        <Tooltip title={order.name}>
                          {" "}
                          <p className="order-id-link">
                            {order.name ? (
                              <a
                                href={`./products/${order.prod_id}`}
                                target="_blank"
                                className="view-in-vf-link"
                                rel="noopener noreferrer"
                              >
                                {" "}
                                {order.name.slice(0, 20) + "..."}
                                <Icon
                                  component={link}
                                  style={{ marginLeft: "6px" }}
                                />
                              </a>
                            ) : (
                              " Name not available"
                            )}
                          </p>
                        </Tooltip>
                      )}
                    </p>

                    <ClickToCopy
                      width={true}
                      inventoryId={order.sku}
                      textToCopy={order.sku}
                    />
                  </div>{" "}
                </div>
                <div className="InventoryE">
                  <span style={{ color: "#898989" }}>
                    {order.source.split(":")[0].toLowerCase() === "awb" ||
                    order.source.split(":")[0].toLowerCase() === "rto" ? (
                      <Tooltip title="Return">
                        <span>{order.source.split(":")[0] + ":"}</span>
                        <span>
                          <a
                            href={`https://track.vcfl.express/?waybill=${
                              order.source.split(":")[1]
                            }`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {order.source.split(":")[1]}
                          </a>
                        </span>
                      </Tooltip>
                    ) : (
                      <>
                        {" "}
                        <span>
                          {" "}
                          {order.source.split(":")[0] + ":" === "Manual Added:"
                            ? "Manual Added"
                            : order.source.split(":")[0] + ":"}
                        </span>{" "}
                        {order.source.split(":")[0] === "Booking Request ID" ? (
                          <a
                            href={`./requests/sourcing-requests?search=${order.source
                              .split(":")[1]
                              .trim()}`}
                          >
                            <span>{order.source.split(":")[1]}</span>
                          </a>
                        ) : (
                          <span>{order.source.split(":")[1]}</span>
                        )}
                      </>
                    )}
                  </span>

                  {/* if booking request found makes it link and redirect on request page */}
                  {/*  */}
                </div>
                <div>
                  <p style={{ color: "#898989" }}>
                    {order.type + ":  "}{" "}
                    <span
                      style={{
                        color: `${
                          order.type === "Inward" ? `#229D1B` : `#C70C3C`
                        }`,
                      }}
                    >
                      {" "}
                      {order.type === "Inward" ? <span>+</span> : null}
                      {order.qty}{" "}
                    </span>
                  </p>
                  <p style={{ color: "#898989" }}>
                    {" "}
                    Dated:
                    <span> {moment(order.date).format("DD MMM, YYYY")}</span>
                  </p>
                </div>
              </div>
            }
            key="1"
          />
        </Collapse>

        {/* logs */}
      </>
    );
  }
}

export default Expandable;
