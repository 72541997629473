import React, { Component } from "react";
import moment from "moment";
import {
  getCurrencySymbol,
  formatNumber,
} from "../../../../../Helpers/Pricing";
import "./index.scss";
import ViewCat from "../../../Common/GlobalCommon/ViewCat";
import { Tooltip, message, Modal, Icon, Button, Popover } from "antd";
import axios from "axios";
import ClickToCopy from "../../../Common/ClickToCopy";
import SourcingProductTitle from "../../../Common/GlobalCommon/ProductTitle/SourcingTitle";
import { ReactComponent as Tooltipican } from "../../../../../assets/images/icons/tooltipican.svg";
import { ReactComponent as DownloadBG } from "../../../../../assets/images/icons/download-bg.svg";
import { ReactComponent as VideoTick } from "../../../../../assets/images/icons/video-play.svg";
import { ReactComponent as FilesIcon } from "../../../../../assets/images/icons/files.svg";
import { ReactComponent as Arrowican } from "../../../../../assets/images/icons/tooltipican.svg";
// import { ReactComponent as Calanderican } from "../../../../../assets/images/icons/calander.svg";

import { ReactComponent as Calander } from "../../../../../assets/images/icons/calander.svg";
import VfIdCopyBadge from "../../../Common/VfIdCopyBadge";
import AddNoteModal from "./AddNoteModal";
import UpdatePackingModal from "./UpdatePackingModal";
import ShowImageModal from "./ShowImageModal";
import { UploadedMedia } from "./UploadedMedia";
export const statusName = {
  pending: "Pending",
  approved: "Approved",
  sourced: "Sourced",
  transit: "In Transit",
  in_flight: "In Flight",
  retail: "Sample",
  inventory_live: "Inventory Live",
  cancelled: "Cancelled",
  reached_hk_airport: "In transit",
  reached_airport_sh: "In transit",
  shipement_loaded: "In transit",
  in_transit: "In transit",
  reached_india_port: "In transit",
  boe_filled: "In custom",
  india_customs_cleared: "In customs",
  enroute_india_fc: "Enroute to India FC",
  reached_india_fc: "Reached to India FC",
  quality_check: "Quality Check",
  in_production: "In Production",
  custom_packaging: "In custom Packaging",
  inward: "Inwarded to FC",
  sourcing_initiated: "Sourcing Initiated",
  partial_inventory_live: "Partial Inventory Live",
  pickup_aligned_from_supplier: "Picked Up from Supplier",
  china_qc_checked: "QC & Labelling",
  partially_handover_to_partner: "Partially Delivered To Partner",
  handover_to_partner: "Delivered To Partner",
};
export const statusColor = {
  pending: "#FC824E",
  approved: "#73d13d",
  sourced: "#43A0E8",
  transit: "#FFC53D",
  in_flight: "#fbc52d",
  inventory_live: "#73d13d",
  cancelled: "#f12f1a",
  partial_inventory_live: "#16273b",
  reached_hk_airport: "#16273b",
  reached_airport_sh: "#16273b",
  shipement_loaded: "#16273b",
  in_transit: "#16273b",
  reached_india_port: "#16273b",
  boe_filled: "#16273b",
  india_customs_cleared: "#16273b",
  enroute_india_fc: "#16273b",
  reached_india_fc: "#16273b",
  quantity_check: "#16273b",
  custom_packaging: "#16273b",
  inward: "#16273b",
  handover_to_partner: "#73d13d",
  sourcing_initiated: "#16273b",
  in_production: "#FFC53D",
  quality_check: "#FFC53D",
  china_qc_checked: "#16273b",
  pickup_aligned_from_supplier: "#16273b",
  partially_handover_to_partner: "#16273b",
};

const { confirm } = Modal;

class SourcingCard extends Component {
  state = {
    currentImgIndex: 0,
    activeTab: "details",
    notesModal: {
      visible: false,
      saving: false,
    },
    notes: [],
    customPacking: false,
    showImageModal: {
      visible: false,
      index: 0,
      files: [],
    },
    uploadedMediaModal: {
      visible: false,
      media: [],
    },
  };

  freightTypeStatusHandler(status) {
    if (status === "india_air_freight") {
      return "India Air Freight";
    }

    if (status === "india_sea_freight") {
      return "India Sea Freight";
    }

    if (status === "vf_inventory") {
      return "VF Inventory";
    }

    if (status === "retail") {
      return "Sample";
    }

    if (status === "domestic") {
      return "Domestic";
    }
  }

  cancelRequest() {
    confirm({
      title: "Are you sure?",
      content: "Are you sure you want to cancel booking request",
      onOk: () => {
        axios({
          url:
            process.env.REACT_APP_API_URL + "/sourcing/cancel_sourcing_request",
          method: "post",
          data: {
            storeid: this.props.storeid,
            request_vfid: this.props.request.request_vfid,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.props.fetchOrders();
              message.success("Update Successfully!");
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            message.error(e.message);
          });
      },
      onCancel: () => {},
    });
  }
  toggleUpdatePackingModal() {
    this.setState({
      customPacking: !this.state.customPacking,
    });
  }
  toggleNotesModal() {
    this.setState({
      notesModal: {
        visible: !this.state.notesModal.visible,
      },
    });
  }
  toggleShowImageModal(i = 0, files = []) {
    console.log(i);
    this.setState({
      showImageModal: {
        visible: !this.state.showImageModal.visible,
        index: i,
        // imageTag: imageTag,
        files: files,
      },
    });
  }
  nextImg() {
    this.setState((state) => ({
      currentImgIndex:
        state.currentImgIndex === this.props.request.images.length - 1
          ? 0
          : state.currentImgIndex + 1,
    }));
  }

  prevImg() {
    this.setState((state) => ({
      currentImgIndex:
        state.currentImgIndex === 0
          ? this.props.request.images.length - 1
          : state.currentImgIndex - 1,
    }));
  }

  setActiveTab(activeTab) {
    this.setState({
      activeTab,
    });
  }

  addNote = (note) => {
    if (typeof note === "string" && note.length > 0) {
      this.setState(
        {
          notesModal: {
            ...this.state.notesModal,
            saving: true,
          },
        },
        () => {
          axios({
            url: process.env.REACT_APP_API_URL + "/sourcing/add_note",
            method: "post",
            data: {
              storeid: this.props.storeid,
              note: note,
              request_vfid: this.props.request.request_vfid,
            },
            withCredentials: true,
          })
            .then((res) => {
              this.setState({
                notesModal: {
                  ...this.state.notesModal,
                  saving: false,
                },
              });
              if (res.data.success === 1) {
                message.success("Note added successfully.");
                this.setState(
                  {
                    notes: [res.data.note, ...this.state.notes],
                  },
                  () => this.toggleNotesModal()
                );
                this.props.fetchOrders();
              } else {
                message.error("There was an error adding the note.");
              }
            })
            .catch((e) => {
              this.setState({
                notesModal: {
                  ...this.state.notesModal,
                  saving: false,
                },
              });
              message.error(e.message);
            });
        }
      );
    } else {
      message.error("Please enter a note to add.");
    }
  };

  render() {
    const request = this.props.request;
    const notes = request.notes.filter((note) => note.notetype === "note");
    const logs = request.notes.filter((note) => note.notetype === "log");
    let no_custom_packaging_data = Array.isArray(
      this.props.request.packaging_suggestions
    );

    return (
      <div id="test" className="sourcing-card">
        <div className="top-section">
          <div className="vforderid_tag">
            <VfIdCopyBadge
              text={"Request ID"}
              hashSign={false}
              textToCopy={
                request && request.request_vfid ? request.request_vfid : "N/A"
              }
            />{" "}
          </div>

          <div className="right-side-status">
            <p
              className="status-label"
              style={{ background: statusColor[request.status] }}
            >
              <div className="circle" />

              {statusName[request.status]
                ? statusName[request.status]
                : request.status}
            </p>
          </div>

          <div>
            {request.status === "pending" ? (
              <p className="right-cancel-btn">
                <button
                  className="clickable cancel-btn"
                  onClick={() => this.cancelRequest()}
                >
                  Cancel
                </button>
              </p>
            ) : null}
          </div>

          <div className="img-wraper">
            <div className="img-container">
              <div style={{ position: "relative" }}>
                <img
                  src={
                    !!request.images &&
                    request.images[this.state.currentImgIndex].url
                  }
                  alt="product"
                />
                <button className="next-img" onClick={() => this.nextImg()}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.163"
                    height="41"
                    viewBox="0 0 17.163 41"
                  >
                    <g>
                      <path
                        d="M16.247 19.4L2.532.932A.95.95 0 00.916.97a1.942 1.942 0 000 2.139l12.907 17.382L.916 37.873a1.937 1.937 0 000 2.176.95.95 0 001.616 0l13.715-18.47a1.937 1.937 0 000-2.179z"
                        fill="#fff"
                        stroke="#fff"
                      />
                    </g>
                  </svg>
                </button>
                <button className="prev-img" onClick={() => this.prevImg()}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.163"
                    height="41"
                    viewBox="0 0 17.163 41"
                  >
                    <g>
                      <path
                        d="M.915 19.4L14.63.932a.95.95 0 011.616.038 1.942 1.942 0 010 2.139L3.339 20.491l12.907 17.382a1.937 1.937 0 010 2.176.95.95 0 01-1.616 0L.915 21.579a1.937 1.937 0 010-2.179z"
                        fill="#fff"
                        stroke="#fff"
                      />
                    </g>
                  </svg>
                </button>
              </div>
            </div>
            <div style={{ textAlign: "center", paddingRight: "24px" }}>
              <ViewCat
                url={`/${request.storegeo}/${request.storeid["$oid"]}/products/${request.product_vfid}`}
              />
            </div>
          </div>

          <div className="basic-details">
            <p>
              <span className="product-title-link">
                <SourcingProductTitle
                  fcType={request.fc}
                  packaging={request.packaging}
                  productName={request.product_name}
                  storegeo={request.storegeo}
                  vfprodid={request.product_vfid}
                  storeid={request.storeid["$oid"]}
                />
              </span>

              {/* <span
                style={{
                  fontSize: 12,
                  color: "#898989",
                  margin: "0 12px",
                  fontWeight: 400,
                }}
              >
                |
              </span> */}
              <div>
                <span
                  style={{ fontSize: 12, color: "#898989", fontWeight: 400 }}
                >
                  PRODUCT ID:
                </span>

                <span className="copied-id" style={{ marginLeft: "5px" }}>
                  <ClickToCopy
                    textToCopy={
                      request && request.product_vfid
                        ? request.product_vfid
                        : "Not available"
                    }
                    style={{ color: "blue" }}
                  />
                  <div className="invtype">
                    <div className="vr" />
                    <p>Inventory Type: </p>
                    <span>
                      {!!this.props.request.packaging
                        ? " Packaging"
                        : " Product"}
                    </span>
                  </div>
                </span>
              </div>
            </p>
            {request.edd ? (
              <div className="edd-date">
                {" "}
                <div className="heading head-bold">
                  <span className="calender-container">
                    <Calander />{" "}
                  </span>
                  Expected Delivery Date :{" "}
                </div>{" "}
                {request.edd_logs && request.edd_logs.length ? (
                  <Popover
                    className="eed-logs"
                    title={
                      <div className="heading-eddlogs">
                        Expected Delivery Date Logs
                      </div>
                    }
                    placement="right"
                    content={request.edd_logs.map((e, i) => {
                      return (
                        <div className="edd-test">
                          <p className="edd-log-text" key={i}>
                            <div className="item">
                              <span className="heading">
                                EDD changed from :
                              </span>
                              <span>
                                {moment(e.old_edd).format("DD MMM, YYYY")}
                              </span>{" "}
                            </div>
                            <div className="item">
                              <span className="heading">to :</span>{" "}
                              <span>
                                {moment(e.revised_edd).format("DD MMM, YYYY")}
                              </span>
                            </div>
                          </p>
                          <div className="remark">
                            <span className="heading">Reason:</span>
                            <span style={{ color: "#898989" }}>
                              {e.partner_remarks}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  >
                    <div className="field-value">
                      {request.revised_edd
                        ? moment(request.revised_edd).format("DD MMM, YYYY")
                        : moment(request.edd).format("DD MMM, YYYY")}
                      <span className="iconCantainer">
                        <Icon component={Tooltipican} />
                      </span>
                    </div>
                  </Popover>
                ) : (
                  <div className="field-value1">
                    {request.revised_edd
                      ? moment(request.revised_edd).format("DD MMM, YYYY")
                      : moment(request.edd).format("DD MMM, YYYY")}
                  </div>
                )}
              </div>
            ) : null}

            <p className="product-description">{request.product_description}</p>
            <div className="fields">
              {/* <div className="field">
                <p className="field-label">Request ID:</p>
                <p className="field-value">
                  {request && request.request_vfid
                    ? request.request_vfid
                    : "Not Available"}
                </p>
              </div> */}

              <div className="field">
                <p className="field-label">Requested Booking Qty:</p>
                <p className="field-value quantity-txt ">
                  {request.hasOwnProperty("old_quantity")
                    ? request.old_quantity
                    : request.quantity}
                </p>
              </div>

              <div className="field">
                <p className="field-label">
                  Requested Booking Amt:
                  <span className="amount-tooltip-ican">
                    <Tooltip title="VF Catalog price">
                      <Icon component={Tooltipican} />
                    </Tooltip>
                  </span>
                </p>
                <p
                  className="field-value"
                  style={{ color: "#1c6513", textAlign: "center" }}
                >
                  {getCurrencySymbol(
                    request.currency === "INR" ? "INR" : "USD"
                  )}{" "}
                  {/* {request.status === "approved"
                    ? formatNumber(request.final_price)
                    : formatNumber(request.promised_price)} */}
                  {formatNumber(request.pricing.base)}
                  {!!request.gst_percentage ? (
                    <span className="gst-field">
                      +GST ({request.gst_percentage}%){" "}
                    </span>
                  ) : null}
                </p>
              </div>

              <div className="field">
                <p className="field-label "> Booking Requested On:</p>
                <p className="field-value black">
                  {!!this.props.request.date_created
                    ? moment(this.props.request.date_created).format(
                        "DD MMM, YYYY | HH:mm"
                      )
                    : "Not Available"}
                </p>
              </div>

              {request.fc && request.storegeo !== "global" ? (
                <div
                  className="field"
                  style={{
                    maxWidth: "168px",
                    display: "inline-block",
                  }}
                >
                  <p className="field-label">Freight Type:</p>
                  <p className="field-value">
                    {this.freightTypeStatusHandler(request.fc)}
                  </p>
                </div>
              ) : null}
            </div>

            <div className="bottom-fields">
              <div className="field">
                <p className="field-label">Final Booking Qty:</p>
                <p className="field-value" style={{ textAlign: "center" }}>
                  {/*                   
                  {getCurrencySymbol(
                    request.currency === "INR" ? "INR" : "USD"
                  )}{" "} */}
                  {request.status !== "cancelled" &&
                  request.status !== "pending"
                    ? request.quantity
                    : "N/A"}
                </p>
              </div>

              {request.status === "pending" || request.status == "cancelled" ? (
                <div className="field">
                  <p className="field-label"> Final Booking Amt:</p>
                  <p className="field-value green-color">N/A</p>
                </div>
              ) : (
                <div className="field">
                  <p className="field-label"> Final Booking Amt:</p>
                  <p className="field-value green-color">
                    {getCurrencySymbol(
                      request.currency === "INR" ? "INR" : "USD"
                    )}{" "}
                    {formatNumber(request.final_price)}
                    {request.gst_percentage && (
                      <span className="gst-field">
                        {" "}
                        +GST ({request.gst_percentage}%){" "}
                      </span>
                    )}
                  </p>
                </div>
              )}

              <div className="field">
                <p className="field-label ">
                  {request.status !== "cancelled" &&
                  request.status !== "pending"
                    ? "Price Per Qty:"
                    : "Booking Price Per Qty:"}
                </p>
                <p className="field-value green-color">
                  {getCurrencySymbol(
                    request.currency === "INR" ? "INR" : "USD"
                  )}{" "}
                  {formatNumber(request.price_perpiece)}
                  {!!request.gst_percentage ? (
                    <span className="gst-field">
                      {" "}
                      +GST ({request.gst_percentage}%){" "}
                    </span>
                  ) : null}
                </p>
              </div>

              {!!request.action_logs && !!request.action_logs.approved ? (
                <div className="field">
                  <p className="field-label "> Approved On:</p>
                  <p className="field-value black">
                    {!!this.props.request.date_created
                      ? moment(
                          this.props.request.action_logs.approved.added_on
                        ).format("DD MMM, YYYY | HH:mm")
                      : "Not Available"}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="tab-container">
          <div className="tabs-header">
            <ul>
              <li>
                <button
                  onClick={() => this.setActiveTab("details")}
                  style={{
                    background:
                      this.state.activeTab === "details"
                        ? "#244060"
                        : "transparent",
                  }}
                >
                  Booking Details
                </button>
              </li>
              <li>
                <button
                  onClick={() => this.setActiveTab("notes")}
                  style={{
                    background:
                      this.state.activeTab === "notes"
                        ? "#244060"
                        : "transparent",
                  }}
                >
                  Notes <span className="tab-count">{notes.length}</span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => this.setActiveTab("logs")}
                  style={{
                    background:
                      this.state.activeTab === "logs"
                        ? "#244060"
                        : "transparent",
                  }}
                >
                  Logs <span className="tab-count">{logs.length}</span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => this.setActiveTab("packing")}
                  style={{
                    background:
                      this.state.activeTab === "packing"
                        ? "#244060"
                        : "transparent",
                  }}
                >
                  Private Label Settings{" "}
                </button>
              </li>
              {request.media && request.media.length ? (
                <li>
                  <button
                    onClick={() => this.setActiveTab("media")}
                    style={{
                      background:
                        this.state.activeTab === "media"
                          ? "#244060"
                          : "transparent",
                    }}
                  >
                    Product Media{" "}
                  </button>
                </li>
              ) : null}
              {request.status === "pending" ? (
                <li className="add-notes-btn">
                  <button onClick={() => this.toggleNotesModal()}>
                    <svg
                      id="filetext"
                      xmlns="http://www.w3.org/2000/svg"
                      width="11.977"
                      height="16"
                      viewBox="0 0 11.977 16"
                      style={{ marginRight: "8px" }}
                    >
                      <path
                        id="Shape"
                        d="M10.868,16H1.14A1.134,1.134,0,0,1,0,14.875V1.125A1.134,1.134,0,0,1,1.14,0H6.527A1.145,1.145,0,0,1,6.98.1L7,.1,7,.107l.021.01.067.036a1.213,1.213,0,0,1,.229.18l4.338,4.382a1.116,1.116,0,0,1,.318.793v9.367A1.105,1.105,0,0,1,10.868,16ZM1.124,1.125v13.75h9.728V6H7.12a1.134,1.134,0,0,1-1.14-1.125V1.125Zm5.981.6v3.15H10.24Zm.453,11.4h-5a.562.562,0,0,1,0-1.125h5a.562.562,0,0,1,0,1.125Zm2-2.5h-7a.562.562,0,0,1,0-1.125h7a.562.562,0,0,1,0,1.125Zm-3-2.5h-4A.562.562,0,0,1,2.561,7h4a.562.562,0,0,1,0,1.125Z"
                        transform="translate(0 0)"
                        fill="#fff"
                      />
                    </svg>
                    Add Note
                  </button>
                </li>
              ) : null}
            </ul>
          </div>
          <div className="tabs-content">
            {!!this.props.request.skus.length ? (
              <div
                style={{
                  display:
                    this.state.activeTab === "details" ? "block" : "none",
                  padding: "5px 16px",
                  background: "white",
                  color: "#212121",
                  fontSize: 12,
                  borderBottomLeftRadius: "4px",
                  borderBottomRightRadius: "4px",
                }}
              >
                <div style={{ width: "100%" }}>
                  <div
                    className="flex"
                    style={{
                      marginBottom: 5,
                      padding: "0 16px",
                      color: "#898989",
                      marginTop: "8px",
                    }}
                  >
                    <div className="flex-2">Variant Details</div>
                    <div className="flex-3">SKU</div>
                    <div className="flex-1">Quantity</div>
                  </div>
                  {request.skus.map((sku) => (
                    <div
                      className="flex"
                      key={sku.vfsku}
                      style={{
                        padding: "5px 16px",
                        background: "#F5F5F5",
                        marginBottom: 8,
                      }}
                    >
                      <div className="flex-2">
                        <div className="flex" style={{ alignItems: "center" }}>
                          <img
                            src={sku.img}
                            style={{
                              width: 40,
                              height: 40,
                              objectFit: "cover",
                              display: "inline-block",
                              marginRight: 16,
                            }}
                            alt={sku.vfsku}
                          />
                          <div className="flex-1">{sku.allspecs}</div>
                        </div>
                      </div>
                      <div className="flex-3">{sku.vfsku}</div>
                      <div className="flex-1">
                        {sku.quantity} {sku.quantity === 1 ? "piece" : "pieces"}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <p className="no-data">No Data Available!</p>
            )}

            {/* notes code */}
            <div
              className={
                this.state.activeTab === "notes"
                  ? "display-block-notes "
                  : "display-none-notes "
              }
            >
              {notes && notes.length > 0 ? (
                <div className="flex-notes-logs">
                  <div className="flex-note-logs">Notes</div>
                  <div className="flex-date">Date</div>
                  <div className="flex-addedby">Added by</div>
                </div>
              ) : null}

              {notes && notes.length > 0 ? (
                notes.map((note, idx) => (
                  <div className="flex-notes-logs notes-detail" key={idx}>
                    <div className="flex-note-logs">
                      {note && note.note.length <= 35 ? (
                        note.note
                      ) : (
                        <Tooltip title={note.note}>
                          {" "}
                          {note.note
                            ? `${note.note.substring(0, 35)}...`
                            : null}
                        </Tooltip>
                      )}
                      {note.media &&  note.media.length ? (
                        <span style={{
                          marginLeft: "8px"
                        }}>
                          <Button
                            
                            className="clickable"
                            onClick={() => {
                              this.setState({
                                uploadedMediaModal: {
                                  visible: true,
                                  media: note.media,
                                },
                              });
                            }}
                          >
                            View Media
                          </Button>
                        </span>
                      ) : null}
                    </div>
                    <div className="flex-date">
                      {moment(note.date).format("DD MMM, YYYY | HH:mm")}
                    </div>
                    <div className="flex-addedby">by {note.added_by}</div>
                  </div>
                ))
              ) : (
                <div className="no-found">No Notes Found!</div>
              )}
            </div>
            <div
              className={
                this.state.activeTab === "packing"
                  ? "display-block-logs "
                  : "display-none-logs "
              }
            >
              {!no_custom_packaging_data ? (
                <div>
                  {this.props.request.packaging_suggestions.files &&
                  this.props.request.packaging_suggestions.files.length ? (
                    <div className="heading-custom-image">Uploaded Files </div>
                  ) : null}

                  <div className="image-row">
                    {this.props.request.packaging_suggestions.files &&
                      this.props.request.packaging_suggestions.files.map(
                        (url) => {
                          let arr = url.split(".");
                          let type = "image";
                          let imageTYPE = ["jpeg", "jpg", "png", "gif", "bmp"];
                          if (imageTYPE.includes(arr[arr.length - 1])) {
                            type = "image";
                          } else if (arr[arr.length - 1] === "pdf") {
                            type = "pdf";
                          } else {
                            type = "other";
                          }
                          if (type === "pdf") {
                            return (
                              <span className="img-cointainer">
                                <a
                                  className="download-icon"
                                  // href={
                                  //   `${process.env.REACT_APP_API_URL}` +
                                  //   `/products/download_product_media?url=` +
                                  //   `${url}`
                                  // }
                                  href={url}
                                  target="_blank"
                                >
                                  <Icon component={DownloadBG} />
                                </a>
                                <iframe
                                  src={url}
                                  style={{
                                    height: "110px",
                                    width: "110px",
                                    marginRight: "10px",
                                  }}
                                />
                              </span>
                            );
                          }
                          if (type === "other") {
                            return (
                              <span className="img-cointainer">
                                <a
                                  className="download-icon"
                                  // href={
                                  //   `${process.env.REACT_APP_API_URL}` +
                                  //   `/products/download_product_media?url=` +
                                  //   `${url}`
                                  // }
                                  href={url}
                                  target="_blank"
                                >
                                  <Icon component={DownloadBG} />
                                </a>
                                <span className="io-container">
                                  <FilesIcon />
                                </span>
                              </span>
                            );
                          }
                          return (
                            <div className="img-cointainer">
                              <a
                                className="download-icon"
                                // href={
                                //   `${process.env.REACT_APP_API_URL}` +
                                //   `/products/download_product_media?url=` +
                                //   `${url}`
                                // }
                                href={url}
                                target="_blank"
                              >
                                <Icon component={DownloadBG} />
                              </a>
                              <img src={url} />
                            </div>
                          );
                        }
                      )}
                  </div>
                  <div className="remarks-row">
                    <div className="remarks">
                      {" "}
                      <span
                        className="remarks-heading"
                        style={{
                          visibility: this.props.request.packaging_suggestions
                            .remarks
                            ? ""
                            : "hidden",
                        }}
                      >
                        {" "}
                        Remarks :{" "}
                      </span>{" "}
                      {this.props.request.packaging_suggestions.remarks}{" "}
                    </div>
                    {request.status === "pending" ? (
                      <Button
                        className="clickable"
                        onClick={() => this.toggleUpdatePackingModal()}
                      >
                        {!this.props.request.packaging_suggestions.remarks &&
                        (!this.props.request.packaging_suggestions.files ||
                          !this.props.request.packaging_suggestions.files
                            .length)
                          ? "Add"
                          : "Edit"}
                      </Button>
                    ) : null}
                  </div>
                </div>
              ) : request.status === "pending" ? (
                <div className="no-found">
                  {" "}
                  <Button
                    className="clickable"
                    onClick={() => this.toggleUpdatePackingModal()}
                  >
                    Add Private Label Details
                  </Button>
                </div>
              ) : (
                <div className="no-found"> No Data Found</div>
              )}{" "}
            </div>
            {/* media */}
            <div
              className={
                this.state.activeTab === "media"
                  ? "display-block-logs "
                  : "display-none-logs "
              }
            >
              <div className="media-attachments">
                {request.media &&
                  request.media.map((media, i) => {
                    let imageTag = true;
                    console.log(
                      media,
                      media.split(".")[media.split(".").length - 1],
                      media.split(".")[1] === "mp4"
                    );
                    !!media.split(".")[media.split(".").length - 1] &&
                    media
                      .split(".")
                      [media.split(".").length - 1].toLowerCase() === "mp4"
                      ? (imageTag = false)
                      : (imageTag = true);
                    return (
                      <div className="item-container">
                        <div
                          className="image-div"
                          key={i}
                          onClick={() =>
                            this.toggleShowImageModal(i, request.media)
                          }
                        >
                          {media ? (
                            imageTag ? (
                              <div>
                                <img src={media} alt="not found" />
                              </div>
                            ) : (
                              <video className="video-data">
                                <source src={media} type="video/mp4" />
                              </video>
                            )
                          ) : (
                            "image not available"
                          )}
                        </div>
                        <a
                          target={!imageTag ? "_self" : "_blank"}
                          download
                          href={media}
                          className="download-image-data"
                        >
                          <Tooltip title="download">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16.8"
                              height="19.6"
                              viewBox="0 0 16.8 19.6"
                            >
                              <g data-name="Group 2197">
                                <g data-name="Group 2193">
                                  <g data-name="Group 2192">
                                    <path
                                      data-name="Path 52"
                                      d="M16.154 13.411a.633.633 0 00-.646.619v3.714a.633.633 0 01-.646.619H1.934a.633.633 0 01-.646-.619V14.03a.647.647 0 00-1.292 0v3.714A1.9 1.9 0 001.934 19.6h12.928a1.9 1.9 0 001.938-1.857v-3.714a.633.633 0 00-.646-.618z"
                                      fill="#1370fc"
                                    />
                                  </g>
                                </g>
                                <g data-name="Group 2195">
                                  <g data-name="Group 2194">
                                    <path
                                      data-name="Path 53"
                                      d="M12.316 11.892a.69.69 0 00-.957 0l-2.27 2.265V.688a.689.689 0 00-1.378 0v13.469l-2.269-2.265a.688.688 0 00-.974.972L7.912 16.3a.69.69 0 00.974 0l3.445-3.438a.687.687 0 00-.015-.97z"
                                      fill="#1370fc"
                                    />
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </Tooltip>
                        </a>
                      </div>
                    );
                  })}
              </div>
            </div>
            {/* logs code */}
            <div
              className={
                this.state.activeTab === "logs"
                  ? "display-block-logs "
                  : "display-none-logs "
              }
            >
              {logs.length > 0 ? (
                <div className="flex-notes-logs">
                  <div className="flex-note-logs">Logs</div>
                  <div className="flex-date">Date</div>
                  <div className="flex-addedby">Added by</div>
                </div>
              ) : null}

              {logs.length > 0 ? (
                logs.map((logs, idx) => (
                  <div className="flex-notes-logs notes-detail" key={idx}>
                    <div className="flex-note-logs">
                      {logs && logs.note.length <= 45 ? (
                        logs.note
                      ) : (
                        <Tooltip title={logs.note}>
                          {" "}
                          {logs.note
                            ? `${logs.note.substring(0, 45)}...`
                            : null}
                        </Tooltip>
                      )}
                    </div>
                    <div className="flex-date">
                      {moment(logs.date).format("DD MMM, YYYY | HH:mm")}
                    </div>
                    <div className="flex-addedby">by {logs.added_by}</div>
                  </div>
                ))
              ) : (
                <div className="no-found">No Logs Found!</div>
              )}
            </div>
          </div>
        </div>
        <AddNoteModal
          visible={this.state.notesModal.visible}
          toggleModal={() => this.toggleNotesModal()}
          onConfirm={(value) => this.addNote(value)}
          saving={this.state.notesModal.saving}
          onCancel={() => this.toggleNotesModal()}
          notes={this.state.notes}
        />
        {this.state.customPacking && (
          <UpdatePackingModal
            visible={this.state.customPacking}
            toggleModal={() => {
              this.toggleUpdatePackingModal();
            }}
            cancel={() => {
              this.toggleUpdatePackingModal();
            }}
            images={
              this.props.request.packaging_suggestions &&
              this.props.request.packaging_suggestions.files &&
              this.props.request.packaging_suggestions.files.length
                ? this.props.request.packaging_suggestions.files
                : []
            }
            request_vfid={this.props.request.request_vfid}
            fetchOrders={() => {
              this.props.fetchOrders();
            }}
            remarks={
              this.props.request.packaging_suggestions &&
              this.props.request.packaging_suggestions.remarks
                ? this.props.request.packaging_suggestions.remarks
                : ""
            }
            Addheading={no_custom_packaging_data}
          />
        )}
        {this.state.showImageModal.visible && (
          <ShowImageModal
            toggleShowImageModal={() => this.toggleShowImageModal()}
            visible={this.state.showImageModal.visible}
            files={this.state.showImageModal.files}
            defaultIndex={this.state.showImageModal.index}
          />
        )}
         {this.state.uploadedMediaModal.visible && (
          <UploadedMedia
            data={this.state.uploadedMediaModal}
            onCancel={() => {
              this.setState({
                uploadedMediaModal: {
                  visible: false,
                  media: [],
                },
              });
            }}
          />
        )}
      </div>
    );
  }
}

export default SourcingCard;